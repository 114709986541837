import React from 'react';
import {
  Row, Col, Image,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import './DiscoverMePanel.scss';
import DiscoverMeLogo from '../images/logos/DiscoveMe.png';

export default function DiscoverMePanel(props) {
  const { children } = props;
  return (
    <Row className="discover-panel">
      <Col className="left-column">
        <div>
          <Image src={DiscoverMeLogo} />
          <p>
            Transforming Healthcare Together
          </p>
        </div>
      </Col>
      <Col className="right-column">
        {children}
      </Col>
    </Row>
  );
}

DiscoverMePanel.propTypes = {
  children: PropTypes.element.isRequired,
};
