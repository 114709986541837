import React from 'react';
import classnames from 'classnames';

interface Props {
  optError: boolean;
  setProductsServicesOpt: (value: boolean) => void;
}

const ServicesOptIn = ({ optError, setProductsServicesOpt }: Props) => {
  return (
    <div className={classnames('switch_container', { error: optError })}>
      <h4>
        At Omnigen, we believe in transforming healthcare together. That
        means providing you with updates and insights into your health
        and ancestry, including Omnigen’s offers and services
                </h4>
      {optError ? <p className="error">Please select an option</p> : ''}
      <div className="control">
        <input
          type="radio"
          id="optin"
          name="opt"
          value="optin"
          onChange={() => setProductsServicesOpt(true)} />
        <label htmlFor="optin">
          Yes please, I would like to hear about Omnigen’s offers and
          services
        </label>
      </div>
      <div className="control">
        <input
          type="radio"
          id="optout"
          name="opt"
          value="optout"
          onChange={() => setProductsServicesOpt(false)} />
        <label htmlFor="optout">
          No thanks, I do not want to hear about Omnigen’s offers and
          services
        </label>
      </div>
    </div>
  );
};

export default ServicesOptIn;
