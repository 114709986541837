import * as types from '../actions/resampleTypes';

export const initialState = {
  isLoading: false,
  canResample: true,
  resampleError: '',
  optingOut: false,
};

const resample = (state = initialState, action) => {
  switch (action.type) {
    case types.ADDRESS_FORM_SUBMITTING:
      return {
        ...state,
        isLoading: true,
      };

    case types.ADDRESS_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        canResample: false,
      };

    case types.ADDRESS_FORM_FAILED:
      return {
        ...state,
        isLoading: false,
        canResample: false,
        resampleError: action.error,
      };

    case types.OPT_OUT_STARTED:
      return {
        ...state,
        optingOut: true,
      };

    case types.OPT_OUT_SUCCESS:
      return {
        ...state,
        optingOut: false,
      };

    case types.OPT_OUT_FAILED:
      return {
        ...state,
        optingOut: false,
        resampleError: action.error,
      };

    default:
      return state;
  }
};

export default resample;
