import { combineReducers } from 'redux';
import { user } from './userState';
import { register } from './registerState';
import resample from './resampleState';

const state = combineReducers({
  user, register, resample,
});

export default state;
