export const NEW_LINK_FETCHING = 'NEW_LINK_FETCHING';
export const NEW_LINK_ERROR = 'NEW_LINK_ERROR';
export const NEW_LINK_SUCCESS = 'NEW_LINK_SUCCESS';
export const REGISTERING = 'REGISTERING';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_NAME_TAKEN_ERROR = 'REGISTER_NAME_TAKEN_ERROR';
export const BIRTHDATE_CHECKING = 'BIRTHDATE_CHECKING';
export const BIRTHDATE_CHECK_PASSED = 'BIRTHDATE_CHECK_PASSED';
export const REGISTER_DOB_ERROR = 'REGISTER_DOB_ERROR';
export const REGISTER_LINK_ERROR = 'REGISTER_LINK_ERROR';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const BIRTHDATE_CHECK_ERROR = 'BIRTHDATE_CHECK_ERROR';
export const USERNAME_RESETTING = 'USERNAME_RESETTING';
export const USERNAME_RESETTING_ERROR = 'USERNAME_RESETTING_ERROR';
export const USERNAME_RESETTING_SUCCESS = 'USERNAME_RESETTING_SUCCESS';
export const RESET_REGISTRATION_STATE = 'RESET_REGISTRATION_STATE';
