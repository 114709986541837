import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Box from '../components/Box';
import './HealthDashboard.scss';
import BackButton from '../components/BackButton';
import HealthPopUp from './HealthPopUp';
import TPPMessage from './TPPMessage';

export const version = '1.0.0';

const bkg = {
  backgroundColor: 'rgba(255,255,255,0.0)',
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  justifyContent: 'center',
};

function HealthDashboard() {
  const mediaMatch = window.matchMedia('(max-width: 576px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const xs = 6;
  const sm = 4;
  return (
    <div style={bkg}>
      <BackButton backLink="/" />
      <Container style={{ maxWidth: 800 }}>
        <HealthPopUp />
        <div style={{
          marginTop: 20, fontFamily: 'Montserrat', fontSize: '20px', alignText: 'center', width: '100%', color: '#707070', fontWeight: 600,
        }}>
          Health Information
        </div>
        <TPPMessage />
        <Row className="justify-content-md-center">
          <Col xs={xs} sm={sm} lg="4">
            <Box
              link="/health/prescriptions"
              text="Prescriptions"
              image="prescriptions@2x.png"
              color="darkblue"
              color2="#315568"
            />
          </Col>
          <Col xs={xs} sm={sm} lg="4">
            <Box
              link="/health/conditions"
              text="Conditions"
              image="conditions@2x.png"
              color="orange"
              color2="#BE701B"
            />
          </Col>
          <Col xs={xs} sm={sm} lg="4">
            <Box
              link="/health/appointments"
              text="Appointments"
              image="appointments@2x.png"
              color="green"
              color2="#548235"
            />
          </Col>
          <Col xs={xs} sm={sm} lg="4">
            <Box
              link="/health/allergies"
              text="Allergies"
              image="allergies@2x.png"
              color="purple"
              color2="#522279"
            />
          </Col>
          <Col xs={xs} sm={sm} lg="4">
            <Box
              link="/health"
              text="Vaccinations"
              image="vaccinations@2x.png"
              color="lightred"
              color2="#C11F47"
              disabled
            />
          </Col>
          <Col xs={xs} sm={sm} lg="4">
            <Box
              link="/health"
              text={matches ? 'Measures' : 'Measures / Tests'}
              image="tests@2x.png"
              color="darkgrey"
              color2="#00000080"
              disabled
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HealthDashboard;
