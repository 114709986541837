import React, { useState, useRef } from 'react';
import {
  Image, Row, Col, Overlay, Tooltip,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Box.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function Box(props) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const {
    color, color2, link, image, text, disabled, onClick, disabledMessage,
  } = props;

  const top = {
    flex: 'auto',
    position: 'relative',
    margin: 0,
    height: '100%',
  };

  const box = {
    alignItems: 'stretch',
    justifyContent: 'center',
    margin: 'auto',
    borderRadius: 20,
    overflow: 'hidden',
    marginTop: 10,
    marginBottom: 10,
    marginRight: 5,
    boxShadow: '0px 3px 6px #00000080',
    cursor: 'pointer',
  };

  const bottom = {
    display: 'flex',
    maxWidth: '100%',
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center',
  };

  const disabledDiv = disabled
    ? <div className="disabled"><i className="material-icons md-light md-inactive md-96">block</i></div>
    : '';

  const rowStyle = { overflow: 'hidden', ...(link === '' && box) };

  const childElement = (
    <Row ref={target} style={rowStyle} className={classnames({ [color]: link === '' }, 'box')} onClick={onClick}>
      <Overlay target={target.current} show={show && disabled} placement="bottom">
        <Tooltip>
          {disabledMessage}
        </Tooltip>
      </Overlay>
      {disabledDiv}
      <Col style={top}>
        <Image
          style={{ width: '100%' }}
          src={require(`../images/icons/home/${image}`)} // eslint-disable-line
        />
      </Col>
      <Col style={bottom} className={color2}>
        <div>
          <p style={{
            margin: 'auto', padding: '1vh', fontFamily: 'Montserrat', fontSize: '90%', alignText: 'center', height: '100%', color: 'white', fontWeight: 600,
          }}>
            {text}
          </p>
        </div>
      </Col>
    </Row>
  );

  const containerElement = link === ''
    ? childElement
    : (
      <LinkContainer
        to={link}
        onMouseOver={() => setShow(true)}
        onFocus={() => setShow(true)}
        onMouseOut={() => setShow(false)}
        onBlur={() => setShow(false)}
        style={box}
        className={color}>
        {childElement}
      </LinkContainer>
    );

  return containerElement;
}

Box.propTypes = {
  color: PropTypes.string.isRequired,
  color2: PropTypes.string.isRequired,
  link: PropTypes.string,
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  onClick: PropTypes.func,
};

Box.defaultProps = {
  disabled: false,
  disabledMessage: 'Coming Soon!',
  link: '',
  onClick: () => { },
};

export default Box;
