import React, { useState } from 'react';
import { Spinner, Button } from 'react-bootstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PrivacyNotice from '../documents/PrivacyNotice';
import TermsAndConditions from '../documents/TermsAndConditions';
import ServicesOptIn from '../components/ServicesOptIn';

import { acceptTocsAction } from '../actions/userActions';

interface Document {
  name: string;
  document: JSX.Element;
}

const getDocuments = (optError: boolean, changeOption: (value: boolean) => void): Document[] => [
  {
    name: 'Privacy Notice',
    document: <PrivacyNotice />,
  },
  {
    name: 'Terms and Conditions',
    document: (
      <div>
        <TermsAndConditions firstTime />
        <ServicesOptIn optError={optError} setProductsServicesOpt={changeOption} />
      </div>
    ),
  }
];

interface Props {
  acceptTocs: (value: boolean) => void,
  isAcceptingTocs: boolean,
}

const FirstTimePolicies = ({ acceptTocs, isAcceptingTocs }: Props) => {
  const [page, setPage] = useState<number>(0);
  const [productsServicesOpt, setProductsServicesOpt] = useState<boolean | undefined>(undefined);
  const [optError, setOptError] = useState<boolean>(false);

  const documents = getDocuments(optError, setProductsServicesOpt);
  const pageLength = documents.length - 1;

  const nextPage = () => {
    if (page === pageLength) {
      if (productsServicesOpt === undefined) {
        setOptError(true);
      } else {
        acceptTocs(productsServicesOpt);
      }
    } else {
      window.scrollTo(0, 0);
      setPage(page + 1);
    }
  }

  const currentDocument = documents[page];

  return (
    <>
      <h1>{ currentDocument.name }</h1>
      { currentDocument.document }
      <Button
        className={classnames('orange', 'tos-btn')}
        onClick={nextPage}
        disabled={isAcceptingTocs}>
        {isAcceptingTocs ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true" />
        ) : 'I accept'}
      </Button>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { settings: { showTocs, isAcceptingTocs } } = state.user;
  return { showTocs, isAcceptingTocs };
};

const mapDispatchToProps = {
  acceptTocs: acceptTocsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstTimePolicies);
