import React, { useState, useRef, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import {
  Button, Form, Popover, Spinner, Overlay,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DiscoverMePanel from '../components/DiscoverMePanel';
import BirthDateSelection from '../components/BirthDateSelection';

import ConfirmationModal from '../components/ConfirmationModal';
import DocumentModal from '../components/DocumentModal';
import Help from '../documents/Help';
import styles from './Register.module.css';

import { newRegisterLinkAction, registerAction, checkBirthdateAction } from '../actions/registerActions';

import './Register.scss';

const useQuery = () => new URLSearchParams(useLocation().search);

const Register = props => {
  const [day, setDay] = useState('1');
  const [month, setMonth] = useState('Jan');
  const [year, setYear] = useState('2004');
  const [username, setUsername] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [password1Error, setPassword1Error] = useState('');
  const [password2Error, setPassword2Error] = useState('');
  const [id, setId] = useState('');
  const [temporaryPassword, setTemporaryPassword] = useState('');
  const [toHome, setToHome] = useState(false);
  const [showResetLink, setShowResetLink] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showUsernamePopover, setShowUsernamePopover] = useState(false);
  const [showPasswordPopover, setShowPasswordPopover] = useState(false);

  const usernameButtonRef = useRef(null);
  const passwordButtonRef = useRef(null);

  const query = useQuery();

  // handle popover dismissal for safari
  useEffect(() => {
    const handleClickOut = event => {
      if (event.target !== usernameButtonRef.current) {
        setShowUsernamePopover(false);
      }
      if (event.target !== passwordButtonRef.current) {
        setShowPasswordPopover(false);
      }
    };

    document.body.addEventListener('click', handleClickOut, false);

    return function cleanup() {
      document.body.removeEventListener('click', handleClickOut, false);
    };
  });

  // handle id changes
  useEffect(() => {
    setId(query.get('id'));
    setTemporaryPassword(query.get('request'));
  }, [query]);

  const {
    newRegisterLink, register, dateOfBirthError, isLoading, error, invalid, userRegistered,
    registerSuccess, checkBirthdate, passedDateOfBirthCheck,
  } = props;

  const newLink = () => {
    newRegisterLink(id, temporaryPassword, year, month, day)
      .then(() => setShowResetLink(true));
  };

  const passwordReset = event => {
    event.preventDefault();
    event.stopPropagation();

    if (usernameError || password1Error || password2Error || !username || !password1 || !password2) {
      return;
    }

    register(password1, username);
  };

  useEffect(() => {
    setPassword1Error('');
    if (password1 !== '') {
      if (password1.search(/\d/) === -1) {
        setPassword1Error('Password must contain a number');
      } else if (password1.search(/[a-z]/) === -1) {
        setPassword1Error('Password must contain a lowercase character');
      } else if (password1.search(/[A-Z]/) === -1) {
        setPassword1Error('Password must contain an uppercase character');
      } else if (password1.length < 12) {
        setPassword1Error('Password must be longer than 11 characters');
      }
    }
  }, [password1]);

  useEffect(() => {
    setPassword2Error(password2 === password1 ? '' : 'Passwords do not match');
  }, [password2, password1]);

  useEffect(() => {
    if (username !== '') {
      setUsernameError(username.length > 3
        ? ''
        : 'Username must be longer than 3 characters');
    }
  }, [username]);

  const handleDateOfBirthChange = event => {
    const { id: dobId, value } = event.target;
    switch (dobId) {
      case 'day':
        setDay(value);
        break;
      case 'month':
        setMonth(value);
        break;
      default:
        setYear(value);
        break;
    }
  };

  const forgottenSection = (
    <>
      <div className="forgotten">
        <Button variant="link" className="help" onClick={() => setShowHelpModal(true)}>Help</Button>
      </div>
      <div>
        If you have already registered, please login here:&nbsp;
        <a href="/">https://portal.discovermestudy.org</a>
      </div>
    </>
  );

  const submitDobForm = event => {
    event.preventDefault();
    event.stopPropagation();
    checkBirthdate(id, temporaryPassword, year, month, day);
  };

  const spinner = isLoading ? (
    <Spinner key="loading-spinner" animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  ) : null;

  const dobForm = (id && temporaryPassword && !passedDateOfBirthCheck && !isLoading && !invalid)
    ? (
      <div key="dob-form" className="register">
        <div className={styles['top-text']}>
          <p>
            Please enter your
            <strong> date of birth</strong>
          </p>
        </div>
        <Form
          className={styles.form}
          onSubmit={submitDobForm}>
          <BirthDateSelection
            day={day}
            month={month}
            year={year}
            change={handleDateOfBirthChange}
            error={dateOfBirthError || error}
            disabled={isLoading} />
          <Button
            disabled={isLoading}
            className={styles.submit}
            bsPrefix="register-btn"
            variant="primary"
            type="submit">
            Next
          </Button>
        </Form>
        { forgottenSection }
      </div>
    ) : null;

  const usernameForm = passedDateOfBirthCheck && !isLoading ? (
    <div key="username-form" className="register">
      <div className={styles['top-text']}>
        <p>Thank you for confirming your date of birth.</p>
        <p>
          Please create a
          <strong> username </strong>
          and
          <strong> password</strong>
        </p>
      </div>
      <Form className={styles.form} onSubmit={passwordReset} disabled={isLoading}>
        <Form.Group controlId="registerUsername">
          <Form.Label>
            Create Username&nbsp;
            <button
              type="button"
              className="info"
              ref={usernameButtonRef}
              onBlur={() => setShowUsernamePopover(false)}
              onClick={() => setShowUsernamePopover(true)}>
              <span className="material-icons">info</span>
            </button>
          </Form.Label>
          <Overlay
            placement="right"
            target={usernameButtonRef.current}
            show={showUsernamePopover}>
            <Popover>
              <Popover.Content>
                <p>
                  Please set a username you would like to use for the Discover Me portal. You will use this to
                  log back in later.
                </p>
                <p>
                  For more help,&nbsp;
                  <button type="button" onClick={() => setShowHelpModal(true)}>click here</button>
                </p>
              </Popover.Content>
            </Popover>
          </Overlay>
          <Form.Control
            className={`form-control ${usernameError ? 'is-invalid' : ''}`}
            value={username}
            onChange={e => setUsername(e.target.value)}
            type="username"
            autoComplete="username"
            placeholder="Enter username"
            isInvalid={error}
            autoCapitalize="off" />
          <div className="invalid-feedback">{usernameError || error}</div>
          <Form.Text className="text-muted" />
        </Form.Group>

        <Form.Group controlId="registerPassword">
          <Form.Label>
            Create Password&nbsp;
            <button
              type="button"
              className="info"
              ref={passwordButtonRef}
              onBlur={() => setShowPasswordPopover(false)}
              onClick={() => setShowPasswordPopover(true)}>
              <span className="material-icons">info</span>
            </button>
          </Form.Label>
          <Overlay
            target={passwordButtonRef.current}
            show={showPasswordPopover}
            placement="right">
            <Popover>
              <Popover.Content>
                <p>
                  Please set a password that contains an uppercase letter, contains a number and is longer than
                  11 characters. You will need this to log back in later.
                </p>
                <p>
                  For more help,&nbsp;
                  <button type="button" onClick={() => setShowHelpModal(true)}>click here</button>
                </p>
              </Popover.Content>
            </Popover>
          </Overlay>
          <Form.Control
            className={`form-control ${password1Error ? 'is-invalid' : ''}`}
            value={password1}
            autoComplete="new-password"
            onChange={e => setPassword1(e.target.value)}
            type="password"
            placeholder="Password"
            autoCapitalize="off" />
          <div className="invalid-feedback">{password1Error}</div>
        </Form.Group>

        <Form.Group controlId="registerPasswordConfirmation">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            className={`form-control ${password2Error ? 'is-invalid' : ''}`}
            value={password2}
            autoComplete="new-password"
            onChange={e => setPassword2(e.target.value)}
            type="password"
            placeholder="Password"
            autoCapitalize="off" />
          <div className="invalid-feedback">{password2Error}</div>
        </Form.Group>

        <Button
          disabled={isLoading}
          className={styles.submit}
          bsPrefix="register-btn"
          variant="primary"
          type="submit">
          Register
        </Button>
      </Form>
      { forgottenSection }
    </div>
  ) : null;

  let errorForm = null;
  if (userRegistered) {
    errorForm = (
      <div key="already-registered">
        <div className={styles['error-title']}>
          Already Registered
        </div>
        <div className={styles['expired-text']}>
          Please login here:
          {' '}
          <a href="/">https://portal.discovermestudy.org</a>
        </div>
      </div>
    );
  }

  if (invalid) {
    errorForm = (
      <div key="expired-link">
        <div className={styles['error-title']}>
          Expired link
        </div>
        <div className={styles['expired-text']}>
          <p>The link you clicked on has now expired.</p>
          <Button onClick={!isLoading ? newLink : null}>
            {isLoading ? 'Sending link...' : 'Request new link'}
          </Button>
        </div>
      </div>
    );
  }

  if (!id || !temporaryPassword) {
    errorForm = (
      <div key="invalid-link">
        <div className={styles['error-title']}>Invalid Link</div>
        <div className={styles['expired-text']}>
          <p>The URL is incorrect, please try again.</p>
        </div>
      </div>
    );
  }

  if (toHome === true || registerSuccess) {
    return <Redirect to="/" />;
  }

  const form = (
    <div>
      { spinner }
      { dobForm }
      { usernameForm }
      { errorForm }
    </div>
  );

  return (
    <DiscoverMePanel>
      <h2>WELCOME TO THE</h2>
      <h1>PARTICIPANT PORTAL</h1>
      {form}
      <ConfirmationModal
        title="Reset Link Sent"
        isOpen={showResetLink}
        pending={false}
        confirm={() => { setShowResetLink(false); setToHome(true); }}>
        Please check your email or mobile phone for a new link and follow that
        to complete registration
      </ConfirmationModal>

      <DocumentModal
        title="Discover Me Participant Portal - Help"
        msg={<Help />}
        isOpen={showHelpModal}
        hide={() => setShowHelpModal(false)} />
    </DiscoverMePanel>
  );
};

const mapStateToProps = state => {
  const {
    register: {
      dateOfBirthError, error, isLoading, invalid, userRegistered, registerSuccess, passedDateOfBirthCheck,
    },
  } = state;
  return {
    dateOfBirthError, error, isLoading, invalid, userRegistered, registerSuccess, passedDateOfBirthCheck,
  };
};

const mapDispatchToProps = {
  newRegisterLink: newRegisterLinkAction,
  register: registerAction,
  checkBirthdate: checkBirthdateAction,
};

Register.propTypes = {
  newRegisterLink: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  dateOfBirthError: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  userRegistered: PropTypes.bool.isRequired,
  registerSuccess: PropTypes.bool.isRequired,
  checkBirthdate: PropTypes.func.isRequired,
  passedDateOfBirthCheck: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
