import 'fastestsmallesttextencoderdecoder';
import Lambda from 'aws-sdk/clients/lambda';
import aws4 from 'aws4';

export function getSignURL(credentials) {
  const opts = {
    service: 'sts',
    path: '/?Action=GetCallerIdentity&Version=2011-06-15',
    signQuery: true,
    method: 'POST',
  };

  const signed = aws4.sign(opts, {
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
  });

  const url = `https://sts.amazonaws.com${signed.path}`;
  return url;
}

export const invokeLambda = (
  lambdaCredentials, signedurl, jwtToken, userId, table, operation,
) => new Promise((resolve, reject) => {
  const lambda = new Lambda({
    credentials: lambdaCredentials,
    region: 'eu-west-2',
  });

  lambda.invoke({
    FunctionName: 'portal-table-crud',
    Payload: JSON.stringify({
      operation,
      callerIdUrl: signedurl,
      method: 'POST',
      table,
      accessToken: jwtToken,
      keys: [{ LINKING_TOKEN: userId }],
    }),
  }, (err, data) => {
    if (err) return reject(err);
    return resolve(data);
  });
});
