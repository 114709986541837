import React from 'react';
import {
  Nav as BSNav, Navbar, Row, Image, NavItem, Dropdown, Button,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DiscoverMeLogo from '../images/logos/DiscoverMe_landscape.png';
import Settings from '../images/icons/settings.svg';
import Profile from '../images/icons/profile.svg';

import { signOut as signOutAction, showFaqsAction } from '../actions/userActions';

const profileButton = React.forwardRef(({ children, onClick }, ref) => (
  /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
  <div
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
    onKeyDown={e => {
      e.preventDefault();
      onClick(e);
    }}>
    <Image src={Profile}>{children}</Image>
  </div>
));

profileButton.propTypes = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Nav = props => {
  const { signOut, isAuthenticated, showFaqs } = props;
  return isAuthenticated ? (
    <Row>
      <Navbar
        style={{
          backgroundColor: 'white',
          width: '100%',
          minHeight: 100,
        }}
        expand="md">
        <LinkContainer style={{ marginLeft: 20 }} to="/">
          <Navbar.Brand>
            <Row>
              <Image style={{ height: 40 }} src={DiscoverMeLogo} />
            </Row>
            <Row
              style={{
                color: '#FCA60A',
                fontFamily: 'Montserrat',
                fontSize: '20px',
                fontWeight: 600,
              }}>
              Participant Portal
            </Row>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-end">
          <BSNav className="navigation-bar">
            <LinkContainer to="/">
              <NavItem>
                <i
                  className="material-icons md-36"
                  style={{ paddingTop: 7, color: '#aaa' }}>
                  home
                </i>
              </NavItem>
            </LinkContainer>

            <Button bsPrefix="nav-help" onClick={showFaqs}>
              <NavItem>
                <i
                  className="material-icons md-36"
                  style={{ paddingTop: 7, color: '#aaa' }}>
                  help
                </i>
              </NavItem>
            </Button>

            <LinkContainer style={{ margin: 15 }} to="/settings">
              <NavItem>
                <Image src={Settings} />
              </NavItem>
            </LinkContainer>

            <NavItem>
              <Dropdown>
                <Dropdown.Toggle
                  as={profileButton}
                  bsPrefix="profile-button" />
                <Dropdown.Menu alignRight>
                  <LinkContainer to="/profile">
                    <Dropdown.Item>Profile</Dropdown.Item>
                  </LinkContainer>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={signOut}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </NavItem>
          </BSNav>
        </Navbar.Collapse>
      </Navbar>
    </Row>
  ) : '';
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.user;
  return { isAuthenticated };
};

const mapDispatchToProps = {
  signOut: signOutAction,
  showFaqs: showFaqsAction,
};

Nav.propTypes = {
  signOut: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  showFaqs: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
