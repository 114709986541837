import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { Redirect, useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import BirthDateSelection from '../components/BirthDateSelection';
import DiscoverMePanel from '../components/DiscoverMePanel';

function generateInvalidURL() {
  return (
    <div>
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          fontFamily: 'Montserrat',
          fontSize: '26px',
          alignText: 'center',
          width: '100%',
          fontWeight: 400,
        }}
      >
        INVALID URL
      </div>
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          fontFamily: 'Montserrat',
          fontSize: '20px',
          alignText: 'center',
          width: '100%',
          fontWeight: 400,
        }}
      >
        The URL is not valid. Please make sure you have the full url provided
        in the email or SMS.
      </div>
    </div>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ForgotPasswordCode = () => {
  const [code, setCode] = useState('');
  const [username, setUsername] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [password1Error, setPassword1Error] = useState('');
  const [password2Error, setPassword2Error] = useState('');
  const [toHome, setToHome] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dob, setDob] = useState({ day: '1', month: 'Jan', year: '2004' });
  const [dateOfBirthError, setDateOfBirthError] = useState('');
  const [invalid, setInvalid] = useState(false);

  const query = useQuery();

  useEffect(() => {
    setUsername(query.get('username'));
    setCode(query.get('code'));
  }, [query]);

  const passwordReset = () => {
    if (password1Error !== ''
      || password2Error !== ''
      || password1 === ''
      || password2 === '') return;

    setIsLoading(true);

    const months = moment.monthsShort();

    const data = {
      username,
      password: code,
      validationData: {
        birth_date: `${dob.year}-${String(months.indexOf(dob.month) + 1).padStart(2, '0')}-${String(
          dob.day,
        ).padStart(2, '0')}`,
      },
    };
    Auth.signIn(data)
      .then(user => {
        Auth.completeNewPassword(user, password1)
          .then(() => setToHome(true))
          .catch(err => {
            // TODO handle errors more elegantly
            console.log(err); //eslint-disable-line
          });
      })
      .catch(err => {
        if (err.message.search('Incorrect Date of Birth Supplied') !== -1) {
          setDateOfBirthError('The date of birth entered is incorrect. Please check and re-enter this.');
        } else if (err.code === 'NotAuthorizedException') {
          setInvalid(true);
        }
        // TODO handle errors more elegantly
        console.log(err); //eslint-disable-line
      });
    setIsLoading(false);
  };

  const handleDateOfBirthChange = event => {
    const { id } = event.target;
    const newDob = { ...dob };
    newDob[id] = event.target.value;
    setDob(newDob);
    setDateOfBirthError('');
  };

  useEffect(() => {
    setPassword1Error('');
    if (password1.search(/\d/) === -1) {
      setPassword1Error('Password must contain a number');
    } else if (password1.search(/[a-z]/) === -1) {
      setPassword1Error('Password must contain a lowercase character');
    } else if (password1.search(/[A-Z]/) === -1) {
      setPassword1Error('Password must contain an uppercase character');
    } else if (password1.length < 12) {
      setPassword1Error('Password must be longer than 11 characters');
    }
  }, [password1]);

  useEffect(() => {
    setPassword2Error(password2 === password1 ? '' : 'Passwords do not match');
  }, [password1, password2]);

  const generateForm = () => (
    <div>
      <p
        style={{
          fontFamily: 'Montserrat',
          fontSize: '14px',
          alignText: 'center',
          width: '100%',
        }}
      >
        Please confirm your
        <strong> date of birth </strong>
        and create a new
        <strong> password </strong>
      </p>
      <Form
        style={{
          justifyContent: 'center',
          textAlign: 'left',
          fontFamily: 'Montserrat',
          fontSize: '14px',
          fontWeight: 300,
        }}
      >
        <BirthDateSelection
          day={dob.day}
          month={dob.month}
          year={dob.year}
          change={event => handleDateOfBirthChange(event)}
          error={dateOfBirthError}
        />

        <Form.Group controlId="password1">
          <Form.Label>Create New Password</Form.Label>
          <Form.Control
            className={`form-control ${password1Error ? 'is-invalid' : ''}`}
            value={password1}
            autoComplete="new-password"
            onChange={event => setPassword1(event.target.value)}
            type="password"
            placeholder="Password"
          />
          <div className="invalid-feedback">{password1Error}</div>
        </Form.Group>

        <Form.Group controlId="password2">
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control
            className={`form-control ${password2Error ? 'is-invalid' : ''}`}
            value={password2}
            autoComplete="new-password"
            onChange={event => setPassword2(event.target.value)}
            type="password"
            placeholder="Password"
          />
          <div className="invalid-feedback">{password2Error}</div>
        </Form.Group>
      </Form>
      <Button
        disabled={isLoading}
        onClick={!isLoading ? passwordReset : null}
        className="login-btn"
        variant="primary"
        type="submit"
      >
        {isLoading ? 'Loading...' : 'Reset Password'}
      </Button>
    </div>
  );

  if (toHome === true) {
    return <Redirect to="/" />;
  }

  return (
    <DiscoverMePanel>
      <h2>WELCOME TO THE</h2>
      <h1>PARTICIPANT PORTAL</h1>
      {username && code && !invalid ? (
        <div>{generateForm()}</div>
      ) : (
        <div>{generateInvalidURL()}</div>
      )}
    </DiscoverMePanel>
  );
};

export default ForgotPasswordCode;
