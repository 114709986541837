import React from 'react';
import moment from 'moment';

export const getDays = () => {
  const output = [];
  for (let i = 1; i <= 31; i += 1) {
    output.push(<option key={i}>{i}</option>);
  }
  return output;
};

export const getMonths = () => {
  const output = [];
  for (let i = 1; i <= 12; i += 1) {
    output.push(<option key={i}>{i}</option>);
  }
  return output;
};

export const getYears = () => {
  const output = [];
  for (let i = 16; i <= 150; i += 1) {
    output.push(<option key={i}>{2020 - i}</option>);
  }
  return output;
};

export function sortByMomentFirstColumn(a, b) {
  const momentA = moment.utc(a[0]);
  const momentB = moment.utc(b[0]);
  if (momentA.isSame(momentB)) return 0;
  return momentA.isBefore(momentB) ? -1 : 1;
}

export function sortByMomentSecondColumn(a, b) {
  const momentA = moment.utc(a[1]);
  const momentB = moment.utc(b[1]);
  if (momentA.isSame(momentB)) return 0;
  return momentA.isBefore(momentB) ? -1 : 1;
}

export function getLastYearMoment() {
  const lastYear = moment().year() - 1;
  return moment()
    .year(lastYear)
    .startOf('month');
}

export const isStillAllowedToBeActive = lastActiveTime => {
  const now = new Date();
  const timeLoggedIn = now.getTime() - lastActiveTime.getTime();
  if (timeLoggedIn > 300000) {
    return false;
  }
  return true;
};
