import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Login.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Button, Form, Popover, Overlay,
} from 'react-bootstrap';

import { loginAction } from '../actions/userActions';
import DiscoverMePanel from '../components/DiscoverMePanel';
import DocumentModal from '../components/DocumentModal';
import Help from '../documents/Help';

const Login = props => {
  const {
    login, isLoading, loginError, registerSuccess,
  } = props;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showUsernamePopover, setShowUsernamePopover] = useState(false);
  const [showPasswordPopover, setShowPasswordPopover] = useState(false);

  const usernameButtonRef = useRef(null);
  const passwordButtonRef = useRef(null);

  // handle popover dismissal for safari
  useEffect(() => {
    const handleClickOut = event => {
      if (event.target !== usernameButtonRef.current) {
        setShowUsernamePopover(false);
      }
      if (event.target !== passwordButtonRef.current) {
        setShowPasswordPopover(false);
      }
    };

    document.body.addEventListener('click', handleClickOut, false);

    return function cleanup() {
      document.body.removeEventListener('click', handleClickOut, false);
    };
  });

  const performLogin = event => {
    event.preventDefault();
    event.stopPropagation();
    login(username, password);
  };

  return (
    <DiscoverMePanel>
      <h2>WELCOME TO THE</h2>
      <h1>PARTICIPANT PORTAL</h1>
      <div>
        { registerSuccess ? (
          <p>
            Thank you for registering on the portal.
            Please login below using the
            <strong> username </strong>
            and
            <strong> password </strong>
            you just created.
          </p>
        ) : (
          <p
            style={{
              fontFamily: 'Montserrat',
              fontSize: '14px',
              textAlign: 'center',
              width: '100%',
            }}>
            Please enter your
            <strong> username </strong>
            and
            <strong> password</strong>
          </p>
        ) }
        <Form onSubmit={performLogin} className="login-form">
          <Form.Group controlId="username">
            <Form.Label>
              Username&nbsp;
              <button
                type="button"
                className="info"
                ref={usernameButtonRef}
                onBlur={() => setShowUsernamePopover(false)}
                onClick={() => setShowUsernamePopover(true)}>
                <span className="material-icons">info</span>
              </button>
            </Form.Label>
            <Overlay
              target={usernameButtonRef.current}
              show={showUsernamePopover}
              placement="right">
              <Popover>
                <Popover.Content>
                  <p>
                    Please enter the username you set when you registered for the Discover Me portal.&nbsp;
                    <b>Portal registration is separate to the process which took place at your GP practice.</b>
                    &nbsp;If you have forgotten your username, click the “Forgot username?” link.
                  </p>
                  <p>
                    For more help,&nbsp;
                    <button type="button" onClick={() => setShowHelpModal(true)}>click here</button>
                  </p>
                </Popover.Content>
              </Popover>
            </Overlay>
            <Form.Control
              className={`form-control ${loginError ? 'is-invalid' : ''}`}
              value={username}
              onChange={event => setUsername(event.target.value)}
              type="username"
              placeholder="Enter username"
              autoCapitalize="off"
              tabIndex={1} // eslint-disable-line
            />
            <Form.Text className="text-muted" />
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>
              Password&nbsp;
              <button
                type="button"
                className="info"
                ref={passwordButtonRef}
                onBlur={() => setShowPasswordPopover(false)}
                onClick={() => setShowPasswordPopover(true)}>
                <span className="material-icons">info</span>
              </button>
            </Form.Label>
            <Overlay
              target={passwordButtonRef.current}
              show={showPasswordPopover}
              placement="right">
              <Popover>
                <Popover.Content>
                  <p>
                    Please enter the password you set when you registered for the Discover Me portal.&nbsp;
                    <b>Portal registration is separate to the process which took place at your GP practice.</b>
                    &nbsp;If you have forgotten your password, click the “Forgot password?” link.
                  </p>
                  <p>
                    For more help,&nbsp;
                    <button type="button" onClick={() => setShowHelpModal(true)}>click here</button>
                  </p>
                </Popover.Content>
              </Popover>
            </Overlay>
            <Form.Control
              className={`form-control ${loginError ? 'is-invalid' : ''}`}
              value={password}
              onChange={event => setPassword(event.target.value)}
              type="password"
              placeholder="Enter password"
              autoCapitalize="off"
              tabIndex={2} // eslint-disable-line
            />
            <div className="invalid-feedback">
              {loginError ? 'Incorrect username or password' : ''}
            </div>
          </Form.Group>
          <Button
            disabled={isLoading}
            className="login-btn"
            variant="primary"
            tabIndex={3} // eslint-disable-line
            type="submit">
            {isLoading ? 'Loading...' : 'Login'}
          </Button>
        </Form>
        <div className="forgotten">
          <Link to="/login/username_reset">Forgot username?</Link>
        </div>
        <div className="forgotten">
          <Link to="/login/password_reset">Forgot password?</Link>
        </div>
        <div className="forgotten">
          <Button variant="link" className="help" onClick={() => setShowHelpModal(true)}>Help</Button>
        </div>
        <div style={{ marginTop: 10 }}>
          If you have not registered yet, please follow the registration link
          provided in the email or text message that was sent to you.
        </div>
      </div>
      <DocumentModal
        title="Discover Me Participant Portal - Help"
        msg={<Help />}
        isOpen={showHelpModal}
        hide={() => setShowHelpModal(false)} />
    </DiscoverMePanel>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loginError: PropTypes.string.isRequired,
  registerSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const {
    register: { registerSuccess },
    user: { isLoading, loginError },
  } = state;
  return { isLoading, loginError, registerSuccess };
};

const mapDispatchToProps = {
  login: loginAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
