import { Auth } from 'aws-amplify';
import moment from 'moment';
import * as types from './registerTypes';

export const resetRegisterStateAction = () => ({ type: types.RESET_REGISTRATION_STATE });
export const newRegisterLinkFetching = () => ({ type: types.NEW_LINK_FETCHING });
export const newRegisterLinkError = error => ({ type: types.NEW_LINK_ERROR, error });
export const newRegisterLinkSuccess = () => ({ type: types.NEW_LINK_SUCCESS });

export const newRegisterLinkAction = (id, temporaryPassword, year, month, day) => async dispatch => {
  dispatch(newRegisterLinkFetching());
  const months = moment.monthsShort();
  try {
    const monthString = String(months.indexOf(month) + 1).padStart(2, '0');
    const dayString = String(day).padStart(2, '0');
    const birthDate = `${year}-${monthString}-${dayString}`;
    const data = {
      username: id,
      password: temporaryPassword,
      validationData: {
        birth_date: birthDate,
        reset: 'true',
      },
    };
    await Auth.signIn(data);
    dispatch(newRegisterLinkSuccess());
  } catch (err) {
    // TODO handle errors more elegantly
    console.log(err); //eslint-disable-line
    dispatch(newRegisterLinkError());
  }
};

export const birthdateChecking = () => ({ type: types.BIRTHDATE_CHECKING });
export const birthdateCheckPassed = user => ({ type: types.BIRTHDATE_CHECK_PASSED, user });
export const registerDobError = error => ({ type: types.REGISTER_DOB_ERROR, error });
export const registerLinkError = () => ({ type: types.REGISTER_LINK_ERROR });
export const registerUserError = () => ({ type: types.REGISTER_USER_ERROR });
export const birthdateCheckError = error => ({ type: types.BIRTHDATE_CHECK_ERROR, error });

export const checkBirthdateAction = (id, temporaryPassword, year, month, day) => async dispatch => {
  dispatch(birthdateChecking());

  const months = moment.monthsShort();
  const monthString = String(months.indexOf(month) + 1).padStart(2, '0');
  const dayString = String(day).padStart(2, '0');
  const birthDate = `${year}-${monthString}-${dayString}`;

  const data = {
    username: id,
    password: temporaryPassword,
    validationData: {
      birth_date: birthDate,
      registration: 'true',
    },
  };

  let user;

  try {
    user = await Auth.signIn(data);
  } catch (err) {
    if (err.message.search('Incorrect Date of Birth Supplied') !== -1) {
      return dispatch(registerDobError('The date of birth entered is incorrect. Please check and re-enter this.'));
    }
    if (err.code === 'NotAuthorizedException') {
      return dispatch(registerLinkError());
    }
    if (err.message.search('User already registered') !== -1) {
      return dispatch(registerUserError());
    }
    // TODO handle errors more elegantly
    console.log(err); //eslint-disable-line
    return dispatch(birthdateCheckError('There was a problem verifying the birthdate. Please try again or contact a member of our support team'));
  }

  return dispatch(birthdateCheckPassed(user));
};

export const registering = () => ({ type: types.REGISTERING });
export const registerError = error => ({ type: types.REGISTER_ERROR, error });
export const registerSuccess = () => ({ type: types.REGISTER_SUCCESS });
export const registerNameTakenError = () => ({ type: types.REGISTER_NAME_TAKEN_ERROR });

export const registerAction = (password1, username) => async (dispatch, getState) => {
  dispatch(registering());

  const { register: { user } } = getState();

  try {
    await Auth.completeNewPassword(user, password1, {
      preferred_username: username,
      'custom:tc_view': 'false',
      'custom:prod_and_services': 'false',
    });
  } catch (err) {
    if (err.code === 'AliasExistsException') {
      return dispatch(registerNameTakenError());
    }
    // TODO handle errors more elegantly
    console.log(err); //eslint-disable-line
    return dispatch(registerError('Could not complete registration. Please try again or contact a member of our support team'));
  }

  await Auth.signOut();

  return dispatch(registerSuccess());
};

export const usernameResetting = () => ({ type: types.USERNAME_RESETTING });
export const usernameResettingError = error => ({ type: types.USERNAME_RESETTING_ERROR, error });
export const usernameResettingSuccess = () => ({ type: types.USERNAME_RESETTING_SUCCESS });

export const forgotUsernameAction = (day, month, year, email, mobile) => async dispatch => {
  dispatch(usernameResetting());

  const months = moment.monthsShort();
  const monthString = String(months.indexOf(month) + 1).padStart(2, '0');
  const dayString = day.toString().padStart(2, '0');

  const data = {
    username: 'sendUsername',
    password: 'password',
    validationData: {
      birth_date: `${year.toString()}-${monthString}-${dayString}`,
      email,
      mobile,
    },
  };

  try {
    await Auth.signIn(data);
  } catch (err) {
    // email or sms sent
    dispatch(usernameResettingSuccess());
  }
};
