import React from 'react';
import './PrivacyPolicy.scss';

export default function PrivacyNotice() {
  return (
    <div className="PrivacyPolicyContainer">
      <h2>Privacy notice for study participants</h2>

      <p>This Privacy Notice describes how we collect, store and process personal information about you as a participant in a study run by Omnigen.&nbsp;</p>

      <p>As an organisation, Omnigen is a ‘data controller’ and we want you to be clear about how we collect, store and use personal information about you, and how we protect the privacy of your personal information. It is therefore important that you read this Privacy Notice as it will explain:</p>

      <ul>
        <li>Why we collect personal information</li>
        <li>How we use and share that information</li>
        <li>How we keep the information private</li>
        <li>How to find out more</li>
      </ul>

      <p>
        Your privacy matters to us. If you have any questions about this Privacy Notice then please contact our Data Protection Office at
        <a href="mailto:dpo@omnigenbiodata.co.uk"> dpo@omnigenbiodata.co.uk </a>
        .
      </p>

      <h1>Why do we collect personal information?</h1>

      <p>Health and care research should serve the public interest, which means that we have to demonstrate that our research serves the interests of society as a whole. We do this by following the UK Policy Framework for Health and Social Care Research.</p>

      <p>We use personally-identifiable information to conduct research to improve health and care. As a Research &amp; Development company, we have a legitimate interest in using information relating to your health and care for research studies, when you agree to take part in a research study. This means that we will use your data, collected in the course of a research study, in the ways needed to conduct and analyse the research study.&nbsp;</p>

      <h1>How does this study comply with data protection law?</h1>

      <p>Because we are using your personal information for research, under data protection law, your rights to access or change your personal information are limited. This is because we need to manage your information in specific ways in order for the research to be reliable and accurate. To safeguard your rights, we will use the minimum personally-identifiable information possible.</p>

      <p>If you decide to withdraw your consent from a research study, we will not erase or destroy any information and samples already collected (including your identifiable information), and we will be unable to recall any information and samples that have already been shared for research.&nbsp;</p>

      <h1>How will we use your information?</h1>

      <p>Over the coming years, a wide range of research may be carried out using your information and sample(s). This research may be related to health or diseases, including research that may help with planning clinical services or developing new medicines, commercial technologies, products or services. Your information and sample(s) may be analysed using new tests and techniques that don’t exist yet, including new genetic tests. Importantly, sharing of your information and sample(s) for these research activities will continue after your death, with the aim of benefitting research and healthcare in the future.</p>

      <p>Your information could be combined with information about you from other sources held by researchers, the NHS or government. Where this information could identify you, the information will be held securely with strict arrangements about who can access the information.&nbsp;</p>

      <p>The information will only be used for the purpose of health and care research, or to contact you about future opportunities to participate in research. It will not be used to make decisions about future services available to you, such as insurance.&nbsp;</p>

      <h1>Who will your information be shared with?</h1>

      <p>To make sure that the information and samples collected from our studies can provide the greatest benefit for society and medical research into human health and disease, we or trusted partner organisations may share your information and samples with other institutions and organisations globally for analysis and further research. These may include research institutions, commercial organisations, universities, GP practices, hospitals, charities and service providers.&nbsp;</p>

      <h1>How do we keep information private?</h1>

      <p>Importantly, only information and samples which do not include your name or other information that directly identifies you will be shared for analysis. Where we share your information and samples, we will ensure there are safeguards to keep them secure and private.</p>

      <p>We will not allow access to your information or sample(s) to the police, security services, relatives or lawyers, unless required to do so by the UK legal courts.</p>

      <p>Where there is a risk that you can be identified, your data will only be used in research that has been independently reviewed by an ethics committee.</p>

      <h1>What to do if you have queries?</h1>

      <p>If you want to know more about your data and samples, you can read the Frequently Asked Questions page for the study you’ve joined.</p>

      <p>You can also read Omnigen’s company privacy notice here.</p>

      <p>If you have queries or wish to raise a complaint on how we have handled your personal data, you can contact our Data Protection Officer who will investigate the matter. If you are not satisfied with our response or believe we are processing your personal data in a way that is not lawful you can complain to the Information Commissioner’s Office (ICO).</p>

      <p>
        You can contact our Data Protection Officer at
        <a href="mailto:dpo@omnigenbiodata.co.uk"> dpo@omnigenbiodata.co.uk </a>
      </p>
      <hr className="uk-divider-small" />
      <p>
        <a href="https://discovermestudy.org/privacy" title="Privacy Notice">View as webpage</a>
      </p>
    </div>
  );
}
