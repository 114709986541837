import * as types from '../actions/registerTypes';

export const INITIAL_STATE = {
  isLoading: false,
  passedDateOfBirthCheck: false,
  dateOfBirthError: '',
  invalid: false,
  userRegistered: false,
  error: '',
  registerSuccess: false,
  user: undefined,
};

export const register = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REGISTERING:
    case types.NEW_LINK_FETCHING:
    case types.BIRTHDATE_CHECKING:
      return {
        ...state,
        isLoading: true,
      };
    case types.NEW_LINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.REGISTER_DOB_ERROR:
      return {
        ...state,
        dateOfBirthError: action.error,
        isLoading: false,
      };
    case types.REGISTER_LINK_ERROR:
      return {
        ...state,
        isLoading: false,
        invalid: true,
      };
    case types.REGISTER_USER_ERROR:
      return {
        ...state,
        userRegistered: true,
        isLoading: false,
      };
    case types.REGISTER_NAME_TAKEN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: 'Username already exists',
      };
    case types.REGISTER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        registerSuccess: true,
        error: '',
      };
    case types.BIRTHDATE_CHECK_PASSED:
      return {
        ...state,
        isLoading: false,
        passedDateOfBirthCheck: true,
        user: action.user,
      };
    case types.BIRTHDATE_CHECK_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case types.USERNAME_RESETTING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case types.USERNAME_RESETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.USERNAME_RESETTING_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case types.RESET_REGISTRATION_STATE:
    default:
      return state;
  }
};
