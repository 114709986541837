import React from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

import Home from './Routes/Home';
import Register from './Routes/Register';
import Login from './Routes/Login';
import ForgotPassword from './Routes/ForgotPassword';
import ForgotUsername from './Routes/ForgotUsername';
import ForgotPasswordCode from './Routes/ForgotPasswordCode';

import HealthDashboard from './Routes/HealthDashboard';
import Settings from './Routes/Settings';
import Profile from './Routes/Profile';
import Prescriptions from './Routes/Health/Prescriptions';
import Appointments from './Routes/Health/Appointments';
import Conditions from './Routes/Health/Conditions';
import Allergies from './Routes/Health/Allergies';
import Vaccinations from './Routes/Health/Vaccinations';
import Ancestry from './Routes/Ancestry';
import Resample from './Routes/Resample';

export default function Routes({ appProps }) {
  const { isAuthenticated, isLoading } = appProps;
  return (
    <Switch>
      <UnauthenticatedRoute path="/register" exact isAuthenticated={isAuthenticated}>
        <Register {...appProps} />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/login" exact isAuthenticated={isAuthenticated}>
        <Login {...appProps} />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/login/password_reset" exact isAuthenticated={isAuthenticated}>
        <ForgotPassword {...appProps} />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/login/username_reset" exact isAuthenticated={isAuthenticated}>
        <ForgotUsername {...appProps} />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/forgot_password" exact isAuthenticated={isAuthenticated}>
        <ForgotPasswordCode {...appProps} />
      </UnauthenticatedRoute>
      <AuthenticatedRoute path="/" exact isAuthenticated={isAuthenticated} isLoading={isLoading}>
        <Home {...appProps} />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/health" exact isAuthenticated={isAuthenticated} isLoading={isLoading}>
        <HealthDashboard {...appProps} />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/settings" exact isAuthenticated={isAuthenticated} isLoading={isLoading}>
        <Settings {...appProps} />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/profile" exact isAuthenticated={isAuthenticated} isLoading={isLoading}>
        <Profile {...appProps} />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/health/prescriptions" exact isAuthenticated={isAuthenticated} isLoading={isLoading}>
        <Prescriptions {...appProps} />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/health/conditions" exact isAuthenticated={isAuthenticated} isLoading={isLoading}>
        <Conditions {...appProps} />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/health/appointments" exact isAuthenticated={isAuthenticated} isLoading={isLoading}>
        <Appointments {...appProps} />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/health/allergies" exact isAuthenticated={isAuthenticated} isLoading={isLoading}>
        <Allergies {...appProps} />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/health/vaccinations" exact isAuthenticated={isAuthenticated} isLoading={isLoading}>
        <Vaccinations {...appProps} />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/ancestry" exact isAuthenticated={isAuthenticated}>
        <Ancestry {...appProps} />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/resample" exact isAuthenticated={isAuthenticated}>
        <Resample />
      </AuthenticatedRoute>
    </Switch>
  );
}

Routes.propTypes = {
  appProps: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    userHasAuthenticated: PropTypes.func.isRequired,
    userAttributes: PropTypes.shape({
      sub: PropTypes.string,
      birthdate: PropTypes.string,
      email_verified: PropTypes.string,
      preferred_username: PropTypes.string,
      given_name: PropTypes.string,
      family_name: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
};
