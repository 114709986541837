import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import TimeLine from '../../components/TimeLine';
import './Appointments.scss';
import DataTable from '../../components/DataTable';
import BackButton from '../../components/BackButton';
import { showFaqsAction } from '../../actions/userActions';

const Appointments = (props) => {
  const { appointments: oldAppointments, showFaqs, usingIE } = props;

  const lastYear = moment().year() - 1;
  const cutoffDate = moment()
    .year(lastYear)
    .date(1)
    .hour(0)
    .minutes(0)
    .seconds(0);

  const appointments = oldAppointments
    .sort()
    .reverse()
    .filter((date) => moment(date).hour(0).isAfter(cutoffDate));

  const ieStyle = usingIE ? { height: appointments.length * 60 + 300 } : {};

  return (
    <div className={classnames('appointments')}>
      <BackButton backLink="/health" />
      <Row>
        <Col>
          <h2 className="page-title">Appointments / Contacts</h2>
        </Col>
      </Row>
      <Row className="page-context">
        <Col>
          <p>
            This lists the dates of your GP practice appointments and other
            contact (such as telephone consultations) from the last 12 months
            that are recorded in your GP medical records. This information does
            not include appointments at other healthcare facilities such as at
            work or at a hospital or private clinic.
          </p>

          <p>
            It can take some time for changes made in your GP medical records to
            appear on this website. This means that the information you can see
            today may not be completely up to date if your appointments and
            contacts history has changed recently.
          </p>

          <p>
            To find out more about the information on this page, please visit
            our
            <Button variant="link" onClick={() => showFaqs('appointments')}>
              Frequently Asked Questions (FAQs).
            </Button>
          </p>
        </Col>
      </Row>

      <Row className="health-data-content" style={ieStyle}>
        <Col>
          <TimeLine color="#70AD47" data={appointments} />

          {appointments.length > 0 ? (
            <DataTable
              headers={['Date of appointment']}
              data={appointments.map((date) => [
                moment.utc(date).format('Do MMM YYYY'),
              ])}
              name="appointments"
              color="#70AD47"
              offColor="#B7D6A2"
            />
          ) : (
            <Row className="disabled">
              There are no appointments in your recent medical record.
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

Appointments.propTypes = {
  appointments: PropTypes.arrayOf(PropTypes.string),
  showFaqs: PropTypes.func.isRequired,
  usingIE: PropTypes.bool.isRequired,
};

Appointments.defaultProps = {
  appointments: [],
};

const mapStateToProps = (state) => {
  const {
    usingIE,
    ehr: { appointments },
  } = state.user;
  return { appointments, usingIE };
};

const mapDispatchToProps = {
  showFaqs: showFaqsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
