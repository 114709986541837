import { Auth } from 'aws-amplify';
import { getSignURL, invokeLambda } from '../getData';
import { isStillAllowedToBeActive } from '../utils/DateUtils';
import { version as tocVersion } from '../documents/TermsAndConditions';
import { version as healthPopUpVersion } from '../documents/HealthMessage';

export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_FAILURE = 'SETTINGS_FAILURE';
export const SETTINGS = 'SETTINGS';

export const settingsSuccess = (settings) => ({
  type: SETTINGS_SUCCESS,
  settings,
});

export const settingsFailure = (error) => ({ type: SETTINGS_FAILURE, error });

export const settings = () => ({ type: SETTINGS });

export const fetchSettings = () => async (dispatch) => {
  dispatch(settings());
  try {
    const userInfo = await Auth.currentUserInfo();
    const s = {
      showAncestry: userInfo.attributes['custom:ancestry'] === 'True',
      showEhr: userInfo.attributes['custom:ehr'] === 'True',
      showTocs:
        userInfo.attributes['custom:viewed_terms'] === undefined
        || userInfo.attributes['custom:viewed_terms'] !== tocVersion,
      showHealth: userInfo.attributes['custom:viewed_health_popup'] === undefined
        || userInfo.attributes['custom:viewed_health_popup'] !== healthPopUpVersion,
      showTPP: userInfo.attributes['custom:tpp'] === 'True',
      showProductsAndServices:
        userInfo.attributes['custom:prod_and_services'] === 'True',
      userAttributes: userInfo.attributes,
    };
    dispatch(settingsSuccess(s));
  } catch (err) {
    dispatch(settingsFailure(err.message));
  }
};

export const EHR_SUCCESS = 'EHR_SUCCESS';
export const EHR_FAILURE = 'EHR_FAILURE';
export const FETCHING_EHR = 'FETCHING_EHR';

export const ehrSuccess = (ehr) => ({ type: EHR_SUCCESS, ehr });

export const ehrFailure = (error) => ({ type: EHR_FAILURE, error });

export const fetchingEhr = () => ({ type: FETCHING_EHR });

const fetchData = async (tableName) => {
  let user;
  let credentials;
  let signedurl;
  let lambdaCredentials;
  try {
    user = await Auth.currentAuthenticatedUser({ bypassCache: false });
    credentials = await Auth.currentCredentials();
    signedurl = await getSignURL(credentials);
    lambdaCredentials = Auth.essentialCredentials(credentials);
  } catch (exception) {
    throw new Error(`AWS Error - ${exception.message}`);
  }

  let data;

  try {
    data = await invokeLambda(
      lambdaCredentials,
      signedurl,
      user.signInUserSession.accessToken.jwtToken,
      user.username,
      tableName,
      'READ',
    );
  } catch (exception) {
    throw new Error(`Lambda Error - ${exception.message}`);
  }

  let toReturn;
  const payload = JSON.parse(data.Payload);
  if (payload.status === 'success') {
    if (payload.items.length !== 1) {
      console.error("There are no user items"); //eslint-disable-line
      throw new Error('Error with user');
    }
    [toReturn] = payload.items;
  } else {
    // TODO handle errors more elegantly
    console.error(payload); //eslint-disable-line
    console.error(payload.exception_message); //eslint-disable-line
    throw new Error(payload.exception_message);
  }

  return toReturn;
};

export const fetchEhrData = () => async (dispatch) => {
  dispatch(fetchingEhr());
  let info;
  try {
    info = await fetchData('PORTAL_EHR_DATA');
  } catch (exception) {
    return dispatch(ehrFailure(exception.message));
  }

  const {
    PORTAL_ALLERGIES: allergies,
    PORTAL_PRESCRIPTIONS: prescriptions,
    PORTAL_CONDITIONS: conditions,
    PORTAL_VACCINATIONS: vaccinations,
    PORTAL_APPOINTMENTS: appointments,
  } = info;

  return dispatch(
    ehrSuccess({
      allergies,
      prescriptions,
      conditions,
      vaccinations,
      appointments,
    }),
  );
};

export const ANCESTRY_SUCCESS = 'ANCESTRY_SUCCESS';
export const ANCESTRY_FAILURE = 'ANCESTRY_FAILURE';
export const FETCHING_ANCESTRY = 'FETCHING_ANCESTRY';

export const ancestrySuccess = (ancestry) => ({
  type: ANCESTRY_SUCCESS,
  ancestry,
});

export const ancestryFailure = (error) => ({ type: ANCESTRY_FAILURE, error });

export const fetchingAncestry = () => ({ type: FETCHING_ANCESTRY });

export const fetchAncestryData = () => async (dispatch) => {
  dispatch(fetchingAncestry());
  let info;
  try {
    info = await fetchData('PORTAL_ANCESTRY_DATA');
  } catch (exception) {
    return dispatch(ancestryFailure(exception.message));
  }
  const { PORTAL_ANCESTRY: ancestry } = info;
  return dispatch(ancestrySuccess({ data: ancestry }));
};

export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export const LOGGING_IN = 'LOGGING_IN';
export const LOG_OUT = 'LOG_OUT';

export const signedOut = () => ({ type: LOG_OUT });

export const signOut = () => async (dispatch) => {
  await Auth.signOut();
  dispatch(signedOut());
};

export const loginSuccess = (username) => ({ type: LOG_IN_SUCCESS, username });

export const loginFailure = (error) => ({ type: LOG_IN_FAILURE, error });

export const loggingIn = () => ({ type: LOGGING_IN });

export const SET_LAST_ACTIVE_TIME = 'SET_LAST_ACTIVE_TIME';
export const setLastActiveTimeAction = () => ({ type: SET_LAST_ACTIVE_TIME });

export const reauthenticateAction = () => async (dispatch, getState) => {
  dispatch(loggingIn());
  const {
    user: { lastActiveTime },
  } = getState();
  try {
    await Auth.currentSession();
    if (isStillAllowedToBeActive(lastActiveTime)) {
      dispatch(setLastActiveTimeAction());
    } else {
      dispatch(signOut());
      return;
    }
  } catch (error) {
    dispatch(loginFailure(error.message));
    return;
  }

  dispatch(loginSuccess());
  dispatch(fetchEhrData());
  dispatch(fetchAncestryData());
  dispatch(fetchSettings());
};

export const loginAction = (username, password) => async (dispatch) => {
  dispatch(loggingIn());
  try {
    await Auth.signIn({ username, password });
    dispatch(setLastActiveTimeAction());
    dispatch(loginSuccess());
    dispatch(reauthenticateAction());
  } catch (err) {
    // TODO handle errors more elegantly
    // eslint-disable-next-line no-console
    console.log(err);
    dispatch(loginFailure(err.message));
  }
};

export const UPDATING_SETTINGS = 'UPDATING_SETTINGS';
export const PASSWORD_UPDATED = 'PASSWORD_UPDATED';
export const UPDATED_SETTINGS_SUCCESS = 'UPDATED_SETTINGS_SUCCESS';
export const UPDATED_SETTINGS_FAIL = 'UPDATED_SETTINGS_FAIL';

export const updatingSettings = () => ({ type: UPDATING_SETTINGS });
export const updatedSettingsSuccess = () => ({
  type: UPDATED_SETTINGS_SUCCESS,
});
export const passwordUpdated = () => ({ type: PASSWORD_UPDATED });
export const updatedSettingsFail = (error) => ({
  type: UPDATED_SETTINGS_FAIL,
  error,
});

export const changePasswordAction = (currentPassword, newPassword) => async (
  dispatch,
) => {
  dispatch(updatingSettings());
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, currentPassword, newPassword);
    dispatch(passwordUpdated());
  } catch (error) {
    if (error.code === 'NotAuthorizedException') {
      dispatch(updatedSettingsFail('Incorrect password'));
    } else {
      dispatch(updatedSettingsFail('Error updating password'));
    }
    return;
  }
  dispatch(fetchSettings());
};

export const updateSettingsAction = (id, previous) => async (dispatch) => {
  dispatch(updatingSettings());
  try {
    let flag = '';
    if (id === 'ancestry') {
      flag = 'custom:ancestry';
    } else if (id === 'health') {
      flag = 'custom:ehr';
    } else if (id === 'productsAndServices') {
      flag = 'custom:prod_and_services';
    }

    const boolValue = (!previous).toString();
    const value = boolValue.charAt(0).toUpperCase() + boolValue.slice(1);
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.updateUserAttributes(user, {
      [flag]: value,
    });

    if (result === 'SUCCESS') {
      dispatch(updatedSettingsSuccess());
    } else {
      throw new Error(`Settings Error: ${result}`);
    }
  } catch (error) {
    console.error("error", error); // eslint-disable-line
    dispatch(updatedSettingsFail(error.message));
    return;
  }
  dispatch(fetchSettings());
};

export const ACCEPTING_TOCS = 'ACCEPTING_TOCS';
export const ACCEPTED_TOCS = 'ACCEPTED_TOCS';

export const acceptingTocs = () => ({ type: ACCEPTING_TOCS });
export const acceptedTocs = () => ({ type: ACCEPTED_TOCS });

export const acceptTocsAction = (productsServicesOpt) => async (dispatch) => {
  dispatch(acceptingTocs());
  const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
  const boolValue = productsServicesOpt.toString();
  const value = boolValue.charAt(0).toUpperCase() + boolValue.slice(1);
  const result = await Auth.updateUserAttributes(user, {
    'custom:tc_view': 'True',
    'custom:viewed_terms': tocVersion,
    'custom:prod_and_services': value,
  });

  if (result === 'SUCCESS') {
    dispatch(acceptedTocs());
  } else {
    // TODO handle errors more elegantly
    console.error("Problem accepting tocs"); //eslint-disable-line
  }
};

export const CONFIRMING_HEALTH = 'CONFIRMING_HEALTH';
export const CONFIRMED_HEALTH = 'CONFIRMED_HEALTH';

export const confirmingHealth = () => ({ type: CONFIRMING_HEALTH });
export const confirmedHealth = () => ({ type: CONFIRMED_HEALTH });

export const confirmHealthAction = () => async (dispatch) => {
  dispatch(confirmingHealth());
  const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
  const result = await Auth.updateUserAttributes(user, {
    'custom:viewed_health_popup': healthPopUpVersion,
  });

  if (result === 'SUCCESS') {
    dispatch(confirmedHealth());
    dispatch(fetchSettings());
  } else {
    // TODO handle errors more elegantly
    console.error("Problem accepting tocs"); //eslint-disable-line
  }
};

export const RESETTING_PASSWORD = 'RESETTING_PASSWORD';
export const PASSWORD_RESET = 'PASSWORD_RESET';

export const resettingPassword = () => ({ type: RESETTING_PASSWORD });
export const passwordReset = () => ({ type: PASSWORD_RESET });

export const resetPasswordAction = (username) => async (dispatch) => {
  dispatch(resettingPassword());

  const inputData = {
    username,
    password: 'PasswordNotSet',
    validationData: {
      resetPassword: 'true',
    },
  };

  try {
    await Auth.signIn(inputData);
  } catch (err) {
    // password should be reset
  }

  dispatch(passwordReset());
};

export const SHOW_FAQS = 'SHOW_FAQS';
export const HIDE_FAQS = 'HIDE_FAQS';

export const showFaqsAction = (section) => ({ type: SHOW_FAQS, section });
export const hideFaqsAction = () => ({ type: HIDE_FAQS });

export const SET_IE = 'SET_IE';
export const setIEAction = (matches) => ({ type: SET_IE, matches });
