import React from 'react';
import { connect } from 'react-redux';
import { confirmHealthAction } from '../actions/userActions';
import DocumentModal from '../components/DocumentModal';
import HealthMessage from "../documents/HealthMessage" ;

interface Props {
    confirmPopUp: (value: boolean) => void,
    showHealth: boolean,
}

const HealthPopUp = ({ confirmPopUp, showHealth }: Props) => {
    return <DocumentModal
    title=""
    msg={<HealthMessage />}
    isOpen={showHealth}
    hide={() => confirmPopUp(false)}
    pending={false}
    confirm={() => confirmPopUp(false)}
  />
}

const mapStateToProps = (state: any) => {

    const { settings: { showHealth } } = state.user;
    return { showHealth };
  };
  
  const mapDispatchToProps = {
    confirmPopUp: confirmHealthAction,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(HealthPopUp);
  