import {
  LOG_OUT,
  LOG_IN_SUCCESS, LOG_IN_FAILURE, LOGGING_IN,
  SETTINGS_SUCCESS, SETTINGS_FAILURE, SETTINGS,
  EHR_SUCCESS, EHR_FAILURE, FETCHING_EHR,
  ANCESTRY_SUCCESS, ANCESTRY_FAILURE, FETCHING_ANCESTRY,
  UPDATING_SETTINGS, UPDATED_SETTINGS_SUCCESS, UPDATED_SETTINGS_FAIL,
  PASSWORD_UPDATED, ACCEPTING_TOCS, ACCEPTED_TOCS,
  RESETTING_PASSWORD, PASSWORD_RESET, SET_LAST_ACTIVE_TIME,
  SHOW_FAQS, HIDE_FAQS, SET_IE,
} from '../actions/userActions';

export const INITIAL_STATE = {
  isAuthenticated: false,
  attributes: [],
  ehr: {
    isLoading: false,
    allergies: [],
    prescriptions: [],
    conditions: [],
    vaccinations: [],
    appointments: [],
  },
  ancestry: {
    data: [],
    isLoading: false,
  },
  settings: {
    isLoading: false,
    isUpdating: false,
    updateError: '',
    passwordUpdated: false,
    showAncestry: false,
    showEhr: false,
    showTocs: false,
    isAcceptingTocs: false,
    showProductsAndServices: false,
    userAttributes: {},
  },
  validAncestry: false,
  validEhr: false,
  isLoading: false,
  loginError: '',
  reset: {
    resettingPassword: false,
  },
  lastActiveTime: new Date(),
  showFaqsModal: false,
  faqSection: '',
  usingIE: false,
};

export const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGGING_IN:
      return {
        ...state,
        isLoading: true,
      };

    case LOG_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
      };

    case LOG_IN_FAILURE:
      return {
        ...state,
        isLoading: false,
        loginError: action.error,
      };

    case LOG_OUT:
      return INITIAL_STATE;

    case SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.settings,
          isLoading: false,
        },
      };

    case SETTINGS_FAILURE:
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoading: false,
        },
      };

    case SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoading: true,
        },
      };

    case EHR_SUCCESS:
      return {
        ...state,
        ehr: {
          ...state.ehr,
          ...action.ehr,
          isLoading: false,
        },
        validEhr: true,
      };

    case EHR_FAILURE:
      return {
        ...state,
        ehr: {
          ...state.ehr,
          isLoading: false,
        },
      };

    case FETCHING_EHR:
      return {
        ...state,
        ehr: {
          ...state.ehr,
          isLoading: true,
        },
      };

    case ANCESTRY_SUCCESS:
      return {
        ...state,
        ancestry: {
          ...state.ancestry,
          ...action.ancestry,
          isLoading: false,
        },
        validAncestry: true,
      };

    case ANCESTRY_FAILURE:
      return {
        ...state,
        ancestry: {
          ...state.ancestry,
          isLoading: false,
        },
      };

    case FETCHING_ANCESTRY:
      return {
        ...state,
        ancestry: {
          ...state.ancestry,
          isLoading: true,
        },
      };

    case UPDATING_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          isUpdating: true,
          passwordUpdated: false,
        },
      };

    case UPDATED_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          isUpdating: false,
          updateError: '',
          passwordUpdated: false,
        },
      };

    case PASSWORD_UPDATED:
      return {
        ...state,
        settings: {
          ...state.settings,
          isUpdating: false,
          updateError: '',
          passwordUpdated: true,
        },
      };

    case UPDATED_SETTINGS_FAIL:
      return {
        ...state,
        settings: {
          ...state.settings,
          isUpdating: false,
          updateError: action.error,
          passwordUpdated: false,
        },
      };

    case ACCEPTING_TOCS:
      return {
        ...state,
        settings: {
          ...state.settings,
          isAcceptingTocs: true,
        },
      };

    case ACCEPTED_TOCS:
      return {
        ...state,
        settings: {
          ...state.settings,
          isAcceptingTocs: false,
          showTocs: false,
        },
      };

    case RESETTING_PASSWORD:
      return {
        ...state,
        reset: {
          ...state.reset,
          resettingPassword: true,
        },
      };

    case PASSWORD_RESET:
      return {
        ...state,
        reset: {
          ...state.reset,
          resettingPassword: false,
        },
      };

    case SET_LAST_ACTIVE_TIME:
      return {
        ...state,
        lastActiveTime: new Date(),
      };

    case SHOW_FAQS:
      return {
        ...state,
        showFaqsModal: true,
        faqSection: action.section,
      };

    case HIDE_FAQS:
      return {
        ...state,
        showFaqsModal: false,
        faqSection: '',
      };

    case SET_IE:
      return {
        ...state,
        usingIE: action.matches,
      };

    default:
      return state;
  }
};
