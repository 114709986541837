import React from 'react';
import {
  Row, Col, Table,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import './DataTable.scss';

export default function DataTable(props) {
  const {
    headers, data, name,
  } = props;

  return (
    <Row className="data-table">
      {data.length > 0
        ? (
          <Table className="data-table-tb">
            <thead>
              <tr>
                {headers.map(header => <td>{header}</td>)}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => {
                const rowColor = index % 2 === 0 ? `${name}-color` : `${name}-offcolor`;
                return ( // eslint-disable-next-line
                  <tr className={`tr-${rowColor}`}>
                    {row.map(field => <td>{field}</td>)}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Row bsPrefix="data-table-row disabled">
            <Col>There is no data in your recent medical record.</Col>
          </Row>
        )}
    </Row>
  );
}

DataTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
};

DataTable.defaultProps = {
  headers: [],
  data: [],
};
