import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToggleSwitch from '../components/ToggleSwitch';
import ConfirmationModal from '../components/ConfirmationModal';
import { changePasswordAction, updateSettingsAction } from '../actions/userActions';

import './Settings.scss';

const Settings = props => {
  const {
    showEhr, showAncestry, showProductsAndServices, isUpdating, changePassword,
    updateSettings, updateError, passwordUpdated,
  } = props;

  const [showHealthModal, setShowHealthModal] = useState(false);
  const [showAncestryModal, setShowAncestryModal] = useState(false);
  const [showProductsAndServicesModal, setShowProductsAndServicesModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [password1Error, setPassword1Error] = useState('');
  const [password2, setPassword2] = useState('');
  const [password2Error, setPassword2Error] = useState('');

  useEffect(() => {
    setPassword2Error('');
    if (password2 !== password1) {
      setPassword2Error('Passwords do not match');
    }
  }, [password1, password2]);

  useEffect(() => {
    setPassword1Error('');
    if (password1 === '') return;
    if (password1.search(/\d/) === -1) {
      setPassword1Error('Password must contain a number');
    } else if (password1.search(/[a-z]/) === -1) {
      setPassword1Error('Password must contain a lowercase character');
    } else if (password1.search(/[A-Z]/) === -1) {
      setPassword1Error('Password must contain an uppercase character');
    } else if (password1.length < 12) {
      setPassword1Error('Password must be longer than 11 characters');
    }
  }, [password1]);

  const handleForm = () => {
    if (password1Error !== ''
      || password2Error !== ''
      || password1 === ''
      || password2 === '') return;

    changePassword(currentPassword, password1);
  };

  return (
    <div style={{ flex: 1 }}>
      <Container style={{ maxWidth: 600, paddingBottom: 20 }}>
        <div
          style={{
            marginTop: 20,
            marginBottom: 30,
            fontFamily: 'Montserrat',
            fontSize: '30px',
            alignText: 'center',
            width: '100%',
            color: '#707070',
            fontWeight: 600,
          }}>
          Settings
        </div>
        <Container
          style={{
            paddingBottom: 20,
            backgroundColor: 'white',
            borderRadius: 15,
          }}>
          <table
            align="center"
            style={{
              fontFamily: 'Montserrat',
              fontSize: '14px',
              width: '90%',
            }}>
            <tr>
              <td style={{ paddingTop: 20, textAlign: 'left' }}>Modules</td>
              <td
                style={{ maxWidth: 40, paddingTop: 20, textAlign: 'right' }}>
                Hide / Show on Dashboard
              </td>
            </tr>
          </table>
          <div style={{ backgroundColor: '#F0F0F0', borderRadius: 20 }}>
            <table
              align="center"
              style={{
                fontFamily: 'Montserrat',
                fontSize: '14px',
                width: '90%',
              }}>
              <tr style={{ borderBottom: '1px solid #ccc' }}>
                <td style={{ padding: 10, textAlign: 'left' }}>
                  Ancestry data
                </td>
                <td
                  style={{
                    paddingTop: 10,
                    paddingRight: 10,
                    textAlign: 'right',
                  }}>
                  <ToggleSwitch
                    handleToggle={() => setShowAncestryModal(true)}
                    isOn={showAncestry === true}
                    id="ancestry" />
                </td>
              </tr>
              <tr style={{ backgroundColor: '#F0F0F0' }}>
                <td style={{ padding: 10, textAlign: 'left' }}>
                  Health data
                </td>
                <td
                  style={{
                    paddingTop: 10,
                    paddingRight: 10,
                    textAlign: 'right',
                  }}>
                  <ToggleSwitch
                    handleToggle={() => setShowHealthModal(true)}
                    isOn={showEhr === true}
                    id="health"
                  />
                </td>
              </tr>
            </table>
          </div>

          <table
            align="center"
            style={{
              fontFamily: 'Montserrat',
              fontSize: '14px',
              width: '90%',
            }}>
            <tr>
              <td style={{ paddingTop: 20, textAlign: 'left' }}>Preferences</td>
              <td
                style={{ maxWidth: 40, paddingTop: 20, textAlign: 'right' }}>
                Opt In
              </td>
            </tr>
          </table>
          <div style={{ backgroundColor: '#F0F0F0', borderRadius: 20 }}>
            <table
              align="center"
              style={{
                fontFamily: 'Montserrat',
                fontSize: '14px',
                width: '90%',
              }}>
              <tr>
                <td style={{ padding: 10, textAlign: 'left' }}>
                  New products and services
                </td>
                <td
                  style={{
                    paddingTop: 10,
                    paddingRight: 10,
                    textAlign: 'right',
                  }}>
                  <ToggleSwitch
                    handleToggle={() => setShowProductsAndServicesModal(true)}
                    isOn={showProductsAndServices === true}
                    id="products" />
                </td>
              </tr>
            </table>
          </div>

          <table
            align="center"
            style={{
              fontFamily: 'Montserrat',
              fontSize: '14px',
              width: '90%',
            }}>
            <tr>
              <td style={{ paddingTop: 20, textAlign: 'left' }}>Password</td>
              <td style={{ paddingTop: 20, textAlign: 'right' }} />
            </tr>
          </table>
          <div style={{ backgroundColor: '#F0F0F0', borderRadius: 20 }}>
            { passwordUpdated ? <p className="success">Your password has been changed</p> : '' }
            <table
              align="center"
              style={{
                fontFamily: 'Montserrat',
                fontSize: '14px',
                width: '90%',
                paddingBottom: 20,
              }}>
              <tr style={{ borderBottom: '1px solid #ccc' }}>
                <td style={{ padding: 10, textAlign: 'left' }}>
                  Current Password
                </td>
                <td style={{ paddingRight: 10, textAlign: 'right' }}>
                  <input
                    style={{
                      margin: 10,
                      border: '1px solid #AAAAAA',
                      borderRadius: 20,
                    }}
                    id="password"
                    className={`form-control ${
                      updateError ? 'is-invalid' : ''
                    }`}
                    value={currentPassword}
                    autoComplete="password"
                    onChange={event => setCurrentPassword(event.target.value)}
                    type="password"
                    placeholder="Old Password"
                  />
                  <div className="invalid-feedback">
                    {updateError}
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: '#F0F0F0' }}>
                <td style={{ padding: 10, textAlign: 'left' }}>
                  New Password
                </td>
                <td style={{ paddingRight: 10, textAlign: 'right' }}>
                  <input
                    style={{
                      margin: 10,
                      border: '1px solid #AAAAAA',
                      borderRadius: 20,
                    }}
                    id="password1"
                    className={`form-control ${
                      password1Error ? 'is-invalid' : ''
                    }`}
                    value={password1}
                    autoComplete="new-password"
                    onChange={event => setPassword1(event.target.value)}
                    type="password"
                    placeholder="New Password"
                  />
                  <div className="invalid-feedback">{password1Error}</div>
                </td>
              </tr>
              <tr style={{ backgroundColor: '#F0F0F0' }}>
                <td style={{ padding: 10, textAlign: 'left' }}>
                  Verify Password
                </td>
                <td style={{ paddingRight: 10, textAlign: 'right' }}>
                  <input
                    style={{
                      margin: 10,
                      border: '1px solid #AAAAAA',
                      borderRadius: 20,
                    }}
                    id="password2"
                    className={`form-control ${
                      password2Error ? 'is-invalid' : ''
                    }`}
                    value={password2}
                    autoComplete="new-password"
                    onChange={event => setPassword2(event.target.value)}
                    type="password"
                    placeholder="Verify Password" />
                  <div className="invalid-feedback">{password2Error}</div>
                </td>
              </tr>
              <tr>
                <td />
                <td style={{ textAlign: 'right' }}>
                  <Button
                    style={{
                      borderColor: '#FCA60A',
                      background: '#FCA60A',
                      borderRadius: 10,
                      marginBottom: 10,
                    }}
                    disabled={isUpdating}
                    onClick={!isUpdating ? () => handleForm() : null}
                    className="login-btn"
                    variant="primary"
                    type="submit">
                    {isUpdating ? 'Loading...' : 'Change Password'}
                  </Button>
                </td>
              </tr>
            </table>
          </div>
        </Container>
      </Container>

      <ConfirmationModal
        title={`Switching ${showEhr ? ' Off ' : ' On '} your Health data`}
        isOpen={showHealthModal}
        pending={isUpdating}
        hide={() => setShowHealthModal(false)}
        confirm={() => updateSettings('health', showEhr)}>
        Please confirm that you wish to opt
        {showEhr ? ' out of ' : ' in to '}
        seeing this data. You can change this setting at any time.
      </ConfirmationModal>

      <ConfirmationModal
        title={`Switching ${showAncestry ? ' Off ' : ' On '} your Ancestry data`}
        isOpen={showAncestryModal}
        pending={isUpdating}
        hide={() => setShowAncestryModal(false)}
        confirm={() => updateSettings('ancestry', showAncestry)}>
        Please confirm that you wish to opt
        {showAncestry ? ' out of ' : ' in to '}
        seeing this data. You can change this setting at any time.
      </ConfirmationModal>

      <ConfirmationModal
        title={`Opt ${showProductsAndServices ? ' out of ' : ' in to '} new products and services?`}
        isOpen={showProductsAndServicesModal}
        pending={isUpdating}
        hide={() => setShowProductsAndServicesModal(false)}
        confirm={() => updateSettings('productsAndServices', showProductsAndServices)}>
        Please confirm that you wish to opt
        {showProductsAndServices ? ' out of ' : ' in to '}
        being informed of new products and services. You can change this setting at any time.
      </ConfirmationModal>
    </div>
  );
};

Settings.propTypes = {
  showEhr: PropTypes.bool.isRequired,
  showAncestry: PropTypes.bool.isRequired,
  showProductsAndServices: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  changePassword: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
  updateError: PropTypes.string.isRequired,
  passwordUpdated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const {
    settings: {
      showAncestry, showEhr, showProductsAndServices, isUpdating, updateError, passwordUpdated,
    },
  } = state.user;
  return {
    showAncestry, showEhr, showProductsAndServices, isUpdating, updateError, passwordUpdated,
  };
};

const mapDispatchToProps = {
  changePassword: changePasswordAction,
  updateSettings: updateSettingsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
