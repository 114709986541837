/* eslint-disable */

import React from "react";

export const version = "1.0.0"

export default function HealthMessage() {
    return (
        <div style={{textAlign:"left"}}>
        <p >
            Dear participant,
        </p>
        <p>
            Due to the current public heath situation with Covid-19, enrolment is on hold and the frequency of some of the study activities is reduced on the Discover Me Study. As a result, there are no recent updates to your health record on your participant portal. You will be notified when your health records are next updated.
        </p>
        <p>
            Kind regards,
            <br />
            The Discover Me study team
        </p>
        </div>
    )
}