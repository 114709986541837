import Lambda from 'aws-sdk/clients/lambda';
import { Auth } from 'aws-amplify';
import * as types from './resampleTypes';

export const submittingAddressForm = () => ({ type: types.ADDRESS_FORM_SUBMITTING });
export const submittedAddressForm = () => ({ type: types.ADDRESS_FORM_SUCCESS });
export const addressFormFailed = error => ({ type: types.ADDRESS_FORM_FAILED, error });

export const submitAddressForm = (postcode, fullAddress) => async dispatch => {
  dispatch(submittingAddressForm());

  let credentials;
  let lambdaCredentials;

  try {
    credentials = await Auth.currentCredentials();
    lambdaCredentials = Auth.essentialCredentials(credentials);
  } catch (exception) {
    // failed
    return;
  }

  const lambda = new Lambda({
    credentials: lambdaCredentials,
    region: 'eu-west-2',
  });

  const session = await Auth.currentSession();

  lambda.invoke({
    FunctionName: 'portal-address-checker',
    Payload: JSON.stringify({
      idToken: session.idToken.jwtToken,
      postcode,
      fullAddress,
    }),
  }, (err, data) => { // eslint-disable-line
    // TODO data will container {postcodeMatch: true / false} currently we don't use it
    if (err) {
      dispatch(addressFormFailed('An error occurred, please refresh the page and try again'));
      return;
    }
    dispatch(submittedAddressForm());
  });
};

export const optingOut = () => ({ type: types.OPT_OUT_STARTED });
export const optedOutSuccess = () => ({ type: types.OPT_OUT_SUCCESS });
export const optedOutFail = error => ({ type: types.OPT_OUT_FAILED, error });

export const optOut = () => async dispatch => {
  dispatch(optingOut());

  let credentials;
  let lambdaCredentials;

  try {
    credentials = await Auth.currentCredentials();
    lambdaCredentials = Auth.essentialCredentials(credentials);
  } catch (exception) {
    dispatch(optedOutFail('A user problem has occurred. Please try again later'));
    return;
  }

  const lambda = new Lambda({
    credentials: lambdaCredentials,
    region: 'eu-west-2',
  });

  const session = await Auth.currentSession();

  lambda.invoke({
    FunctionName: 'portal-address-checker',
    Payload: JSON.stringify({
      idToken: session.idToken.jwtToken,
    }),
  }, (err, data) => { // eslint-disable-line
    if (err) {
      dispatch(optedOutFail('An error occurred, please refresh the page and try again'));
      return;
    }
    dispatch(optedOutSuccess());
  });
};
