export default {
  "userPoolId": "eu-west-2_ABvR2LozO",
  "aws_cognito_region": "eu-west-2",
  "userPoolWebClientId": "4r5buo555iablhdpb7rea5vui9",
  "identityPoolId": "eu-west-2:3852b6ee-1602-4008-b3ae-27ad5483aea9",
  "region": "eu-west-2",
  "Analytics": {
    "AWSPinpoint": {
      "appId": 'bc0b686565854b2bb2adc0c5ed4e33c0',
      "region": 'eu-west-1'
    },
  },
};

