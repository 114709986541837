import React from 'react';
import { connect } from 'react-redux';

interface Props {
    showTPP: boolean,
}

const TPPMessage = ({ showTPP }: Props) => {
    if (!showTPP) {
        return <></>
    }
    return <div>
        Due to a change in our processes, we regret that we will no longer be able to provide you with updates to your health information via the participant portal.
        {' '}
        You will still be able to view existing health information on your portal, but this will not be updated going forward. You can continue to view your genetic
        {' '}
        ancestry information if this is available, and you have chosen to receive this information. We apologise for any inconvenience caused. Please do not hesitate
        {' '}
        to contact the Discover Me Research Team should you have any queries.
    </div>
}

const mapStateToProps = (state: any) => {

    const { settings: { showTPP } } = state.user;
    return { showTPP };
};


export default connect(mapStateToProps)(TPPMessage);
