import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Modal, Spinner,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import BirthDateSelection from '../components/BirthDateSelection';
import DiscoverMePanel from '../components/DiscoverMePanel';

import { forgotUsernameAction, resetRegisterStateAction } from '../actions/registerActions';

import './ForgotUsername.scss';

const ForgotUsername = props => {
  const [userEmail, setUserEmail] = useState('');
  const [userMobile, setUserMobile] = useState('');
  const [day, setDay] = useState(1);
  const [month, setMonth] = useState('Jan');
  const [year, setYear] = useState(2004);
  const [modalShow, setModalShow] = useState(false);
  const [toHome, setToHome] = useState(false);

  const {
    forgotUsername, isLoading, error, resetRegisterState,
  } = props;

  useEffect(() => {
    resetRegisterState();
  }, [resetRegisterState]);

  const handleDateOfBirthChange = event => {
    const { id: dobId, value } = event.target;
    switch (dobId) {
      case 'day':
        setDay(value);
        break;
      case 'month':
        setMonth(value);
        break;
      default:
        setYear(value);
        break;
    }
  };

  const spinner = isLoading ? (
    <Spinner key="loading-spinner" animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  ) : null;

  const submitForm = event => {
    event.preventDefault();
    event.stopPropagation();
    forgotUsername(day, month, year, userEmail, userMobile);
    setModalShow(true);
  };

  if (toHome === true) return <Redirect to="/" />;

  return (
    <DiscoverMePanel>
      <h2>WELCOME TO THE</h2>
      <h1>PARTICIPANT PORTAL</h1>
      <div>
        <p
          style={{
            fontFamily: 'Montserrat',
            fontSize: '14px',
            textAlign: 'center',
            width: '100%',
          }}>
          To get your username please enter your
          <strong> date of birth </strong>
          and either your
          <strong> email </strong>
          or
          <strong> mobile phone number </strong>
          that you registered with
        </p>
        <Form
          onSubmit={submitForm}
          style={{
            justifyContent: 'center',
            textAlign: 'left',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 300,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <BirthDateSelection
            error={error}
            day={day}
            month={month}
            year={year}
            change={handleDateOfBirthChange} />
          <Form.Group controlId="userEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              className={`form-control ${error ? 'is-invalid' : ''}`}
              value={userEmail}
              onChange={event => setUserEmail(event.target.value)}
              type="userEmail"
              placeholder="Enter email"
              autoCapitalize="off" />
            <Form.Text className="text-muted" />
          </Form.Group>
          <Form.Group controlId="userMobile">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              className={`form-control ${error ? 'is-invalid' : ''}`}
              value={userMobile}
              onChange={event => setUserMobile(event.target.value)}
              type="userMobile"
              placeholder="Enter mobile number" />
          </Form.Group>
          <Button
            disabled={isLoading}
            variant="primary"
            type="submit">
            {isLoading ? 'Loading...' : 'Send Username Reminder'}
          </Button>
        </Form>
        <div style={{ marginTop: 10 }}>
          Need help? Contact the Discover Me Research Team at
          <a href="+442038668941"> 02038668941 </a>
          or
          <a href="mailto:discovermeuk@omnigenbiodata.co.uk"> discovermeuk@omnigenbiodata.co.uk </a>
        </div>
      </div>
      <Modal show={modalShow} onHide={() => setToHome(true)}>
        <Modal.Header closeButton>
          <Modal.Title />
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
          { isLoading
            ? spinner
            : 'If the account details were correct, an email or SMS has been sent to you to remind you of your username' }
        </Modal.Body>
        <Modal.Footer>
          { isLoading
            ? ''
            : (
              <Button variant="secondary" onClick={() => setToHome(true)}>
                Close
              </Button>
            )}
        </Modal.Footer>
      </Modal>
    </DiscoverMePanel>
  );
};

ForgotUsername.propTypes = {
  forgotUsername: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  resetRegisterState: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const {
    register: {
      isLoading, error,
    },
  } = state;
  return { isLoading, error };
};

const mapDispatchToProps = {
  forgotUsername: forgotUsernameAction,
  resetRegisterState: resetRegisterStateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotUsername);
