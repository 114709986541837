import React, { useState } from 'react';
import {
  Row, Col, Button,
  // Form, Spinner,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import AddressForm from '../components/AddressForm';

import { optOut as optOutAction } from '../actions/resampleActions';

import styles from './Resample.module.css';

const Resample = () => {
  // const [resampleOptIn, setResampleOptIn] = useState(undefined);
  // const [hasAnsweredOptIn, setHasAnsweredOptIn] = useState(false);
  const [toHome, setToHome] = useState(false);
  // const [error, setError] = useState(false);

  // const { optingOut, optOut, resampleError } = props;

  // const submitResample = event => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   if (resampleOptIn === undefined) {
  //     setError(true);
  //     return;
  //   }
  //   if (!resampleOptIn) {
  //     optOut();
  //   }
  //   setHasAnsweredOptIn(true);
  // };

  // const errorMessage = error ? (
  //   <Row>
  //     <Col>
  //       <Form.Label className="text-danger">
  //         Please select an option
  //       </Form.Label>
  //     </Col>
  //   </Row>
  // ) : undefined;

  const resampleClosed = (
    <Row>
      <Col>
        <p>
          The deadline to let us know that you would like to provide additional samples has now passed.
        </p>
        <p>
          If you do not want to provide additional samples there is nothing more you need to do. However, in this case we will not be able to provide you with your ancestry results.
        </p>
        <p>
          If you do want to provide additional samples, please contact us at discovermeuk@omnigenbiodata.co.uk. We will record all interest and hope to contact you in the future if another option should become available.
        </p>
        <Button type="submit" className={styles.button} onClick={() => setToHome(true)}>Homepage</Button>
      </Col>
    </Row>
  );

  // const cannotResample = (
  //   <Row>
  //     <Col>
  //       <p>Unfortunately you cannot opt to resample at this time</p>
  //     </Col>
  //   </Row>
  // );

  // const optInForm = (
  //   <>
  //     <Row>
  //       <Col>
  //         <p>
  //           Unfortunately, there has been an issue with processing your mouth swab sample. We would therefore like to invite you to provide additional samples.
  //         </p>
  //         <p>
  //           Providing additional samples is entirely optional; you do not have to provide these if you do not wish to. However, please note that we will not be able to give you your ancestry results in this case.
  //         </p>
  //         <p>
  //           Please confirm if you would like to provide additional samples using our sample postal kit:
  //         </p>
  //       </Col>
  //     </Row>
  //     <Form onSubmit={submitResample}>
  //       { errorMessage }
  //       <Row>
  //         <Col>
  //           <Form.Group className={styles['checkbox-container']} onClick={() => setResampleOptIn(true)}>
  //             <p>Yes – I would like to provide additional samples</p>
  //             <div className={`${styles.checkbox} ${resampleOptIn ? styles.checked : ''}`}>
  //               <span className="material-icons">done</span>
  //             </div>
  //           </Form.Group>
  //         </Col>
  //         <Col>
  //           <Form.Group className={styles['checkbox-container']} onClick={() => setResampleOptIn(false)}>
  //             <p>No – I do NOT want to provide additional samples</p>
  //             <div className={`${styles.checkbox} ${resampleOptIn === false ? styles.checked : ''}`}>
  //               <span className="material-icons">done</span>
  //             </div>
  //           </Form.Group>
  //         </Col>
  //       </Row>
  //       <Row />
  //       <Row>
  //         <Col>
  //           <Button type="submit" className={styles.button}>Confirm</Button>
  //         </Col>
  //       </Row>
  //     </Form>
  //   </>
  // );

  // const noConfirmation = optingOut ? (
  //   <>
  //     <Spinner
  //       as="span"
  //       animation="border"
  //       size="sm"
  //       role="status"
  //       aria-hidden="true"
  //     />
  //     <span className="sr-only">Loading...</span>
  //   </>
  // ) : (
  //   <>
  //     <Row>
  //       <Col>
  //         { resampleError ? (
  //           <p>{resampleError}</p>
  //         ) : (
  //           <>
  //             <p>
  //               Thank you for confirming that you do not want to provide additional samples for the Discover Me study. We will send you confirmation of this via email or text message.
  //             </p>
  //             <p>
  //               Please note that we will not be able to provide you with your ancestry results, if you have chosen to receive them.
  //             </p>
  //             <p>
  //               If you change your mind and would like to provide additional samples, you can contact us at any time.
  //             </p>
  //           </>
  //         )}
  //       </Col>
  //     </Row>
  //     <Row>
  //       <Col>
  //         <Button type="submit" className={styles.button} onClick={() => setToHome(true)}>Homepage</Button>
  //       </Col>
  //     </Row>
  //   </>
  // );

  if (toHome) return <Redirect to="/" />;

  // let display = optInForm;
  // if (hasAnsweredOptIn && !resampleOptIn) display = noConfirmation;
  // if (hasAnsweredOptIn && resampleOptIn) display = <AddressForm back={() => setHasAnsweredOptIn(false)} />;
  // if (!canResample) display = cannotResample;

  return (
    <div className="content-wrapper">
      <Row>
        <Col>
          <h2 className={`page-title ${styles['page-title']}`}>Repeat Sample Confirmation</h2>
        </Col>
      </Row>
      <div className={styles.content}>
        {resampleClosed}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  optingOut: state.resample.optingOut,
  resampleError: state.resample.resampleError,
});

const mapDispatchToProps = {
  optOut: optOutAction,
};

// Resample.propTypes = {
// optingOut: PropTypes.bool.isRequired,
// optOut: PropTypes.func.isRequired,
// resampleError: PropTypes.string.isRequired,
// };

export default connect(mapStateToProps, mapDispatchToProps)(Resample);
