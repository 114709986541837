/* eslint-disable max-len */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import './FAQ.scss';

const Help = props => {
  const { helpSection } = props;

  const registrationRef = useRef(null);
  const loginRef = useRef(null);

  useEffect(() => {
    switch (helpSection) {
      case 'registration':
        if (registrationRef.current) {
          registrationRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'login':
        if (loginRef.current) {
          loginRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      default:
        break;
    }
  }, [helpSection]);

  return (
    <div className="FAQContainer">
      <p>
        If you have questions about the Discover Me study, please visit the study website&nbsp;
        <a href="https://discovermestudy.org/">https://discovermestudy.org/</a>
      </p>
      <p className="warning">
        If you have specific questions about any medical matter, you should
        consult your doctor or other professional healthcare provider without
        delay. If you think you are experiencing any medical condition you
        should seek immediate medical attention from a doctor or other
        professional healthcare provider. You should never delay seeking medical
        advice, disregard medical advice, or discontinue medical treatment
        because of information on this Platform.
      </p>
      <ol>
        <li>
          Registering for the Discover Me portal
          <ol>
            <li>
              Why do I need to register for the Discover Me portal?
              <p>
                The Discover Me participant portal is a secure website where you can view some of your health and genetic ancestry information if you have participated in the Discover Me study. The registration process for the portal is separate to that which took place at your GP practice. Registration for the portal involves creating and verifying an account so that you can log in any time to view your health and ancestry information. You will need to follow the registration link in the email or text message sent to you to register for the portal.
              </p>
            </li>
            <li>
              How do I register for the Discover Me participant portal?
              <p>
                To register for the Discover Me portal, you will need to follow the link that was sent to you either via email or text message to your mobile phone. This will take you to the registration page, where you will need to enter your date of birth and set a username and password. The registration process for the portal is separate to that which took place at your GP practice so you will need to set a username and password before you can log in. You will also be asked to read and accept some terms and conditions.
              </p>
              <p>To register:</p>
              <ul>
                <li>Please enter your date of birth and click &quot;Next&quot;</li>
                <li>Please choose a username that you can remember – you will need to use this to log in every time</li>
                <li>Set a password - you will be required to set a password that contains an uppercase letter, contains a number and is longer than 11 characters.</li>
                <li>Click &quot;Register&quot;</li>
              </ul>
              <p>
                For more information on setting a username and password see &quot;How do I set a username and password?&quot;
              </p>
            </li>
            <li>
              Why do I need to enter my date of birth when I set a username and password?
              <p>
                Your date of birth, alongside the registration link we sent you, provides additional security steps, to help ensure that only you can log into your portal.
              </p>
            </li>
            <li>
              How do I set a username and password?
              <p>
                When you first register for the Discover Me portal you will be asked to set a username and password. You can set any username you like as long as this has not already been used by someone else. You will need to use this username to log in to the participant portal. You will be required to set a password that contains an uppercase letter, contains a number and is longer than 11 characters.
              </p>
            </li>
            <li>
              Why do I need to set a complex password?
              <p>
                We take privacy of your information very seriously. Setting a password that contains an uppercase letter, contains a number and is longer than 11 characters helps to make your password more effective at keeping your information secure.
              </p>
            </li>
            <li>
              The registration link has expired, what do I need to do?
              <p>
                After a period of time, the registration link sent to you expires and you will need to request a new link in order to register for the portal. If this happens, you will be directed to a webpage with a button to request a new link. Please request a new link to be sent to you. Please follow this new link to register for the portal.
              </p>
            </li>
          </ol>
        </li>
        <li>
          Logging in to the Discover Me portal
          <ol>
            <li>
              I have registered for the Discover Me portal, how do I log in?
              <p>
                If you have previously registered for the Discover Me portal, by entering your date of birth, setting a username and password and have previously logged in to the portal, you can log in by visiting&nbsp;
                <a href="https://portal.discovermestudy.org/">https://portal.discovermestudy.org/</a>
              </p>
            </li>
            <li>
              My username and/or password are not recognised, why is this and what should I do?
              <p>It is possible that you have forgotten your username or password, or you may have made an error when you originally typed your username or password during registration. We recommend that you request a username reminder or reset your password by clicking “Forgot username?” or “Forgot password?”. </p>
              <p>Please note that your username and password are set during portal registration, which is separate to enrolment into the Discover Me study, which took place at your GP practice.</p>
            </li>
            <li>
              What should I do if I have forgotten my username?
              <p>If you have already registered for the Discover Me portal, please click on the “Forgot username?” link on the log in page. This should direct you to a page where you will need to enter your date of birth and email OR mobile phone number that you provided when you enrolled into the Discover Me study at your GP practice. You will be sent an email or text message which will provide a reminder of your username. </p>
              <p>Registration for the portal is different from the process that took place at your GP practice. If you have not already registered for the portal, please follow the registration link in the email or text message sent to you to set a username and password.</p>
            </li>
            <li>
              I clicked “Forgot Username?” but I have not received a username reminder
              <p>If you have forgotten your username, you can get a reminder by clicking on the “Forgot username?” link on the log in page and entering your date of birth and email of mobile phone number. If you do not receive this email of text message, this could be for the following reasons:</p>
              <ul>
                <li>You may have typed your email or mobile number incorrectly – if we usually communicate with you via email, please enter an email address. If we usually communicate with you via text message, please enter your mobile number.</li>
                <li>You have not already registered for the Discover Me Portal – this is separate to enrolment into the Discover Me study, which took place at your GP practice. See section 1.2 on how to register.</li>
              </ul>
            </li>
            <li>
              What should I do if I have forgotten my password?
              <p>
                Please click on the “Forgot password?” link on the log in page. This should direct you to a page where you will be asked to enter your username. You will then receive an email or text message with a link to reset your password. Registration for the portal is different from the process that took place at your GP practice. If you have not already registered for the portal, please follow the registration link in the email or text message sent to you to set a username and password.
              </p>
            </li>
            <li>
              I have forgotten my password, but I have not been sent a link to reset my password.
              <p>
                If you have forgotten your password, you can reset your password by clicking the “Forgot password?” link on the log in page. This should direct you to a page where you will be asked to enter your username. If you have forgotten your username, please see&nbsp;
                <u>section 2.3</u>
                . If you have not received an email of text message with a link to reset your password, this could be for the following reasons:
              </p>
              <ul>
                <li>
                  You may have typed your username incorrectly – you may want to check your username – please see&nbsp;
                  <u>section 2.3</u>
                </li>
                <li>
                  You have not already registered for the Discover Me Portal – this is separate to enrolment into the Discover Me study, which took place at your GP practice. See&nbsp;
                  <u>section 1.2</u>
                  &nbsp;on how to register.
                </li>
              </ul>
            </li>
            <li>
              I received an email to reset my password, but I did not request this, what should I do?
              <p>
                If you received an email with a link to reset your password and you did NOT request this, please contact the Discover Me Research Team immediately at&nbsp;
                <a href="mailto:discovermeuk@omnigenbiodata.co.uk">discovermeuk@omnigenbiodata.co.uk</a>
                &nbsp;or phone:&nbsp;
                <a href="tel:+4420 3866 8941">020 3866 8941</a>
              </p>
            </li>
            <li>
              What happens if I do not choose to accept the Terms and Conditions for the portal?
              <p>
                It is completely your choice whether you wish to accept the Terms and Conditions. However, it is not possible to access the Discover Me portal without accepting the Terms and Conditions.
              </p>
            </li>
            <li>
              What should I do if I have issues registering or logging into the Discover Me portal?
              <p>
                If you experience issues registering for the portal and you cannot find what you are looking for in these FAQs, please contact the Discover Me Research Team:&nbsp;
                <a href="mailto:discovermeuk@omnigenbiodata.co.uk">discovermeuk@omnigenbiodata.co.uk</a>
                &nbsp;or phone:&nbsp;
                <a href="tel:+4420 3866 8941">020 3866 8941</a>
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

/* eslint-enable max-len */

Help.propTypes = {
  helpSection: PropTypes.string,
};

Help.defaultProps = {
  helpSection: '',
};

export default Help;
