import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import PropTypes from 'prop-types';

export default function BirthDateSelection(props) {
  function getDays() {
    const output = [];
    let index;
    for (index = 1; index <= 31; index += 1) {
      output.push(
        <option key={index}>{index.toString().padStart(2, '0')}</option>,
      );
    }
    return output;
  }

  function getMonths() {
    const output = [];
    const months = moment.monthsShort();
    let index;
    for (index = 0; index < months.length; index += 1) {
      output.push(<option key={index}>{months[index]}</option>);
    }
    return output;
  }

  function getYears() {
    const output = [];
    let index;
    for (index = 16; index <= 150; index += 1) {
      output.push(<option key={2020 - index}>{2020 - index}</option>);
    }
    return output;
  }

  const {
    day,
    month,
    year,
    change,
    error,
    disabled,
  } = props;

  return (
    <Form.Group>
      <Row>
        <Col>
          <Form.Group controlId="day">
            <Form.Label>Day</Form.Label>
            <Form.Control
              className={`form-control ${error ? 'is-invalid' : ''}`}
              as="select"
              value={day}
              onChange={change}
              disabled={disabled}>
              {getDays()}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="month">
            <Form.Label>Month</Form.Label>
            <Form.Control
              className={`form-control ${error ? 'is-invalid' : ''}`}
              as="select"
              value={month}
              onChange={change}
              disabled={disabled}>
              {getMonths()}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="year">
            <Form.Label>Year</Form.Label>
            <Form.Control
              className={`form-control ${error ? 'is-invalid' : ''}`}
              as="select"
              value={year}
              onChange={change}
              disabled={disabled}>
              {getYears()}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} className="dob-error">
          <div className={`${error ? 'is-invalid' : ''}`} />
          <div className="invalid-feedback">{error}</div>
        </Col>
      </Row>
    </Form.Group>
  );
}

BirthDateSelection.propTypes = {
  day: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
