import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './Conditions.scss';
import { sortByMomentFirstColumn } from '../../utils/DateUtils';
import DataTable from '../../components/DataTable';
import BackButton from '../../components/BackButton';
import { showFaqsAction } from '../../actions/userActions';

const Conditions = (props) => {
  const { conditions: oldConditions, showFaqs, usingIE } = props;

  const lastYear = moment().year() - 1;
  const cutoffDate = moment().year(lastYear).startOf('month');

  const conditions = oldConditions
    .sort(sortByMomentFirstColumn)
    .reverse()
    .filter((condition) => moment(condition[0]).hour(0).isAfter(cutoffDate))
    .map((condition) => [
      moment.utc(condition[0]).format('Do MMM YYYY'),
      condition[1],
    ]);

  const ieStyle = usingIE ? { height: conditions.length * 60 + 90 } : {};

  return (
    <div className="scrollbar-conditions">
      <BackButton backLink="/health" />
      <Row>
        <Col>
          <h2 className="page-title">Conditions</h2>
        </Col>
      </Row>
      <Row className="page-context">
        <Col>
          <p>
            This lists the medical conditions from the last 12 months that are
            recorded in your GP medical records. This information may not
            include conditions that have been investigated, diagnosed or managed
            elsewhere, such as at work or at a hospital or private clinic.
          </p>
          <p>
            It can take some time for changes made in your GP medical records to
            appear on this website. This means that the information you can see
            today may not be completely up to date if your conditions have
            changed recently.
          </p>

          <p>
            To find out more about the information on this page, please visit
            our
            <Button variant="link" onClick={() => showFaqs('conditions')}>
              Frequently Asked Questions (FAQs).
            </Button>
          </p>
        </Col>
      </Row>
      <Row className="health-data-content" style={ieStyle}>
        <Col>
          {conditions.length > 0 ? (
            <DataTable
              headers={['Date Recorded', 'Condition Description']}
              data={conditions}
              name="conditions"
            />
          ) : (
            <Row className="disabled">
              <Col>There are no conditions in your recent medical record.</Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

Conditions.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  showFaqs: PropTypes.func.isRequired,
  usingIE: PropTypes.bool.isRequired,
};

Conditions.defaultProps = {
  conditions: [],
};

const mapStateToProps = (state) => {
  const {
    usingIE,
    ehr: { conditions },
  } = state.user;
  return { conditions, usingIE };
};

const mapDispatchToProps = {
  showFaqs: showFaqsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Conditions);
