import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import './Prescriptions.scss';
import DataTable from '../../components/DataTable';
import { sortByMomentSecondColumn } from '../../utils/DateUtils';
import BackButton from '../../components/BackButton';
import { showFaqsAction } from '../../actions/userActions';

const Prescriptions = (props) => {
  const { prescriptions: oldPrescriptions, showFaqs, usingIE } = props;

  const lastYear = moment().year() - 1;
  const cutoffDate = moment().year(lastYear).startOf('month');

  const prescriptions = oldPrescriptions
    .sort(sortByMomentSecondColumn)
    .reverse()
    .filter((prescription) => moment(prescription[1]).hour(0).isAfter(cutoffDate))
    .map((prescription) => [
      prescription[0],
      moment.utc(prescription[1]).format('Do MMM YYYY'),
      prescription[3],
    ]);

  const ieStyle = usingIE ? { height: prescriptions.length * 60 + 90 } : {};

  const headers = ['Drug', 'Date issued', 'Directions for use'];
  return (
    <div className="scrollbar-prescriptions">
      <BackButton backLink="/health" />
      <Row>
        <Col>
          <h2 className="page-title">Prescriptions</h2>
        </Col>
      </Row>
      <Row className="page-context">
        <Col>
          <p>
            This lists the prescriptions from the last 12 months that are
            recorded in your GP medical records. This information may not
            include prescriptions you have had elsewhere, such as at work or at
            a hospital or private clinic.
          </p>

          <p>
            Currently we only list new prescriptions issued in the previous 12
            months. As part of future updates to the portal, we will provide
            information on prescriptions issued before this time (including
            repeat prescriptions).
          </p>

          <p>
            It can take some time for changes made in your GP medical records to
            appear on this website. This means that the information you are
            viewing today may not be completely up to date if your prescriptions
            have changed recently.
          </p>

          <p>
            To find out more about the information on this page, please visit
            our
            <Button variant="link" onClick={() => showFaqs('prescriptions')}>
              Frequently Asked Questions (FAQs).
            </Button>
          </p>
        </Col>
      </Row>
      <Row className="health-data-content" style={ieStyle}>
        <Col>
          {prescriptions.length > 0 ? (
            <DataTable
              headers={headers}
              data={prescriptions}
              name="prescriptions"
            />
          ) : (
            <Row className="disabled">
              There are no prescriptions in your recent medical record
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

Prescriptions.propTypes = {
  prescriptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  showFaqs: PropTypes.func.isRequired,
  usingIE: PropTypes.bool.isRequired,
};

Prescriptions.defaultProps = {
  prescriptions: [],
};

const mapStateToProps = (state) => {
  const {
    ehr: { prescriptions },
    usingIE,
  } = state.user;
  return { prescriptions, usingIE };
};

const mapDispatchToProps = {
  showFaqs: showFaqsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Prescriptions);
