import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

export default function GeneralModal(props) {
  const {
    hide, title, msg, pending,
  } = props;

  Modal.setAppElement('#root');

  return (
    <Modal className="settings-modal" {...props}>
      <div className="modal-heading">
        <h1>
          {` ${title} `}
        </h1>
      </div>
      <div className="modal-body">
        <p>
          {` ${msg} `}
        </p>
      </div>
      <div className="modal-footer">
        <Button variant="secondary" onClick={hide} disabled={pending}>Close</Button>
      </div>

    </Modal>
  );
}

GeneralModal.propTypes = {
  msg: PropTypes.string.isRequired,
  hide: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
