/* eslint-disable max-len */
import React from 'react';
import './PrivacyPolicy.scss';

export default function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicyContainer">
      <p>
        This webpage sets out when and how we use your personal information that
        you or others provide to us.
      </p>
      <h1>1. WHO WE ARE</h1>
      <p>
        We are Omnigen Biodata Ltd, a private limited company (registered number
        10565653).
      </p>
      <p align="left">
        Our registered address is Merlin Place, Milton Road, Cambridge, CB4 0DP.
      </p>
      <p>
        We have appointed a data protection officer who is responsible for
        overseeing questions in relation to this privacy policy. If you have any
        questions about this privacy policy, including any requests to exercise
        your legal rights, please contact our data protection officer (Taylor
        Vinters LLP) by email at
        <a href="mailto:dpo@omnigenbiodata.co.uk"> dpo@omnigenbiodata.co.uk</a>
        .
      </p>
      <h1>2. HOW DO YOU USE MY DATA?</h1>

      <ul>
        <li>
          <h2>
            <strong>
              When you phone us or interact with our website or our Platform
              (the study participant portal)
            </strong>
          </h2>
        </li>
      </ul>
      <p>
        When you phone us or contact us by email with general queries, we may
        also handle your personal information (your name, contact details and
        the other details you provide to us) in order to provide the customer
        services you have asked us to. This could be when you ask us to provide
        more information about what we do, the Platform which we have and use in
        the course of providing our services, or the studies which we are
        involved in, or to explain how our research is going or how we operate.
      </p>
      <p>
        We rely on your consent to handle your personal information in this way.
        If you do not provide us with the personal information we request from
        you for customer services purposes, we may not be able to fully answer
        your queries.
      </p>
      <ul>
        <li>
          <h2>
            <strong>When you have expressed an interest in our services</strong>
          </h2>
        </li>
      </ul>
      <p>
        When we send you marketing emails because you have opted-in to receive
        them, we rely on your consent to contact you for marketing purposes.
      </p>
      <p>
        Every email we send to you for marketing purposes will also contain
        instructions on how to unsubscribe from receiving them.
      </p>
      <p>
        You are not under any obligation to provide us with your personal data
        for marketing purposes.
      </p>
      <p>
        You can tell us that you do not want your personal information to be
        processed in this way at any time by contacting us at
        <a href="mailto:dpo@omnigenbiodata.co.uk"> dpo@omnigenbiodata.co.uk </a>
        or, where relevant, by following the unsubscribe link shown in every
        marketing communication you receive from us.
      </p>
      <ul>
        <li>
          <h2>
            <strong>To make our website better</strong>
          </h2>
        </li>
      </ul>
      <p>
        We will also use your personal information to provide you with a more
        effective user experience (such as by displaying content we think you
        will be interested in).
      </p>
      <p>
        Our use of your information in this way means that your experience of
        our website will be more tailored to you, and that the content you see
        on our site may differ from someone accessing the same site with a
        different history or browsing habits.
      </p>
      <p>
        We will also use your personal information for the purposes of making
        our site more secure, and to administer our site and for internal
        operations, including troubleshooting, data analysis, testing, research,
        statistical and survey purposes.
      </p>
      <p>
        We process your data for this reason because we have a legitimate
        interest to provide you with the best experience we can, and to ensure
        that our site is kept secure.
      </p>
      <p>
        You can prevent us from using your personal information in this way by
        using the &apos;do not track&apos; functionality in your internet
        browser. If you enable the &apos;do not track&apos; functionality, our
        site may be less tailored to your needs and preferences.
      </p>
      <ul>
        <li>
          <h2>
            <strong>If our business is sold</strong>
          </h2>
        </li>
      </ul>
      <p>We will transfer your personal information to a third party:</p>
      <ul>
        <ul>
          <li>
            <p>
              If we sell or buy any business or assets, we will provide your
              personal information to the buyer (but only to the extent we need
              to, and always in accordance with data protection legislation); or
            </p>
          </li>
          <li>
            <p>
              If Omnigen Biodata Ltd or the majority of its assets are acquired
              by somebody else, in which case the personal information held by
              Omnigen Biodata Ltd will be transferred to the buyer.
            </p>
          </li>
        </ul>
      </ul>
      <p>
        We process your personal information for this purpose because we have a
        legitimate interest to ensure our business can be continued by the
        buyer. If you object to our use of your personal information in this
        way, the buyer of our business may not be able to provide services to
        you.
      </p>
      <p>
        In some circumstances we may also need to share your personal
        information if we are under a duty to disclose or share it to comply
        with a legal obligation.
      </p>
      <h1>3. WHAT ABOUT TECHNICAL INFORMATION AND ANALYTICS?</h1>
      <ul>
        <li>
          <h2>
            <strong>Information we collect about you</strong>
          </h2>
        </li>
      </ul>
      <p>
        When you visit our website, we will automatically collect the following
        information:
      </p>
      <ul>
        <ul>
          <li>
            <p>
              Technical information, including the Internet Protocol (IP)
              address used to connect your computer to the internet, your login
              information, browser type and version, time zone setting, browser
              plug-in types and versions, screen resolution, operating system
              and platform; and
            </p>
          </li>
          <li>
            <p>
              Information about your visit, including the full Uniform Resource
              Locators (URL), clickstream to, through and from our site
              (including date and time), page response times, download errors,
              length of visits to certain pages, page interaction information
              (such as scrolling, clicks, and mouse-overs) and methods used to
              browse away from the page.
            </p>
          </li>
        </ul>
      </ul>
      <ul>
        <li>
          <h2>
            <strong>Information we receive from other sources</strong>
          </h2>
        </li>
      </ul>
      <p>
        We are also working closely with third party networks, analytics
        providers, hosting providers and search information providers from whom
        we may also receive general aggregated anonymous information about you.
      </p>
      <p>
        We will combine the information you provide to us with information we
        collect about you.
      </p>
      <h1>4. WHERE ARE MY DATA STORED?</h1>
      <p>
        We are based within the UK, and the processing
        of your personal information will not involve a transfer of data outside
        of the UK.
      </p>
      <p>
        If we have to transfer your personal information outside of the UK, we
        ensure it is protected by only transferring your data to a country that
        provides an equivalent level of protection to your data as if it never
        left the UK.
      </p>
      <p>
        You can contact us at
        {' '}
        <a href="mailto:dpo@omnigenbiodata.co.uk">dpo@omnigenbiodata.co.uk</a>
        {' '}
        for the details as to how we protect specific transfer of your data.
      </p>
      <p>
        All information you provide to us is stored on our secure servers or
        those of our third party data storage providers.
      </p>
      <h1>5. HOW LONG DO WE RETAIN YOUR DATA FOR?</h1>
      <p>
        In line with Omnigen&apos;s Records Retention Policy, we will only
        retain your personal information for as long as necessary to fulfil the
        purposes we collected it for, including for the purposes of satisfying
        any accounting or legal requirements. If you are a study participant,
        please also refer to the privacy notice for study participants
        {' '}
        <a
          href="https://discovermestudy.org/privacy"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://discovermestudy.org/privacy
        </a>
        {' '}
        for more information.
      </p>
      <p>
        To determine the appropriate retention period for the personal
        information we hold, we consider the amount, nature and sensitivity of
        the personal information, the risk of harm from unauthorised use or
        disclosure of your personal information, the reasons why we handle your
        personal information and whether we can achieve those purposes through
        other means, and the applicable legal requirements.
      </p>
      <h1>6. WHAT ARE MY RIGHTS UNDER DATA PROTECTION LAWS?</h1>
      <ul>
        <li>
          <strong>Request access </strong>
          to your data (commonly known as a &quot;subject access request&quot;).
          This enables you to receive a copy of the data we hold about you and
          to check that we are lawfully processing it.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Request correction </strong>
          of the data that we hold about you. This enables you to have any
          incomplete or inaccurate information we hold about you corrected.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Request erasure </strong>
          of your data. This enables you to ask us to delete or remove data
          where there is no good reason for us continuing to process it. You
          also have the right to ask us to delete or remove your data where you
          have exercised your right to object to processing (see below).
        </li>
      </ul>
      <ul>
        <li>
          <strong>Object to processing </strong>
          of your data where we are relying on a legitimate interest (or those
          of a third party) and there is something about your particular
          situation which makes you want to object to processing on this ground.
          You also have the right to object where we are processing your data
          for direct marketing purposes, research or statistical purposes.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Request the restriction </strong>
          of processing of your data. This enables you to ask us to suspend the
          processing of data about you, for example if you want us to establish
          its accuracy or the reason for processing it.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Request the transfer </strong>
          of your data to another party.
        </li>
      </ul>
      <p>
        Privacy law is often complicated, and whether these rights are available
        to you sometimes depends on the types of data we are handling, as well
        as why we are handling them. If you would like to exercise any of these
        rights, please contact us using the details at the bottom of this
        policy. You always have the right to lodge a complaint with us or the
        Information Commissioner&apos;s Office, the supervisory authority for
        data protection issues in England and Wales.
      </p>
      <h1>7. WHAT ABOUT WEBSITES WE LINK TO?</h1>
      <p>
        Our site may, from time to time, contain links to and from the websites
        of our partner networks and affiliates.
      </p>
      <p>
        Our site connects you to different websites. If you follow a link to any
        of these websites or use our services, please note that you have left
        our site and these websites have their own privacy policies.
      </p>
      <p>
        We do not accept any responsibility or liability for these policies or
        websites. Please check their policies before you submit any personal
        information to these websites.
      </p>
      <h1>8. WHEN WILL YOU CHANGE YOUR PRIVACY POLICY?</h1>
      <p>
        Any changes we make to our privacy policy in the future will be posted
        on this page and, where appropriate, notified to you by e-mail or post.
      </p>
      <p>
        Please check back frequently to see any updates or changes to our
        privacy policy.
      </p>
      <h1>9. HOW DO I CONTACT YOU WITH FEEDBACK?</h1>
      <p>
        Questions, comments and requests regarding this privacy policy are
        welcomed and should be addressed to
        <a href="mailto:dpo@omnigenbiodata.co.uk"> dpo@omnigenbiodata.co.uk </a>
        .
      </p>
      <p>This privacy policy was last updated in May 2021.</p>
    </div>
  );
}
/* eslint-enable max-len */
