import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import './BackButton.scss';

export default function BackButton(props) {
  const { backLink } = props;
  return (
    <Row>
      <Col>
        <div className="back-btn">
          <Link to={backLink}>
            <i className="material-icons flipped">play_circle_outline</i>
            <h4>Back</h4>
          </Link>
        </div>
      </Col>
    </Row>
  );
}

BackButton.propTypes = {
  backLink: PropTypes.string,
};

BackButton.defaultProps = {
  backLink: '',
};
