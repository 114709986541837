import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function logic(isAuthenticated, isLoading, children, props) {
  if (isAuthenticated && !isLoading) {
    return children;
  }
  if (isLoading) {
    return <div className="loader" />;
  }
  return (
    <Redirect
      to={`/login?redirect=${props.location.pathname}${props.location.search}`} // eslint-disable-line
    />
  );
}

const AuthenticatedRoute = ({ children, ...rest }) => {
  const { isAuthenticated, isLoading } = rest;
  return (
    <Route
      {...rest}
      render={props => logic(isAuthenticated, isLoading, children, props)}
    />
  );
};

AuthenticatedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

const mapStateToProps = state => {
  const {
    ehr: { isLoading: ehrLoading },
    ancestry: { isLoading: ancestryLoading },
    settings: { isLoading: settingsLoading },
    isLoading: appLoading,
  } = state.user;

  const isLoading = ehrLoading || ancestryLoading || settingsLoading || appLoading;

  return { isLoading };
};

export default connect(mapStateToProps)(AuthenticatedRoute);
