/* eslint-disable camelcase */
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GeneralModal from '../components/GeneralModal';
import DocumentModal from '../components/DocumentModal';
import ParticipantInformationSheet from '../components/ParticipantInformationSheet';
import DiscoverMeVideo from '../components/DiscoverMeVideo';

import './Profile.scss';

const Profile = (props) => {
  const { name, email, phone } = props;
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showParticipantModal, setShowParticipantModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  return (
    <div className="profile">
      <h1>Profile</h1>
      <div className="profile-container">
        <Row>
          <Col>
            <h2>Your Details</h2>
            <div className="table">
              <Row>
                <Col xs={12} sm={6} className="label">
                  Name
                </Col>
                <Col xs={12} sm={6} className="data">
                  {name}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} className="label">
                  Email
                </Col>
                <Col xs={12} sm={6} className="data">
                  {email}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} className="label">
                  Landline
                </Col>
                <Col xs={12} sm={6} className="data" />
              </Row>
              <Row className="segment">
                <Col xs={12} sm={6} className="label">
                  Mobile
                </Col>
                <Col xs={12} sm={6} className="data">
                  {phone}
                </Col>
              </Row>
              <Row className="centered">
                <Button onClick={() => setShowUpdateModal(true)}>
                  Update details
                </Button>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Button
              className="wide"
              onClick={() => setShowParticipantModal(true)}
            >
              View participant information sheet
            </Button>
          </Col>
          <Col sm={4}>
            <Button className="wide" onClick={() => setShowVideoModal(true)}>
              View the study enrolment video
            </Button>
          </Col>
          <Col sm={4}>
            <Button className="wide" onClick={() => setShowWithdrawModal(true)}>Withdraw from Discover Me</Button>
          </Col>
        </Row>
      </div>

      <GeneralModal
        title="Update Your Details"
        msg="This feature is currently not available through the portal. To update your details
           please contact your GP practice."
        isOpen={showUpdateModal}
        hide={() => setShowUpdateModal(false)}
      />

      <GeneralModal
        title="Withdraw from Discover Me"
        msg="This feature is not currently available through the portal.
          To withdraw from the study please contact your GP practice."
        isOpen={showWithdrawModal}
        hide={() => setShowWithdrawModal(false)}
      />

      <DocumentModal
        title="Participant Information Sheet"
        msg={(
          <div>
            <p style={{ color: 'red', fontSize: '1.2em' }}>
              This participant information sheet was provided to you when you
              joined the Discover Me study
            </p>
            <ParticipantInformationSheet />
          </div>
        )}
        isOpen={showParticipantModal}
        hide={() => setShowParticipantModal(false)}
      />

      <DocumentModal
        title="Enrolment Video"
        msg={<DiscoverMeVideo />}
        isOpen={showVideoModal}
        hide={() => setShowVideoModal(false)}
      />
    </div>
  );
};

Profile.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    settings: {
      userAttributes: {
        given_name, family_name, email, phone_number: phone,
      },
    },
  } = state.user;
  const name = `${given_name} ${family_name}`;
  return { name, email, phone };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
