import React from 'react';
import './ParticipantInformationSheet.scss';

function ParticipantInformationSheet() {
  return (
    <div className="infoContainer">
      <div>
        <h4>We invite you to take part in a research study.</h4>
        <p />
      </div>
      <p>
        <strong>
          You are being invited to take part in the Discover Me study.
        </strong>
      </p>
      <p>
        <strong>
          Discover Me is a nationwide research study that aims to analyse health
          and genetic information on thousands of people. The research aims to
          improve our understanding of many different diseases, and to find ways
          to help GP practices improve patient care.
        </strong>
      </p>
      <p>
        <strong>
          As part of Discover Me, along with providing information about
          yourself and a saliva or mouth swab sample, you will be able to
          receive and view information about your ancestry and some of your
          health information through a secure online participant portal.
        </strong>
        <strong>
          Access to this information could help you and your GP make better
          decisions about your healthcare.
        </strong>
      </p>
      <p>
        <strong>
          Before you decide whether to take part, it is important for you to
          understand why the research is being done and what it will involve.
          Please read the following information carefully and ask us if there is
          anything that is not clear or if you would like more information.
        </strong>
      </p>
      <p>
        <strong>
          Omnigen Biodata, a research and development company founded by senior
          academics at the University of Cambridge, is running this study with
          your GP practice.
        </strong>
      </p>
      <div id="section1">
        <p>
          <strong>1. </strong>
          <strong>What is this research study about?</strong>
        </p>
        <p>
          We aim to study the health of thousands of people from all around the
          UK in order to improve patient care.
        </p>
        <p>
          By connecting genetic information to other health information, we hope
          to better understand:
        </p>
        <ul>
          <li>Why diseases affect some people more than others.</li>
          <li>Why diseases progress differently in different people.</li>
          <li>How to better prevent, manage and diagnose diseases.</li>
        </ul>
        <p>
          This information may also help us improve existing treatments and find
          new treatments for conditions such as cancer and diabetes, and improve
          the health of this and future generations.
        </p>
      </div>
      <div id="section2">
        <p>
          <strong>2. </strong>
          <strong>Can I take part in Discover Me?</strong>
        </p>
        <p>
          If you are 18 or over and able to consent for yourself, then you can
          take part in Discover Me.
        </p>
        <p>
          We would like you to take part whether you are in good health or have
          health problems. It is important that all types of people join the
          study.
        </p>
      </div>
      <div id="section3">
        <p>
          <strong>3. </strong>
          <strong>What will happen if I decide to take part?</strong>
          <strong />
        </p>
        <p> Taking part in Discover Me would involve you:</p>
        <ul>
          <li>
            Enrolling to be part of the study, which includes providing a saliva
            or mouth swab sample and some details about yourself.
          </li>
          <li>
            Allowing information from your health and care records (such as GP
            records, hospital records and disease registries) and other sources
            to be collected and used alongside your sample.
          </li>
          <li>
            Agreeing to be contacted in the future by the research team about
            this study and other new research studies.
          </li>
          <li>
            Choosing whether to receive information on your genetic ancestry
            from your sample and some of your health information from your
            health and care records.
          </li>
        </ul>
      </div>
      <div id="section4">
        <p>
          <strong>4. </strong>
          <strong>What happens during enrolment?</strong>
        </p>
        <p>
          Enrolment will be conducted on electronic devices at your GP practice,
          and should take around 20 minutes.
        </p>
        <p> Enrolment will include:</p>
        <ul>
          <li>
            Viewing a short video and reading this information sheet to learn
            more about the study, as well as a chance to ask the research team
            any questions.
          </li>
          <li>
            Answering some questions about the study to check your understanding
            of what you are consenting to.
          </li>
          <li>
            Filling in a consent form to show that you have agreed to join the
            study.
          </li>
          <li>
            Giving some simple information about yourself including your date of
            birth and contact details.
          </li>
          <li>
            Checking the information you give about yourself with your GP
            practice to ensure you can take part.
          </li>
          <li>
            Providing a saliva or mouth swab sample using our sample collection
            kit.
          </li>
        </ul>
      </div>
      <div id="section5">
        <p>
          <strong>5. </strong>
          <strong>What happens next?</strong>
        </p>
        <p>
          After you complete enrolment, we will send you a confirmation of
          enrolment by text or email. We will also send you a copy of your
          signed consent form, and we will inform your GP practice that you are
          taking part. You can also choose to obtain your consent form from your
          GP practice.
        </p>
        <p>
          We will also send you instructions by text or email on how to access
          your ancestry results and some of your health information, if you have
          chosen to receive these. If you are unable to provide a mobile phone
          number or email address at this point, we
          <strong> won&apos;t </strong>
          be able to send you instructions for accessing your ancestry results
          and health information. However, if you are able to provide a mobile
          phone number or email address in the future, you can then contact us,
          and we can arrange for you to access your ancestry results and health
          information.
        </p>
      </div>
      <div id="section6">
        <p>
          <strong>6. </strong>
          <strong>What happens to my sample?</strong>
        </p>
        <p>
          The DNA (genetic material) will be extracted from your sample and
          placed into long-term secure storage. Using a range of scientific
          methods, we will read your DNA and use this information to see how
          genetic differences (inherited differences between families because of
          ancestry) may help explain how some diseases are caused and how they
          progress. In time, this might also help us find new treatments.
        </p>
        <p>
          If you choose, we will provide you with information on your ancestry,
          based on your DNA. We will
          <strong> not </strong>
          provide you with any other genetic information from this study.
        </p>
        <p>
          Your sample may be sent to service providers, commercial organisations
          and research centres in the UK and other countries, for storage,
          processing and analysis (including obtaining genetic information).
          Your sample will be labelled with a unique code rather than your name
          so that you cannot be directly identified.
        </p>
        <p>
          If your sample cannot be processed for any reason, we may ask you to
          repeat your sample collection. You do not have to provide a repeat
          sample if you do not wish to.
        </p>
      </div>
      <div id="section7">
        <p>
          <strong>7. </strong>
          <strong>What information is collected about me?</strong>
        </p>
        <p>
          For many years, we will follow the health of everyone who has joined
          Discover Me. To do so, we will collect information about you for
          research from your health and care records (for example, from your GP
          records, hospital records and disease registries).
        </p>
        <p>
          This information may be collected at multiple points after you consent
          to take part in the study, so we can update the information we hold on
          you over time. We may also add other information, such as from the
          census, or on the weather or pollution in your area, which may also
          affect health.
        </p>
        <p>
          To obtain this information, the research team may send details about
          you to other organisations. These details may include your name and
          NHS number (or equivalent), or date of birth. To do this, we will use
          secure methods which are routinely used as part of your healthcare.
        </p>
        <p>
          The research team may also contact you or your GP practice directly to
          update our records if we think you have changed practice, so that we
          can continue to collect your information from your GP practice.
        </p>
      </div>
      <div id="section8">
        <p>
          <strong>8. </strong>
          <strong>How will my information be used for this study?</strong>
        </p>
        <p>
          We will use your health and genetic information to investigate why
          diseases affect some people more than others, and why disease
          progresses differently in different people. We will also use this
          information to find new or improved ways to prevent, diagnose and
          treat disease, and to help improve patient care and management.
        </p>
      </div>
      <div id="section9">
        <p>
          <strong>9. </strong>
          <strong>What results can I choose to receive from this study?</strong>
          <strong />
        </p>
        <p>
          Once your information has been collected and your sample has been
          analysed, you will be able to see your ancestry results and some
          health information from your health and care records in a secure
          online participant portal. You can expect to see your results within
          12 weeks.
        </p>
        <p>
          You can choose on the consent form whether or not you would like to
          receive this information. Remember, you will need to provide a mobile
          phone number or email address if you wish to receive this information.
        </p>
        <p>
          <strong>Information on ancestry</strong>
        </p>
        <p>
          The genetic information from your sample can be analysed to tell us
          things about your ancestry. This includes information on what
          populations your ancestors belonged to.
        </p>
        <p>
          <strong>Information from your health and care records</strong>
        </p>
        <p>
          Some of the information from your health and care records will be
          available for you to view. This may include information on
          measurements taken at your GP practice, such as blood pressure and
          body mass index (BMI), as well as medications you have taken, and
          vaccinations you have had.
        </p>
      </div>
      <div id="section10">
        <p>
          <strong>10. </strong>
          <strong>
            What should I consider when choosing whether to have ancestry
            results or health information returned to me?
          </strong>
        </p>
        <p>
          You may find viewing health information from your health and care
          records interesting, and it may help you keep track of, and manage,
          your health.
        </p>
        <p>
          When deciding whether you wish to have ancestry results returned to
          you, you should consider that results may be unexpected and have
          ongoing effects.
        </p>
        <p>
          Genetic information can reveal things about your biological
          relationships to other members of your family. This may include you
          finding out that someone you thought you were related to is not your
          biological relative.
        </p>
        <p>
          You can contact the research team, or complete a form in the secure
          online participant portal, if you change your mind about receiving
          your ancestry results or health information.
        </p>
      </div>
      <div id="section11">
        <p>
          <strong>11.</strong>
          <strong>Will I be contacted in the future about this study?</strong>
        </p>
        <p>
          After you consent, we will contact you about activities relating to
          Discover Me. This will include instructions for accessing your
          ancestry and health information, a short questionnaire via the online
          portal about the information you have received, and general updates
          about the study or your ancestry and health information.
        </p>
      </div>
      <div id="section12">
        <p>
          <strong>12.</strong>
          <strong>Will my information and sample be shared?</strong>
        </p>
        <p>
          Your information (including information that directly identifies you
          and your health and genetic information) and sample will be securely
          stored by Omnigen for many years, and possibly indefinitely.
        </p>
        <p>
          To make sure that the information and samples collected from this
          study can provide the greatest benefit for society and medical
          research into human health and disease, we or trusted partner
          organisations will share your information with other institutions and
          organisations globally for analysis and further research. These may
          include research institutions, commercial organisations, universities,
          GP practices, hospitals, charities and service providers.
        </p>
        <p>
          We may also share some of your DNA sample with other institutions and
          organisations globally for analysis and further research, including
          research institutions, commercial organisations, universities, GP
          practices, hospitals, charities and service providers.
        </p>
        <p>
          Importantly, only information and samples which do
          <strong> not </strong>
          include your name or other information that directly identifies you
          will be shared for analysis. Where we share your information or
          samples, we will ensure there are safeguards to keep them secure and
          private.
        </p>
        <p>
          Over the coming years, a wide range of research may be carried out
          using your sample and information. This research may be related to
          health or diseases, including research that may help with planning
          clinical services or developing new medicines, commercial
          technologies, products or services. Your information and sample may be
          analysed using new tests and techniques that don&apos;t exist yet,
          including new genetic tests. Importantly, sharing of your information
          and sample for these research activities will continue after your
          death, with the aim of benefitting research and healthcare in the
          future.
        </p>
        <p>
          Where additional research is carried out on your sample, we will not
          provide the individual results of this research to you, as they are
          not suitable for making diagnoses.
        </p>
        <p>
          You will not benefit financially if this research leads to the
          development of new treatments, medical tests or commercial
          technologies, products or services.
        </p>
      </div>
      <div id="section13">
        <p>
          <strong>13.</strong>
          <strong>
            Will I be contacted in the future about other studies?
          </strong>
        </p>
        <p>
          At some points in the future, the research team may contact you
          directly, to see if you would like to join new research studies. These
          new research studies will also be available to view in the secure
          online participant portal and may include surveys or trials of new
          therapies. The new research studies may or may not be related to this
          study. You may be invited to take part based on your health and/or
          genetic information collected as part of this study. Taking part in
          these new research studies would be entirely optional; you will be
          able to decide at the time.
        </p>
      </div>
      <div id="section14">
        <p>
          <strong>14.</strong>
          <strong>How will information about me be kept private?</strong>
        </p>
        <p>
          All information which directly identifies you will be held securely,
          with access restricted to the research team. The research team will
          use this information to contact you about this study, manage the
          running of the study, carry out and audit the information collection
          process or invite you to join new research studies.
        </p>
        <p>
          The people who analyse the information will not be able to identify
          you, and will not be able to find out your name or contact details.
          Any information or sample shared for analysis with other organisations
          will
          <strong> not </strong>
          include your name or other information that directly identifies you.
          Where we share your information or samples, we will ensure there are
          safeguards to keep them secure and private. Researchers who analyse
          your sample and information will sign a contract to say that they will
          not try to find out who you are. You will not be identified personally
          in any publication or report.
        </p>
        <p>
          We will not allow access to your information or sample to the police,
          security services, relatives or lawyers, unless required to do so by
          the UK legal courts.
        </p>
      </div>
      <div id="section15">
        <p>
          <strong>15.</strong>
          <strong>
            What are the possible risks and benefits of taking part?
          </strong>
        </p>
        <p>
          You may find knowing about your ancestry and health information useful
          or interesting and you might benefit in terms of your health and care
          personally. Also, patients in the future may benefit from the
          knowledge generated by this study.
        </p>
        <p>
          We do not expect that you will get sick or unwell from taking part in
          this research study. There will be minimal risk of you being
          identified by those analysing your sample and information.
        </p>
        <p>
          When deciding whether to learn about your ancestry and receive
          information from your health and care records, you should carefully
          consider what this means for you personally.
        </p>
      </div>
      <div id="section16">
        <p>
          <strong>16.</strong>
          <strong>Who is organising the study?</strong>
        </p>
        <p>
          Omnigen Biodata is the sponsor of the Discover Me study based in the
          United Kingdom. The sponsor of the study has overall responsibility
          for the conduct of the study, including being responsible for ensuring
          the study is carried out ethically and in the best interests of the
          people taking part in the study. Omnigen Biodata is a Research and
          Development company registered in the UK (company number 10565653) and
          co-founded by senior academics from the University of Cambridge.
        </p>
        <p>
          The risk of participants in the study suffering harm as a result of
          taking part is minimal, and Omnigen Biodata has full insurance to
          undertake the study.
        </p>
        <p>
          All research in the NHS is looked at by an independent group of
          people, called a Research Ethics Committee, to protect your interests.
          The study has been reviewed and approved by the North East - Tyne
          &amp; Wear South Research Ethics Committee (study reference
          19/NE/0020).
        </p>
      </div>
      <div id="section17">
        <p>
          <strong>17.</strong>
          <strong>How does this study comply with data protection law? </strong>
        </p>
        <p>
          The new laws relating to data protection (the General Data Protection
          Regulation (GDPR) and the Data Protection Act 2018) took effect on
          25th May 2018. As a result of these laws, we are required to inform
          you about how we use your personal information if you decide to take
          part in this study.
        </p>
        <p>
          Omnigen Biodata will be using information about you in order to
          undertake this study and will act as the data controller for this
          study. This means that we are responsible for looking after your
          information and using it properly.
        </p>
        <p>
          Because we are using your personal information for research, under
          data protection law, your rights to access or change your personal
          information are limited. This is because we need to manage your
          information in specific ways in order for the research to be reliable
          and accurate. To safeguard your rights, we will use the minimum
          personally-identifiable information possible. You can contact us
          directly if you have any concerns about how we handle your personal
          information, or you can contact the UK government&apos;s Information
          Commissioner&apos;s Office.
        </p>
        <p>
          You can find out more about how we use your personal information at
          <a href="https://discovermestudy.org"> https://discovermestudy.org </a>
          or by contacting the research team (see
          section 22 for contact details).
        </p>
      </div>
      <div id="section18">
        <p>
          <strong>18.</strong>
          <strong>Is it my choice to take part in the study? </strong>
        </p>
        <p>
          Yes, it is completely your choice to join the study. You do not have
          to take part in this study. Your decision will not affect your routine
          medical care in any way.
        </p>
      </div>
      <div id="section19">
        <p>
          <strong>19.</strong>
          <strong>What if I no longer want to take part in the study? </strong>
        </p>
        <p>
          If you consent to take part in the study but later decide you would
          like to leave, you are free to do so at any time. You do not have to
          give a reason, and your decision to leave the study will not affect
          your routine medical care in any way.
        </p>
        <p>
          If you decide to leave the study, you can tell us by completing a form
          available on the secure online participant portal or by contacting
          your GP practice.
        </p>
        <p>
          On telling us that you wish to leave the study, you will be able to
          choose options for further collection and sharing of information and
          your sample, future contact and access to the secure online
          participant portal. If you decide to withdraw from the study, we will
          not erase or destroy any information and samples already collected
          (including your identifiable information), and we will be unable to
          recall any information and samples that have already been shared for
          research.
        </p>
      </div>
      <div id="section20">
        <p>
          <strong>20.</strong>
          <strong>
            What if I no longer have the capacity to consent to the study?
          </strong>
        </p>
        <p>
          If you consent to take part in the study, your doctor or relative may
          later inform the research team if they feel you have lost the capacity
          to consent to the study; or, we may check your health records for this
          information. &quot;Loss of capacity to consent&quot; means when a
          person has lost the ability to make or communicate a decision. This
          may be the result of conditions such as dementia, mental illness,
          learning disabilities or other conditions causing confusion or loss of
          consciousness (e.g. stroke).
        </p>
        <p>
          On being made aware of this, we will suspend further information and
          sample collection, future contact, and access to the secure online
          participant portal. Information and samples already collected will
          continue to be stored (including your identifiable information) and
          shared for research (including for further research after this study).
        </p>
        <p>
          If you later regain capacity to consent, you can ask to participate in
          the study again if you wish.
        </p>
      </div>
      <div id="section21">
        <p>
          <strong>21.</strong>
          <strong>What should I do if I want to take part?</strong>
        </p>
        <p>
          If you would like to be part of Discover Me, please contact your GP
          practice.
        </p>
      </div>
      <div id="section22">
        <p>
          <strong>22.</strong>
          <strong>How do I contact the Discover Me research team? </strong>
        </p>
        <p>
          After enrolment, if you have any questions, concerns or complaints
          about anything to do with this study, you can contact the research
          team by:
        </p>
        <p> Telephone: 0203 8668 941</p>
        <p> Email: discovermeuk@omnigenbiodata.co.uk</p>
        <p> Post: </p>
        <p className="indent">
          FAO Discover Me UK
          <br />
          Omnigen Biodata
          <br />
          Merlin Place
          <br />
          Milton Road
          <br />
          Cambridge CB4 0DP
        </p>
        <p />
        <p>
          You can also access further information at
          <a href="https://discovermestudy.org"> https://discovermestudy.org</a>
          .
        </p>
      </div>
    </div>
  );
}

export default ParticipantInformationSheet;
