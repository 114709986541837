import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import {
  Button, Form,
} from 'react-bootstrap';

import { resetPasswordAction } from '../actions/userActions';

import ConfirmationModal from '../components/ConfirmationModal';
import DiscoverMePanel from '../components/DiscoverMePanel';

const ForgotPassword = props => {
  const { resetPassword, resettingPassword } = props;

  const [username, setUsername] = useState('');
  const [modalshow, setModalshow] = useState(false);
  const [toHome, setToHome] = useState(false);

  if (resettingPassword && !modalshow) {
    setModalshow(true);
  }

  const handleClose = () => {
    setToHome(true);
  };

  if (toHome === true) {
    return <Redirect to="/" />;
  }

  return (
    <DiscoverMePanel>
      <h2>WELCOME TO THE</h2>
      <h1>PARTICIPANT PORTAL</h1>
      <div>
        <p
          style={{
            fontFamily: 'Montserrat',
            fontSize: '14px',
            alignText: 'center',
            width: '100%',
          }}>
          Please enter your
          <strong> username </strong>
          to get a password reset link.
        </p>
        <Form
          style={{
            justifyContent: 'center',
            textAlign: 'left',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 300,
          }}>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              value={username}
              onChange={event => setUsername(event.target.value)}
              type="username"
              placeholder="Enter username"
              autoCapitalize="off"
            />
            <Form.Text className="text-muted" />
          </Form.Group>
        </Form>
        <Button
          onClick={() => resetPassword(username)}
          className="login-btn"
          variant="primary"
          disabled={resettingPassword}
          type="submit">
          Reset Password
        </Button>
        <div style={{ marginTop: 10 }}>
          Need help? Contact the Discover Me Research Team at
          <a href="+442038668941"> 02038668941 </a>
          or
          <a href="mailto:discovermeuk@omnigenbiodata.co.uk">
            {' '}
            discovermeuk@omnigenbiodata.co.uk
          </a>
        </div>

      </div>
      <ConfirmationModal
        confirm={() => handleClose()}
        title=""
        pending={resettingPassword}
        isOpen={modalshow}
        pendingText="Attempting to reset your password">
        If your username was found a password reset link has been sent to either your email or mobile phone.
      </ConfirmationModal>
    </DiscoverMePanel>
  );
};

ForgotPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  resettingPassword: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const { reset: { resettingPassword } } = state.user;
  return { resettingPassword };
};

const mapDispatchToProps = {
  resetPassword: resetPasswordAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
