import React, { useState, useEffect } from 'react';
import {
  BarChart, CartesianGrid, Bar, XAxis, YAxis, Tooltip, Cell, Label, ResponsiveContainer,
} from 'recharts';
import {
  Container, Image, Col, Row, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Animated } from 'react-animated-css';
import classnames from 'classnames';
import { connect } from 'react-redux';
import world from '../components/world.svg';
import { showFaqsAction } from '../actions/userActions';

import './Ancestry.scss';
import BackButton from '../components/BackButton';

const Ancestry = props => {
  const mediaMatch = window.matchMedia('(max-width: 315px)');
  const mobileMatch = window.matchMedia('(max-width: 768px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [mobileMatches, setMobileMatches] = useState(mobileMatch.matches);
  const [displayMap, setDisplayMap] = useState(true);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    const mHandler = e => setMobileMatches(e.matches);
    mediaMatch.addListener(handler);
    mobileMatch.addListener(mHandler);
    return () => { mediaMatch.removeListener(handler); mobileMatch.removeListener(mHandler); };
  });

  const { ancestry, showFaqs } = props;
  const labels = [
    'Celtic Europe',
    'Central and West Asia',
    'East Africa',
    'East Asia',
    'Middle-east',
    'North Africa',
    'North America',
    'North Europe',
    'South Africa',
    'South America',
    'South Asia',
    'South Europe',
    'West Africa',
  ];

  const data = labels.map((name, index) => (
    { name, percentage: Math.round(ancestry[index] * 100) }
  ));

  const colors = [
    '#332288',
    '#117733',
    '#44AA99',
    '#88CCEE',
    '#DDCC77',
    '#CC6677',
    '#AA4499',
    '#882255',
    '#332288',
    '#117733',
    '#44AA99',
    '#88CCEE',
    '#DDCC77',
  ];

  const circleStyles = [
    { top: '33%', left: 48 }, // Celtic Europe
    { top: '38%', left: 70 }, // CWA
    { top: '60%', left: 61 }, // East African
    { top: '36%', left: 78 }, // East Asia
    { top: '41%', left: 63 }, // Middle East
    { top: '44%', left: 53 }, // North Africa
    { top: '40%', left: 23 }, // North America
    { top: '23%', left: 52 }, // North Europe
    { top: '76%', left: 57 }, // South Africa
    { top: '60%', left: 35 }, // South America
    { top: '48%', left: 70 }, // South Asia
    { top: '32%', left: 55 }, // South Europe
    { top: '55%', left: 48 }, // West Africa
  ];

  const circles = data.map((circle, index) => {
    const size = (parseInt(circle.percentage, 10) * 0.4) + 20;
    const halfSize = size / 2;
    const defaultStyle = circleStyles[index];
    const leftValue = matches ? defaultStyle.left * 2 : defaultStyle.left;
    const containerStyle = {
      position: 'absolute',
      left: `calc(${leftValue}% - ${halfSize}px`,
      top: `calc(${defaultStyle.top} - ${halfSize}px)`,
    };
    const circleStyle = {
      width: size,
      height: size,
      backgroundColor: colors[index],
    };
    const topPadding = halfSize + 5;
    return (
      <Animated animationIn="zoomIn" style={containerStyle}>
        <div className="circle" style={circleStyle} aria-label={labels[index]} role="button">
          {Math.trunc(circle.percentage)}
        </div>
        <div className="bubble-label" style={{ top: topPadding }}><h4>{circle.name}</h4></div>
      </Animated>
    );
  });

  const chart = matches
    ? (
      <BarChart data={data} width={400} height={450}>
        <CartesianGrid stroke="#ccc" />
        <YAxis
          type="number"
          domain={[0, 100]}
          label={{
            value: 'Estimated percentage', angle: -90, position: 'insideLeft', verticalAnchor: 'middle', textAnchor: 'middle',
          }} />
        <XAxis type="category" dataKey="name" angle={-45} textAnchor="end" interval={0} height={120} />
        <Tooltip />
        <Bar dataKey="percentage">
          {
            data.map((entry, index) => (
              <Cell key={`cell-${labels[index].replace(' ', '-')}`} stroke={colors[index]} fill={colors[index]} />
            ))
          }
        </Bar>
      </BarChart>
    ) : (
      <ResponsiveContainer width="100%" height={475}>
        <BarChart data={data} layout="vertical">
          <CartesianGrid stroke="#ccc" />
          <XAxis
            type="number"
            domain={[0, 100]}
            height={50}>
            <Label value="Estimated percentage" width={mobileMatches ? 50 : 200} offset={-2} position="insideBottom" />
          </XAxis>
          <YAxis type="category" dataKey="name" width={150} interval={0} />
          <Tooltip />
          <Bar dataKey="percentage">
            {
              data.map((entry, index) => (
                <Cell key={`cell-${labels[index].replace(' ', '-')}`} stroke={colors[index]} fill={colors[index]} />
              ))
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );

  return (
    <div className="ancestry-wrapper">
      <BackButton backLink="/" />
      <Row>
        <Col>
          <h2 className="page-title">Ancestry</h2>
        </Col>
      </Row>

      <Row className="page-context">
        <Col>
          <p>
            Your genetic ancestry results tell you what percent of your DNA comes from 13
            populations from around the world that are in our database. This analysis includes DNA
            from all your ancestors from both sides of your family.
          </p>

          <p>
            Over the year, you may receive updated ancestry estimates. This is because over time we
            will expand our database, which will allow us to understand ancestry
            composition more precisely. This could lead to changes to your results.
          </p>

          <p>
            To find out more about the information on this page, please visit our
            <Button variant="link" onClick={() => showFaqs('ancestry')}>Frequently Asked Questions (FAQs)</Button>
          </p>
        </Col>
      </Row>

      <Row className="container-ancestry">
        <Col>
          <Row className="controls">
            <div
              className={classnames('option', { selected: displayMap })}
              onClick={() => setDisplayMap(true)}
              onKeyDown={() => setDisplayMap(true)}
              role="button"
              aria-label="Map"
              tabIndex={0}>
              <h3>Map</h3>
            </div>
            <div
              className={classnames('option', { selected: !displayMap })}
              onClick={() => setDisplayMap(false)}
              onKeyDown={() => setDisplayMap(false)}
              role="button"
              aria-label="Chart"
              tabIndex={0}>
              <h3>Chart</h3>
            </div>
          </Row>
          <Row>
            {displayMap ? (
              <Container className="world-container">
                <Image className="world" src={world} fluid />
                {circles}
              </Container>
            ) : (
              <Col>
                <div className="chart-wrapper">
                  {chart}
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

Ancestry.propTypes = {
  ancestry: PropTypes.arrayOf(PropTypes.number),
  showFaqs: PropTypes.func.isRequired,
};

Ancestry.defaultProps = {
  ancestry: [],
};

const mapStateToProps = state => {
  const { ancestry: { data: ancestry } } = state.user;
  return { ancestry };
};

const mapDispatchToProps = {
  showFaqs: showFaqsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ancestry);
