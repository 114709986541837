import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';

import Box from '../components/Box';
import ConfirmationModal from '../components/ConfirmationModal';

import './Home.scss';

function Home(props) {
  const [showAncestryModal, setAncestryModalVisible] = useState(false);
  const [showHealthModal, setHealthModalVisible] = useState(false);

  const {
    showEhr,
    showAncestry,
    validAncestry,
    validEhr,
    userAttributes: { given_name: name },
  } = props;

  const ancestryBox = validAncestry ? (
    <Col>
      <Box
        link={showAncestry ? '/ancestry' : '/'}
        text="ANCESTRY INFORMATION"
        image="ancestry_main@2x.png"
        color="lightblue"
        color2="#3A547F"
        disabled={!showAncestry}
        disabledMessage="You have chosen not to receive your ancestry information. You can change your preferences at any time in Settings " />
    </Col>
  ) : (
    <Col>
      <Box
        text="ANCESTRY INFORMATION"
        image="ancestry_main@2x.png"
        color="lightblue"
        color2="#3A547F"
        onClick={() => setAncestryModalVisible(true)}
        disabled={!showAncestry}
        disabledMessage="You have chosen not to receive your ancestry information. You can change your preferences at any time in Settings " />
    </Col>
  );

  const healthBox = validEhr ? (
    <Col>
      <Box
        link={showEhr ? '/health' : '/'}
        text="HEALTH INFORMATION"
        image="health_main@2x.png"
        color="lightgreen"
        color2="#34A56C"
        disabled={!showEhr}
        disabledMessage="You have chosen not to receive your health information. You can change your preferences at any time in Settings " />
    </Col>
  ) : (
    <Col>
      <Box
        text="HEALTH INFORMATION"
        image="health_main@2x.png"
        color="lightgreen"
        color2="#34A56C"
        onClick={() => setHealthModalVisible(true)}
        disabled={!showEhr}
        disabledMessage="You have chosen not to receive your health information. You can change your preferences at any time in Settings " />
    </Col>
  );

  return (
    <Container className={classnames('home')}>
      <h1>
        Welcome back
        <span>{` ${name}`}</span>
      </h1>
      <Row className="justify-content-md-center">
        {ancestryBox}
        {healthBox}
      </Row>

      <ConfirmationModal
        title="Ancestry information currently unavailable"
        isOpen={showAncestryModal}
        pending={false}
        confirm={() => setAncestryModalVisible(false)}>
        <p>
          Unfortunately, your ancestry results are not available yet. Please
          check back again later. If we need to, we may contact you about this.
        </p>
      </ConfirmationModal>

      <ConfirmationModal
        title="Health information currently unavailable"
        isOpen={showHealthModal}
        pending={false}
        confirm={() => setHealthModalVisible(false)}>
        Unfortunately, your health information is not available yet. Please
        check back again later. If we need to, we may contact you about this.
      </ConfirmationModal>
    </Container>
  );
}

Home.propTypes = {
  userAttributes: PropTypes.shape({
    given_name: PropTypes.string.isRequired,
  }).isRequired,
  showAncestry: PropTypes.bool.isRequired,
  showEhr: PropTypes.bool.isRequired,
  validAncestry: PropTypes.bool.isRequired,
  validEhr: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const {
    validAncestry, validEhr,
    settings: {
      showAncestry, showEhr, userAttributes,
    },
  } = state.user;
  return {
    validAncestry, validEhr, showAncestry, showEhr, userAttributes,
  };
};

export default connect(mapStateToProps)(Home);
