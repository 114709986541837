import React from 'react';
import {
  Button,
  Spinner,
} from 'react-bootstrap';
import Modal from 'react-modal';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './DocumentModal.scss';

export default function DocumentModal(props) {
  const {
    hide,
    title,
    msg,
    pending,
  } = props;

  Modal.setAppElement('#root');

  return (
    <Modal className="document-modal" {...props}>
      <div className="modal-heading">
        <h1>{` ${title} `}</h1>
      </div>
      <div className="modal-body">
        {msg}
      </div>
      {hide ? (
        <div className="modal-footer">
          {pending ? (
            <Button
              variant="primary"
              disabled
              className={classnames(
                'orange',
              )}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">
                Loading...
              </span>
            </Button>
          ) : (
            <Button
              variant="secondary"
              onClick={hide}
              disabled={pending}>
              Close
            </Button>
          )}
        </div>
      ) : ''}
    </Modal>
  );
}

DocumentModal.propTypes = {
  msg: PropTypes.element.isRequired,
  hide: PropTypes.func,
  confirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

DocumentModal.defaultProps = {
  hide: undefined,
};
