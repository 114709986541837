import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Modal from 'react-modal';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export default function ConfirmationModal(props) {
  const {
    hide, confirm, title, pending, children, pendingText,
  } = props;

  Modal.setAppElement('#root');

  return (
    <Modal className="settings-modal" {...props}>
      <div className="modal-heading">
        <h1>{title}</h1>
      </div>
      <div className="modal-body">
        <p>
          { pending && pendingText ? pendingText : children }
        </p>
      </div>
      <div className="modal-footer">
        {pending
          ? (
            <Button variant="primary" disabled className={classnames('orange')}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">Loading...</span>
            </Button>
          ) : (
            <Button
              className={classnames('orange')}
              onClick={confirm}>
              Confirm
            </Button>
          )}
        {hide ? <Button variant="secondary" onClick={hide} disabled={pending}>Cancel</Button> : ''}
      </div>

    </Modal>
  );
}

ConfirmationModal.propTypes = {
  hide: PropTypes.func,
  confirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  children: PropTypes.string,
  pendingText: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  hide: undefined,
  children: '',
  pendingText: undefined,
};
