import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import TimeLine from '../../components/TimeLine';
import './Vaccinations.scss';
import DataTable from '../../components/DataTable';
import BackButton from '../../components/BackButton';

class Vaccinations extends Component {
  constructor(props) {
    super(props);

    const { vaccinations: oldVaccinations } = props;

    const vaccinationsTimeline = oldVaccinations.reduce(
      (accumulator, value) => accumulator.concat(value[0]),
      [],
    );

    const vaccinations = oldVaccinations
      .sort()
      .reverse()
      .map(vaccination => [
        moment.utc(vaccination[0]).format('Do MMM YYYY'), vaccination[1], vaccination[2],
      ]);
    vaccinationsTimeline.sort().reverse();

    this.state = {
      vaccinations,
      vaccinationsTimeline,
    };
  }

  render() {
    const { vaccinationsTimeline, vaccinations } = this.state;
    return (
      <Container className={classnames('vaccinations')}>
        <Container style={{ paddingBottom: 20, maxWidth: 800 }}>
          <BackButton backLink="/health" />
          <h1>Vaccinations</h1>

          <p>
            This lists the vaccinations that are recorded in your GP medical
            records. This information may not include vaccinations you have had
            elsewhere, such as at work or at a hospital or private clinic.
          </p>

          <p>
            It can take up to 30 days on average for changes made in your GP
            medical records to appear on this website. This means that the
            information you can see today may not be completely up to date if
            your vaccination history has changed recently.
          </p>

          <Container className="health-data-content">
            <TimeLine color="#EC2C58" data={vaccinationsTimeline} />

            {vaccinations.length > 0
              ? (
                <DataTable
                  headers={['Date of vaccination', 'Vaccination type', 'Dose number']}
                  data={vaccinations}
                  color="#EC2C58"
                  offColor="#F594AA" />
              ) : (
                <Row className="disabled">
                  There are no vaccinations in your medical record.
                </Row>
              )}
          </Container>
        </Container>
      </Container>
    );
  }
}

Vaccinations.propTypes = {
  vaccinations: PropTypes.arrayOf(PropTypes.string),
};

Vaccinations.defaultProps = {
  vaccinations: [],
};

export default Vaccinations;
