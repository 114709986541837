/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import './ToggleSwitch.scss';

const ToggleSwitch = ({
  isOn, handleToggle, id,
}) => (
  <>
    <input
      checked={isOn}
      onChange={handleToggle}
      className="react-switch-checkbox"
      id={id}
      type="checkbox"
    />
    <label
      style={{ background: '#FCA60A' && '#ffde84' }}
      className="react-switch-label"
      htmlFor={id}
    >
      <span
        style={{ background: isOn && '#fca60a' }}
        className="react-switch-button"
      />
    </label>
  </>
);

ToggleSwitch.propTypes = {
  isOn: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default ToggleSwitch;
