import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const useQuery = () => new URLSearchParams(useLocation().search);

export default function UnauthenticatedRoute({ children, ...rest }) {
  const query = useQuery();
  const redirectLocation = query.get('redirect');
  const { isAuthenticated } = rest;

  let child;
  if (isAuthenticated) {
    child = <Redirect to={redirectLocation || '/'} />;
  }
  return (
    <Route
      {...rest}
      render={() => (!isAuthenticated ? (
        children
      ) : (
        child
      ))}
    />
  );
}

UnauthenticatedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
