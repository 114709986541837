/* eslint-disable max-len */
import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './FAQ.scss';

const FAQ = (props) => {
  const { isAuthenticated, faqSection } = props;

  const ancestryRef = useRef(null);
  const healthRef = useRef(null);
  const prescriptionsRef = useRef(null);
  const conditionsRef = useRef(null);
  const appointmentsRef = useRef(null);
  const allergiesRef = useRef(null);

  useEffect(() => {
    switch (faqSection) {
      case 'prescriptions':
        if (prescriptionsRef.current) {
          prescriptionsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'conditions':
        if (conditionsRef.current) {
          conditionsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'appointments':
        if (appointmentsRef.current) {
          appointmentsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'allergies':
        if (allergiesRef.current) {
          allergiesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'ancestry':
        if (ancestryRef.current) {
          ancestryRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'health':
        if (healthRef.current) {
          healthRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      default:
        break;
    }
  }, [faqSection]);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="FAQContainer">
      <div className="boxHeader">
        <h4>Discover Me participant portal</h4>
        <h4>Frequently Asked Questions (FAQs)</h4>
      </div>
      <p>
        These FAQs relate to the Discover Me participant portal. If you have
        questions about the Discover Me study, please visit the study
        website&nbsp;
        <a
          href="https://discovermestudy.org"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://discovermestudy.org
        </a>
        &nbsp;to review the study FAQs.
      </p>
      <p className="warning">
        If you have specific questions about any medical matter, you should
        consult your doctor or other professional healthcare provider without
        delay. If you think you are experiencing any medical condition you
        should seek immediate medical attention from a doctor or other
        professional healthcare provider. You should never delay seeking medical
        advice, disregard medical advice, or discontinue medical treatment
        because of information on this Platform.
      </p>
      <ol>
        <li>
          General
          <ol>
            {isAuthenticated ? (
              <li>
                Should I log out of my portal after accessing my health and
                ancestry information?
                <p>
                  We take privacy of your information very seriously. You can
                  only view your own information on the Discover Me portal. Do
                  not give anyone access to your electronic device whilst you
                  are logged into the portal. To keep your information private,
                  you need to ensure you log out of the portal when you have
                  finished viewing your health and ancestry information.
                </p>
              </li>
            ) : (
              ''
            )}
            {isAuthenticated ? (
              <li>
                Why does the portal log me out after five minutes?
                <p>
                  We take privacy of your information very seriously. You can
                  only view your own information on the participant portal. If
                  you are logged into the portal and are not browsing through
                  your information or clicking through the pages, you will
                  automatically be logged out after five minutes. To access your
                  health and ancestry information again, you will need your
                  username and password to log back in.
                </p>
              </li>
            ) : (
              ''
            )}
          </ol>
        </li>
        {isAuthenticated ? (
          <li ref={ancestryRef}>
            Ancestry Information
            <ol>
              <li>
                What does my ancestry information mean?
                <p>
                  Your genetic ancestry results tell you what percentage of your
                  DNA comes from 13 global regions based on 133 populations from
                  around the world that are in our database. This analysis
                  includes DNA from all your ancestors from both sides of your
                  family. Your ancestry estimates are approximate, and may
                  change over time as our database grows and includes more
                  populations within and across regions.
                </p>
                <p>
                  Your ancestry information can tell you things about your
                  origins, and therefore about your relationship to other groups
                  of people worldwide. Sometimes this information will confirm
                  things you already know about your particular heritage or
                  origins. At other times, this information might surprise you,
                  and you might learn that you share a genetic history with
                  unexpected populations around the world.
                </p>
                <p>
                  We use genetic markers in your DNA that indicate from which
                  populations within our dataset you are related to. By
                  analysing a large proportion of these markers we use
                  statistical methods to estimate the populations you are likely
                  to belong to, and to estimate what percentage of your DNA is
                  likely to come from each of the populations in our database.
                  For example, if your results say 30% Northern European and 70%
                  Southern European, this represents the likelihood that the
                  segments of DNA we have analysed are of Northern and Southern
                  European ancestry, respectively.
                </p>
              </li>
              <li>
                Why might my ancestry information change over time?
                <p>
                  Over time, you may receive updated ancestry estimates. Your
                  ancestry information is based on the amount of data we have
                  available in our database, and the methods we use to
                  understand your results. Because genetic research is
                  constantly advancing, and we’re collecting more data and
                  improving our methods, this means that future analysis of your
                  DNA may change the interpretation of your DNA. This is why
                  your ancestry information may change in the future. This does
                  not mean your DNA has changed, but is because the ways we can
                  look at your DNA have changed.
                </p>
              </li>
              <li>
                How is my ancestry estimated?
                <p>
                  To estimate your ancestry, we analyse the DNA in your sample
                  and compare this to the DNA of populations from around the
                  world. We use genetic markers in your DNA that indicate from
                  which populations within our dataset you are related to. By
                  analysing a large proportion of these markers we use
                  statistical methods to estimate the populations you are likely
                  to belong to, and to estimate what percentage of your DNA is
                  likely to come from each of the populations in our database.
                  For example, if your results say 30% Northern European and 70%
                  Southern European, this represents the likelihood that the
                  segments of DNA we have analysed are of Northern and Southern
                  European ancestry, respectively.
                </p>
                <p>
                  Some regions, such as East Africa, are large areas with
                  substantial geographic and ethnic diversity and therefore may
                  also refer to other populations. For example, if your ancestry
                  estimation includes East Africa, this may also indicate
                  similarities in your DNA to populations in North Africa, the
                  Middle East and parts of Europe. Over time we will expand our
                  database, which will allow us to understand ancestry
                  composition more precisely. This is because we may add
                  populations that better represent your ancestry.
                </p>
                <p>
                  Other regions are just as complex. We provide estimates of
                  Celtic European ancestry. The Celts were a collection of
                  peoples with origins in central Europe that shared similar
                  languages and cultures. They represent some of the ancient
                  population diversity across Europe and the UK. Some modern
                  populations in the UK and Europe represent more of this
                  ancestry than others. We use these populations to help
                  estimate Celtic European ancestry. However, this may also
                  represent ancient ancestry from other parts of UK, Europe,
                  including Northern Europe and Southern Europe.
                </p>
              </li>
              <li>
                Why doesn’t my ancestry information show specific countries?
                <p>
                  We only show your ancestry results by broad geographical
                  regions rather than specific countries. This is because
                  individual ancestries do not reliably reflect specific
                  countries. Individual ancestries represent the movement and
                  mixing of populations across regions over thousands of years,
                  reflecting multiple population migrations and changing
                  geographical boundaries. We all have an incredibly complex
                  ancestral history. We aim to provide more detailed historical
                  insights into ancestry later in the year.
                </p>
              </li>
              <li>
                Can I choose to view my ancestry information and can I change my
                mind?
                <p>
                  When you are logged into the portal, you can change your
                  preferences for viewing your ancestry information by selecting
                  the toggle in Settings.
                </p>
              </li>
              <li>
                When I try to access my ancestry information, I see a pop-up
                that tells me it is currently unavailable. Why?
                <p>
                  You may not have ancestry information available yet because we
                  are still analysing your sample, or we have had issues
                  processing your sample. Please check back again later. If we
                  need to, we may contact you about this.
                </p>
              </li>
            </ol>
          </li>
        ) : (
          ''
        )}
        {isAuthenticated ? (
          <li ref={healthRef}>
            Health information – General
            <ol>
              <li>
                Can I choose to view my health information and can I change my
                mind?
                <p>
                  When you are logged into the portal, you can change your
                  preferences for viewing your health information by selecting
                  the toggle in Settings.
                </p>
              </li>
              <li>
                When I try to access my health information, I see a pop-up that
                tells me it is currently unavailable. Why?
                <p>
                  We may be in the process of accessing your medical records
                  from your GP practice or we may have experienced technical
                  issues when accessing your health information. If this
                  happens, we will work to resolve this, so please check back
                  again later. If we need to, we may contact you about this.
                </p>
              </li>
              <li>
                Why are some features not available?
                <p>
                  We are working on some additional exciting features for the
                  participant portal, which will be made available to you soon.
                </p>
              </li>
              <li>
                Where does my health information come from?
                <p>
                  Currently, on the Discover Me Portal, we only display health
                  information that comes from your GP medical records. This
                  means that information from elsewhere (such as hospitals or
                  private clinics) may not be included here.
                </p>
                <p>
                  We have a number of measures in place to ensure that the
                  information provided on the Discover Me portal is your
                  information. The health information displayed on your portal
                  is taken directly from your GP medical records. We do not
                  change, or add to, this information in any way. Importantly,
                  the information on your Discover Me Portal is meant for you.
                  The Discover Me Portal is not used by your GP or other health
                  professionals under any circumstances.
                </p>
                <p>
                  On the portal, we are only able to show a selection of the
                  information from your GP record. Therefore, there will be lots
                  of additional information in your medical record that is not
                  presented on the Discover Me Portal.
                </p>
                <p>
                  Some of the health information pages only display information
                  in your GP medical records over the last 12 months and this is
                  outlined on each page.
                </p>
                <p>
                  Sometimes, we display the date that health information was
                  recorded in your medical record. This is the date that the GP
                  entered this information into your medical record. This might
                  not be the same date as any appointments or other contact with
                  your GP.
                </p>
                <p>
                  We only display information in your medical records held at
                  the GP practice where you enrolled into the study. If you have
                  changed GP practices in the past, information in your medical
                  records from your previous GP practice(s) may not be displayed
                  on the Discover Me portal.
                </p>
                <p>
                  If you move GP practices after taking part in the Discover Me
                  study, we may not be able to continue to update your health
                  information on the Discover Me portal.
                </p>
                <p>
                  It can take some time for changes made in your GP medical
                  records to appear on this website. This means that the
                  information you are viewing today may not be completely up to
                  date if your GP medical records have changed recently.
                </p>
              </li>
              <li>
                Will I still be able to view my health information if I move GP
                practices?
                <p>
                  If you move GP practices, we will still display your health
                  information on your Discover Me Portal. However, we may not be
                  able to continue to update your health information on the
                  Discover Me portal.
                </p>
                <p>
                  We only display information in your medical records held at
                  the GP practice where you enrolled into the study. If you have
                  changed GP practices in the past, information in your medical
                  records from your previous GP practice(s) may not be displayed
                  on the Discover Me portal.
                </p>
              </li>
              <li>
                How often is my health information updated?
                <p>
                  It can take some time for changes made in your GP medical
                  records to appear on this website. This means that the
                  information you are viewing today may not be completely up to
                  date if your GP medical records have changed recently.
                </p>
              </li>
              <li>
                Can I get general medical advice, or ask for help about health
                issues that I have, through my portal?
                <p>
                  No. We are not a healthcare service and we cannot give out
                  medical advice. We are not qualified to do this; we are a
                  research company.
                </p>
                <p>
                  The health information in this Platform is not advice, and
                  should not be treated as such. You must not rely on the
                  information on this Platform as an alternative to medical
                  advice from your doctor or other professional healthcare
                  provider. You must therefore obtain the relevant professional
                  or specialist advice before taking, or refraining from, any
                  action based on the information in this Platform.
                </p>
                <p>
                  If you have specific questions about any medical matter, you
                  should consult your doctor or other professional healthcare
                  provider without delay. If you think you are experiencing any
                  medical condition you should seek immediate medical attention
                  from a doctor or other professional healthcare provider. You
                  should never delay seeking medical advice, disregard medical
                  advice, or discontinue medical treatment because of
                  information on this Platform.
                </p>
              </li>
            </ol>
          </li>
        ) : (
          ''
        )}
        {isAuthenticated ? (
          <li ref={prescriptionsRef}>
            Prescriptions
            <ol>
              <li>
                What does my prescription information mean?
                <p>
                  The “Prescriptions” page of the participant portal lists the
                  new prescriptions issued in the last 12 months that are
                  recorded in your GP medical records. This information may not
                  include prescriptions you have had elsewhere, such as at work
                  or at a hospital or private clinic.
                </p>
                <p>
                  For more information about the health information on the
                  Discover Me portal, please see
                  {' '}
                  <button type="button" className="sectionLink" onClick={() => scrollToRef(healthRef)}>
                    Section 3.
                  </button>
                </p>
              </li>
              <li>
                Why can’t I see some of my previous prescriptions?
                <p>
                  There are a few reasons why not all of this information may be
                  available:
                </p>
                <ul>
                  <li>
                    It can take some time for changes made in your GP medical
                    records to appear on this website, so prescriptions that
                    were more recently issued may not be displayed yet.
                  </li>
                  <li>
                    At this time, we only display information recorded in your
                    GP medical record in the last 12 months, so prescriptions
                    issued before this (including repeat prescriptions first
                    issued before this time) will not be displayed at this time.
                  </li>
                  <li>
                    The information displayed may not include any prescriptions
                    you have had issued from elsewhere, such as at work or at a
                    hospital or private clinic.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        ) : (
          ''
        )}
        {isAuthenticated ? (
          <li>
            Vaccinations (coming soon)
            <ol>
              <li>
                What does my vaccination information mean?
                <p>
                  The “Vaccinations” page lists the vaccinations that are
                  recorded in your GP medical records. This information may not
                  include vaccinations you have had elsewhere, such as at work
                  or at a hospital or private clinic.
                </p>
                <p>
                  For more information about the health information on the
                  Discover Me portal, please see
                  {' '}
                  <button type="button" className="sectionLink" onClick={() => scrollToRef(healthRef)}>
                    Section 3.
                  </button>
                </p>
              </li>
              <li>
                Why can’t I see some of my vaccinations?
                <p>
                  There are a few reasons why you might not be able to see some
                  of your vaccinations:
                </p>
                <ul>
                  <li>
                    It can take some time for changes made in your GP medical
                    records to appear on this website, so more recent
                    vaccinations may not be displayed yet.
                  </li>
                  <li>
                    The information displayed may not include any vaccinations
                    you have had elsewhere, such as at work or at a hospital or
                    private clinic.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        ) : (
          ''
        )}
        {isAuthenticated ? (
          <li ref={appointmentsRef}>
            Appointments/contact
            <ol>
              <li>
                What does my appointment/contact information mean?
                <p>
                  This lists the dates of your GP practice appointments and
                  other contact (such as telephone consultations) from the last
                  12 months that are recorded in your GP medical records. This
                  information does not include appointments at other healthcare
                  facilities such as at work or at a hospital or private clinic.
                </p>
                <p>
                  For more information about the health information on the
                  Discover Me portal, please see
                  {' '}
                  <button type="button" className="sectionLink" onClick={() => scrollToRef(healthRef)}>
                    Section 3.
                  </button>
                </p>
              </li>
              <li>
                Why can’t I see some of my appointments?
                <p>
                  There are a few reasons why you might not be able to see some
                  of your appointments:
                </p>
                <ul>
                  <li>
                    It can take some time for changes made in your GP medical
                    records to appear on this website, so more recent
                    appointments may not be displayed yet.
                  </li>
                  <li>
                    At this time, we only display information recorded in your
                    GP medical record in the last 12 months, so appointments
                    before this time will not be displayed.
                  </li>
                  <li>
                    The information displayed may not include any appointments
                    you have had elsewhere, such as at work or at a hospital or
                    private clinic.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        ) : (
          ''
        )}
        {isAuthenticated ? (
          <li ref={conditionsRef}>
            Conditions
            <ol>
              <li>
                What does my conditions information mean?
                <p>
                  The “Conditions” page lists the medical conditions from the
                  last 12 months that are recorded in your GP medical records.
                  This information may not include conditions that have been
                  investigated, diagnosed or managed elsewhere, such as at work
                  or at a hospital of private clinic.
                </p>
                <p>
                  For more information about the health information on the
                  Discover Me portal, please see
                  {' '}
                  <button type="button" className="sectionLink" onClick={() => scrollToRef(healthRef)}>
                    Section 3.
                  </button>
                </p>
              </li>
              <li>
                Why can’t I see some of my conditions?
                <p>
                  There are a few reasons why you might not be able to see some
                  of your conditions:
                </p>
                <ul>
                  <li>
                    It can take some time for changes made in your GP medical
                    records to appear on this website, so more recently recorded
                    conditions may not be displayed yet.
                  </li>
                  <li>
                    At this time, we only display information recorded in your
                    GP medical record in the last 12 months, so conditions
                    recorded before this time will not be displayed.
                  </li>
                  <li>
                    The information displayed may not include any conditions
                    that have been investigated, diagnosed or managed elsewhere,
                    such as at work or at a hospital or private clinic.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        ) : (
          ''
        )}
        {isAuthenticated ? (
          <li ref={allergiesRef}>
            Allergies
            <ol>
              <li>
                What does my allergy information mean?
                <p>
                  This lists the allergies that are recorded in your GP medical
                  records. This information may not include allergies that have
                  been diagnosed elsewhere, such as at work or at a hospital or
                  private clinic.
                </p>
                <p>
                  For more information about the health information on the
                  Discover Me portal, please see
                  {' '}
                  <button type="button" className="sectionLink" onClick={() => scrollToRef(healthRef)}>
                    Section 3.
                  </button>
                </p>
              </li>
              <li>
                Why can’t I see some of my allergies?
                <p>
                  There are a few reasons why you might not be able to see some
                  of your allergies:
                </p>
                <ul>
                  <li>
                    It can take some time for changes made in your GP medical
                    records to appear on this website, so more recently recorded
                    allergies may not be displayed yet.
                  </li>
                  <li>
                    The information displayed may not include any medical care
                    or advice you have had elsewhere, such as at work or at a
                    hospital or private clinic.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        ) : (
          ''
        )}
        {isAuthenticated ? (
          <li>
            Settings and profile
            <ol>
              <li>
                How do I change my preferences for what information I would like
                to view on the portal?
                <p>
                  When you are logged into the portal, you can change your
                  preferences for viewing your health and/or ancestry
                  information by selecting the toggle in Settings.
                </p>
              </li>
              <li>
                How can I withdraw from the Discover Me study through the
                portal?
                <p>
                  This function is currently unavailable on the participant
                  portal but will be coming soon. In the meantime, if you wish
                  to withdraw from the Discover Me study, please contact your GP
                  practice, who will be able to provide you with a withdrawal
                  form to complete.
                </p>
              </li>
              <li>
                What is the participant information sheet?
                <p>
                  The participant information sheet was provided to you when you
                  enrolled into the Discover Me study. We have provided a copy
                  of this on the participant portal.
                </p>
              </li>
              <li>
                How can I update my details on the portal?
                <p>
                  This function is currently unavailable on the participant
                  portal but will be coming soon. In the meantime, if you wish
                  to update your details, please contact your GP practice and
                  let them know the details you would like to update, and they
                  will inform the Discover Me Research Team.
                </p>
              </li>
              <li>
                How can I change my password?
                <p>
                  To change your password, please go to the Settings page. Here
                  you will be able to change your password by entering your
                  current password and the new password that you would like to
                  use instead. You will need to enter this twice to make sure
                  you have typed this correctly. The next time you log in to the
                  participant portal, you will need to use your new password.
                </p>
              </li>
            </ol>
          </li>
        ) : (
          ''
        )}
      </ol>
      <h5>Discover Me study</h5>
      If you have questions about the Discover Me study, please visit the study
      website&nbsp;
      <a href="https://discovermestudy.org/">https://discovermestudy.org/</a>
      &nbsp;to review the study FAQs. You can also contact your GP practice or
      the Discover Me Research Team at&nbsp;
      <a href="mailto:discovermeuk@omnigenbiodata.co.uk">
        discovermeuk@omnigenbiodata.co.uk
      </a>
    </div>
  );
};

/* eslint-enable max-len */

FAQ.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  faqSection: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { isAuthenticated, faqSection } = state.user;
  return { isAuthenticated, faqSection };
};

export default connect(mapStateToProps)(FAQ);
