/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import './App.scss';
import {
  Row,
  Button,
  Container,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import IdleTimer from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import Routes from './Routes';

import DocumentModal from './components/DocumentModal';
import PrivacyPolicy from './documents/PrivacyPolicy';
import PrivacyNotice from './documents/PrivacyNotice';
import TermsAndConditions from './documents/TermsAndConditions';
import FAQ from './documents/FAQ';
import Nav from './components/Nav';
import FirstTimePolicies from './Routes/FirstTimePolicies';

import {
  signOut as signOutAction,
  reauthenticateAction,
  hideFaqsAction,
  setIEAction,
} from './actions/userActions';

function App(props) {
  const {
    signOut, isAuthenticated, reauthenticate, showTocs, showFaqsModal,
    hideFaqs, setIE,
  } = props;
  const [showTCsModal, setShowTCsModal] = useState(false);
  const [showPrivacyNoticeModal, setShowPrivacyNoticeModal] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [padding, setPadding] = useState(true);

  const mediaMatch = window.matchMedia('(-ms-high-contrast: none), (-ms-high-contrast: active)');
  setIE(mediaMatch.matches);

  const timer = useRef(null);

  let location = useLocation(); // eslint-disable-line

  useEffect(() => {
    reauthenticate();
  }, [reauthenticate]);

  useEffect(() => {
    if (location.pathname.match(/login/) || location.pathname.match(/register/)) {
      setPadding(false);
    } else {
      setPadding(true);
    }
  }, [location]);

  function onIdle() {
    if (isAuthenticated) {
      signOut();
    }
  }

  function onActive() {
    reauthenticate();
    timer.current.reset();
  }

  return (
    <Container fluid className={classnames('App', { center: !isAuthenticated })}>
      <IdleTimer
        ref={timer}
        element={document}
        onIdle={onIdle}
        onActive={onActive}
        debounce={250}
        timeout={1000 * 60 * 5}
      />
      <Nav />
      <div className={classnames('inner', { wide: !isAuthenticated }, { 'mobile-login': !padding })}>
        { showTocs ? <FirstTimePolicies /> : (
          <Routes
            appProps={{
              ...props,
            }} />
        ) }
      </div>

      <Row>
        <footer className={classnames({ shadow: !isAuthenticated })}>
          <div className="right">
            <Button
              bsPrefix={classnames('footer-btn', {
                shadow: !isAuthenticated,
              })}
              onClick={() => setShowTCsModal(true)}>
              Terms and Conditions
            </Button>
            <Button
              bsPrefix={classnames('footer-btn', {
                shadow: !isAuthenticated,
              })}
              onClick={() => setShowPrivacyNoticeModal(true)}>
              Privacy Notice
            </Button>
            <Button
              bsPrefix={classnames('footer-btn', {
                shadow: !isAuthenticated,
              })}
              onClick={() => setShowPrivacyPolicyModal(true)}>
              Privacy Policy
            </Button>
            {process.env.REACT_APP_VERSION}
          </div>
        </footer>
      </Row>

      <DocumentModal
        title="Terms and Conditions"
        msg={<TermsAndConditions firstTime={false} />}
        isOpen={showTCsModal}
        hide={() => setShowTCsModal(false)} />

      <DocumentModal
        title="Privacy Notice"
        msg={<PrivacyNotice />}
        isOpen={showPrivacyNoticeModal}
        hide={() => setShowPrivacyNoticeModal(false)} />

      <DocumentModal
        title="Privacy Policy"
        msg={<PrivacyPolicy />}
        isOpen={showPrivacyPolicyModal}
        hide={() => setShowPrivacyPolicyModal(false)} />

      <DocumentModal
        title="Portal Frequently Asked Questions (FAQs)"
        msg={<FAQ isAuthenticated={isAuthenticated} />}
        isOpen={showFaqsModal}
        hide={() => hideFaqs()} />
    </Container>
  );
}

const mapStateToProps = state => {
  const { isAuthenticated, showFaqsModal, settings: { showTocs } } = state.user;
  return {
    isAuthenticated, showTocs, showFaqsModal,
  };
};

const mapDispatchToProps = {
  signOut: signOutAction,
  reauthenticate: reauthenticateAction,

  hideFaqs: hideFaqsAction,
  setIE: setIEAction,
};

App.propTypes = {
  signOut: PropTypes.func.isRequired,
  reauthenticate: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  showTocs: PropTypes.bool.isRequired,
  showFaqsModal: PropTypes.bool.isRequired,
  hideFaqs: PropTypes.func.isRequired,
  setIE: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
