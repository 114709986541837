import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import { getLastYearMoment } from '../utils/DateUtils';
import './TimeLine.scss';

const COLUMN_WIDTH = 140;

class TimeLine extends Component {
  constructor(props) {
    super(props);

    const { data } = props;

    const lastDate = data.length > 0
      ? moment.utc(data[data.length - 1])
      : getLastYearMoment();
    const start = moment().startOf('month');
    const diff = start.diff(lastDate);
    const duration = Math.ceil(moment.duration(diff).asMonths());
    const canvasWidth = (duration * COLUMN_WIDTH) + 70;
    const graphWidth = (duration * COLUMN_WIDTH) + 34;

    this.state = {
      data,
      canvasWidth,
      graphWidth,
      start,
      duration,
    };
  }

  componentDidMount() {
    this.draw();
  }

  draw() {
    const { start, duration, graphWidth } = this.state;
    const ctx = this.canvas.getContext('2d');
    ctx.textAlign = 'center';
    ctx.font = '14px Montserrat';
    ctx.strokeStyle = '#D1D1D1';
    ctx.lineWidth = 1;
    let x = graphWidth;

    for (let i = 0; i <= duration; i += 1) {
      ctx.beginPath();
      ctx.moveTo(x, 127);
      ctx.lineTo(x, 57);
      ctx.stroke();
      ctx.fillText(start.format('MMM YYYY'), x, 145);
      start.subtract(1, 'M');
      x -= COLUMN_WIDTH;
    }

    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.moveTo(34, 114);
    ctx.lineTo(graphWidth, 114);
    ctx.stroke();

    this.addData();
  }

  addData() {
    const { data, graphWidth, duration } = this.state;
    const { color } = this.props;
    const { canvas } = this;
    const start = moment().startOf('month');
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.strokeStyle = color;
      ctx.fillStyle = color;
      const height = new Array(duration + 1).fill(0);
      data.forEach(point => {
        const date = moment.utc(point).startOf('month');
        const diff = start.diff(date);
        const monthDiff = Math.round(moment.duration(diff).asMonths());
        const x = graphWidth - (COLUMN_WIDTH * monthDiff);
        const y = 100 - (height[monthDiff] * 15);
        ctx.beginPath();
        ctx.arc(x, y, 5, 0, 2 * Math.PI, false);
        ctx.fill();
        ctx.stroke();
        height[monthDiff] += 1;
      });
    }
  }

  render() {
    this.addData();
    const { canvasWidth } = this.state;

    return (
      <Row className={classnames('timeline')}>
        <Col className="timeline-wrapper">
          <canvas ref={c => { this.canvas = c; }} width={canvasWidth} height={175} />
        </Col>
      </Row>
    );
  }
}

TimeLine.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.string.isRequired,
};

TimeLine.defaultProps = {
  data: [],
};

export default TimeLine;
