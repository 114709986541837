import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './Allergies.scss';
import DataTable from '../../components/DataTable';
import { sortByMomentSecondColumn } from '../../utils/DateUtils';
import BackButton from '../../components/BackButton';
import { showFaqsAction } from '../../actions/userActions';

const Allergies = (props) => {
  const { allergies: oldAllergies, showFaqs, usingIE } = props;

  const allergies = oldAllergies
    .sort(sortByMomentSecondColumn)
    .reverse()
    .map((allergy) => {
      // Remove H/O: at start of allergy and capitalise
      let allergyName = allergy[0].replace(/H\/O:? /i, '');
      allergyName = allergyName.charAt(0).toUpperCase() + allergyName.slice(1);
      return [allergyName, moment.utc(allergy[1]).format('Do MMM YYYY')];
    });

  const ieStyle = usingIE ? { height: allergies.length * 60 + 90 } : {};

  return (
    <div className="scrollbar-allergies">
      <BackButton backLink="/health" />
      <Row>
        <Col>
          <h2 className="page-title">Allergies</h2>
        </Col>
      </Row>
      <Row className="page-context">
        <Col>
          <p>
            This lists the allergies that are recorded in your GP medical
            records. This information may not include allergies that have been
            diagnosed elsewhere, such as at work or at a hospital or private
            clinic.
          </p>
          <p>
            It can take some time for changes made in your GP medical records to
            appear on this website. This means that the information you can see
            today may not be completely up to date if your allergy history has
            changed recently.
          </p>

          <p>
            To find out more about the information on this page, please visit
            our
            <Button variant="link" onClick={() => showFaqs('allergies')}>
              Frequently Asked Questions (FAQs).
            </Button>
          </p>
        </Col>
      </Row>
      <Row className="health-data-content" style={ieStyle}>
        <Col>
          {allergies.length > 0 ? (
            <DataTable
              headers={['Allergy', 'Date Recorded']}
              data={allergies}
              name="allergies"
              usingIE={usingIE}
            />
          ) : (
            <Row className="disabled">
              There are no allergies in your medical record
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

Allergies.propTypes = {
  allergies: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  showFaqs: PropTypes.func.isRequired,
  usingIE: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    usingIE,
    ehr: { allergies },
  } = state.user;
  return { allergies, usingIE };
};

const mapDispatchToProps = {
  showFaqs: showFaqsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Allergies);
