/* eslint-disable */

import React from "react";
import "./TermsAndConditions.scss";

export const version = "3.0.0"

export default function TermsAndConditions(props) {
  const { firstTime } = props;
  return (
    <div className="PrivacyPolicyContainer">
      <div className="boxHeader">
        <h4>Omnigen Biodata Ltd</h4>
        <h4>Discover Me Participant Portal Terms and Conditions</h4>
      </div>

      <p className="warning">PLEASE READ THESE LICENCE TERMS CAREFULLY</p>

      {firstTime ? (
        <p className="warning">
          BY CLICKING ON THE &quot;ACCEPT&quot; BUTTON BELOW YOU AGREE TO THESE
          TERMS WHICH WILL BIND YOU.
        </p>
      ) : (
        ""
      )}

      <h1>1. WHO WE ARE AND WHAT THIS AGREEMENT DOES</h1>

      <p>
        We, Omnigen Biodata Ltd of Merlin Place, Milton Road, Cambridge, CB4 0DP,
        license you to use the Discover Me Participant Portal
        and the data supplied with the software (&quot;Platform&quot;), and any
        updates or supplements to it as permitted in these terms which relate to
        your participation in the Discover Me study which you agreed to take
        part in (the &quot;Study&quot;).
      </p>

      <h1>2. INFORMATION AND ACCESS</h1>
      <h2>Ancestry information</h2>
      <p>
        Your ancestry information can tell you things about your origins, and
        therefore about your relationship to other groups of people worldwide.
        Sometimes this information will confirm things you already know about
        your particular heritage or origins. At other times, this information
        might surprise you, and you might learn that you share a genetic history
        with unexpected populations around the world.
      </p>

      <p>
        Our genes are part of our family heritage. This means that our ancestry
        information can sometimes reveal things about our biological
        relationships to other family members. In the process of learning about
        your ancestry and comparing that with others, you may discover
        information that contradicts prior assumptions about someone you thought
        you were related to.
      </p>

      <p>
        You should also understand that your ancestry information could change
        over time. Your ancestry information is based on the amount of data we
        have available, and the methods we use to understand your results.
        Because genetic research is constantly advancing, and we’re collecting
        more data and improving our methods, this means that future scientific
        research may change the interpretation of your DNA. This is why your
        ancestry information may change in the future. This does not mean your
        DNA has changed, but is because the ways we can look at your DNA have
        changed.
      </p>

      <p>
        If you have received a bone marrow or stem cell transplant, your
        ancestry test may provide unexpected results because your mouth swab
        sample may contain cells with your DNA as well as cells with your
        donor’s DNA. DNA for the test is extracted from the cells, and the
        combination of DNA sources can result in a failed test or a test that
        provides results based on your donor’s DNA. Therefore, we recommend that
        those who have received a bone marrow or stem cell transplant do not use
        the ancestry information from their mouth swab sample.
      </p>

      <p>
        We make no representations, warranties or guarantees, whether express or
        implied, that the content in these ancestry information webpages is
        true, accurate, complete, current or non-misleading.
      </p>

      <p>
        If you have any queries or concerns about your ancestry information,
        please contact the Discover Me Research Team at{" "}
        <a href="mailto:discovermeuk@omnigenbiodata.co.uk">
          discovermeuk@omnigenbiodata.co.uk
        </a>
        . If your sample cannot be processed for any reason, we may contact you
        and ask you to repeat your sample collection. You do not have to provide
        a repeat sample if you do not wish to.
      </p>

      <h2>Health information</h2>

      <p>
        This Platform contains general information about your medical history,
        conditions and treatments which are recorded in your GP medical records.
        This means that information from elsewhere (such as hospitals or private
        clinics) may not be included here.
      </p>

      <p>
        It can take some time for changes made in your GP medical records to
        appear on this Platform. This means that the information you are viewing
        today may not be completely up to date if your GP medical records have
        changed recently.
      </p>

      <p>
        Some of the information that appears on this Platform is only from the
        last 12 months of your GP medical records; other information may be
        dated from further back in your records. In the future, we may increase
        the amount of health information from your GP and other medical records
        that you can view on the Platform.
      </p>

      <p>
        The health information in this Platform is not advice, and should not be
        treated as such. You must not rely on the information on this Platform
        as an alternative to medical advice from your doctor or other
        professional healthcare provider. You must therefore obtain the relevant
        professional or specialist advice before taking, or refraining from, any
        action based on the information in this Platform.
      </p>

      <p>
        If you have specific questions about any medical matter, you should
        consult your doctor or other professional healthcare provider without
        delay. If you think you are experiencing any medical condition you
        should seek immediate medical attention from a doctor or other
        professional healthcare provider. You should never delay seeking medical
        advice, disregard medical advice, or discontinue medical treatment
        because of information on this Platform.
      </p>

      <p>
        We make no representations, warranties or guarantees, whether express or
        implied, that the content in these health information webpages is true,
        accurate, complete, current or non-misleading.
      </p>

      <h1>3. YOUR PRIVACY</h1>
      <p>
        Under data protection legislation, we are required to provide you with
        certain information about who we are, how we process your personal data
        and for what purposes, and your rights in relation to your personal data
        and how to exercise them. This information is provided in the Discover
        Me privacy notice for study participants. This notice is also available
        via the Study website (
        <a
          href="https://discovermestudy.org"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://discovermestudy.org
        </a>
        ).
      </p>

      <p>
        Please be aware that internet transmissions are never completely private
        or secure and that any message or information you send using the
        Platform may be read or intercepted by others, even if there is a
        special notice that a particular transmission is encrypted.
      </p>

      <p>
        Please note that we will handle the personal data which you upload to
        the Platform in accordance with the information and preferences provided
        by you. For further information please see our company privacy policy
        which can be found on the company website, or through this Platform.
      </p>

      <p>
        As part of your engagement, interaction and use of the Platform, we may
        let you know about future research which we undertake or conduct, in
        accordance with the consent structure provided to you upon your
        participation in the current Study.
      </p>

      <h1>4. COMMUNICATING WITH YOU</h1>
      <p>
        From time to time, we may contact you either through the Platform, or
        via email or telephone using the personal data we collect and maintain
        about you, as set out in our company privacy policy. You should
        regularly check your email account or the Platform.
      </p>

      <p>
        We may contact you for a number of reasons, for example (but not limited
        to):
      </p>

      <ul>
        <li>
          We will use the information which you have provided in order to manage
          your account, to provide technical support, to contact you (without
          limitation) so as to notify you regarding any important updates
          relating to the Platform, to answer queries you might raise regarding
          the Platform or any services, and for our own internal administrative
          purposes.
        </li>
        <li>
          To help us to verify your identity where appropriate by cross-checking
          the records kept at the relevant GP Practice (to help keep your
          information secure).
        </li>
        <li>
          We may use your identity, contact details and Platform information to
          form a view on what we think you may want or need, or what may be of
          interest to you. This is how we decide which products, services and
          offers may be relevant for you (i.e. ‘marketing’ or ‘market
          research’).
        </li>
        <li>
          You may receive marketing communications from us if you have requested
          information from us or receive services from us and you have opted-in
          to receiving that marketing material.
        </li>
        <li>
          We may ask you to identify areas of particular interest (which may be
          related to certain conditions or medications) and if you choose to
          provide those details then we may send you information which we feel
          may be relevant to those areas of interest, or which might otherwise
          be of interest to you based on the preferences identified.
        </li>
      </ul>

      <p>
        You are not obliged to respond to these communications, and you can
        restrict or unsubscribe from communications via your preferences options
        within the Platform.
      </p>

      <p>
        Please keep your details up to date and let us know immediately if any
        information you've given us changes.
      </p>

      <h1>5. OPERATING SYSTEM REQUIREMENTS</h1>
      <p>
        This Platform requires you to be using the latest versions of Chrome,
        Firefox or Safari.
      </p>

      <h1>6. SUPPORT FOR THE PLATFORM AND HOW TO TELL US ABOUT PROBLEMS</h1>
      <ul>
        <li>
          <b>Support </b>
          If you want to learn more about the Platform or have any problems
          using it, please take a look at our frequently asked questions section
          which can be found through this Platform. Our website is available
          free of charge. We do not guarantee that our site, or any content on
          it, will always be available or be uninterrupted. We reserve the right
          to suspend, withdraw or restrict the availability of any part of the
          website for business or operational reasons.
        </li>

        <li>
          <b>Contacting us (including with complaints). </b>
          If you think the Platform is faulty or misdescribed, or wish to
          contact us for any other reason, please contact the Discover Me
          Research Team via the Platform or email them at{" "}
          <a href="mailto:discovermeuk@omnigenbiodata.co.uk">
            discovermeuk@omnigenbiodata.co.uk{" "}
          </a>{" "}
          or call them on <a href="tel:+44203 8668 941">0203 8668 941</a>.
        </li>

        <li>
          <b>How we will communicate with you </b>
          If we have to contact you, we will do so through the Platform, or by
          email or telephone using the contact details you have provided to us.
        </li>
      </ul>

      <h1>7. HOW YOU MAY USE THE PLATFORM</h1>
      <p>In return for your agreeing to comply with these terms you may:</p>

      <ul>
        <li>
          access the Platform and view, use and display the Platform on such
          appropriate devices for your personal purposes only.
        </li>
        <li>
          receive and use any free supplementary software code or update of the
          Platform incorporating &quot;patches&quot; and corrections of errors
          as we may provide to you.
        </li>
      </ul>

      <h1>8. YOU MUST BE 18 TO ACCEPT THESE TERMS AND ACCESS THE PLATFORM</h1>
      <p>
        You must be 18 or over to accept these terms and access the Platform.
      </p>

      <h1>9. YOU MAY NOT TRANSFER THE PLATFORM TO SOMEONE ELSE</h1>
      <p>
        We are giving you personally the right to use the Platform as set out
        above. We are permitting you to access your own results from the Study
        and you must keep your personal details, username, password or any other
        account details secure and not share them with any other person. If you
        are concerned that your details may well have been compromised, please
        contact us immediately via the Platform or email us at{" "}
        <a href="mailto:discovermeuk@omnigenbiodata.co.uk">
          discovermeuk@omnigenbiodata.co.uk
        </a>{" "}
        or call us on <a href="tel:+44203 8668 941">0203 8668 941</a>
      </p>

      <h1>10. CHANGES TO THESE TERMS</h1>

      <p>
        We may need to change these terms to reflect changes in law or best
        practice, or to deal with additional features which we introduce.
      </p>

      <p>
        We will give you at least 5 days' notice of any change by sending you an
        email or text message with details of the change, or notifying you of a
        change when you next start the Platform.
      </p>

      <p>
        If you do not accept the notified changes you may continue to use the
        Platform in accordance with the existing terms, but certain new features
        may not be available to you.
      </p>

      <h1>11. UPDATE AND CHANGES TO THE PLATFORM</h1>
      <p>
        From time to time we may automatically update the Platform to improve
        performance, enhance functionality, reflect changes to the operating
        system or address security issues.
      </p>

      <h1>
        12. WE MAY COLLECT TECHNICAL DATA ABOUT HOW YOU ACCESS THE PLATFORM
      </h1>
      <p>
        By using the Platform, you agree to us collecting and using technical
        information about the methods which you use to access and use the
        Platform on and related software, hardware and peripherals to improve
        our products.
      </p>

      <h1>13. WE ARE NOT RESPONSIBLE FOR OTHER WEBSITES YOU LINK TO</h1>
      <p>
        The Platform may contain links to other independent websites which are
        not provided by us. Such independent sites are not under our control and
        we are not responsible for, and have not checked and approved, their
        content or their privacy policies (if any).
      </p>

      <p>
        You will need to make your own independent judgement about whether to
        use any such independent sites, including whether to buy any products or
        services offered by them.
      </p>

      <h1>14. LICENCE RESTRICTIONS</h1>
      <p>You agree that you will:</p>

      <ul>
        <li>
          not rent, lease, sub-license, loan, provide, or otherwise make
          available, the Platform in any form, in whole or in part to any person
          without prior written consent from us;
        </li>

        <li>
          not copy the Platform, except as part of the normal use of the
          Platform or where it is necessary for the purpose of back-up or
          operational security;
        </li>

        <li>
          not translate, merge, adapt, vary, alter or modify, the whole or any
          part of the Platform nor permit the Platform or any part of it to be
          combined with, or become incorporated in, any other programs, except
          as necessary, to use the Platform as permitted in these terms;
        </li>

        <li>
          not disassemble, de-compile, reverse engineer or create derivative
          works based on the whole or any part of the Platform nor attempt to do
          any such things, except to the extent that (by virtue of sections 50B
          and 296A of the Copyright, Designs and Patents Act 1988) such actions
          cannot be prohibited because they are necessary to decompile the
          Platform to obtain the information necessary to create an independent
          program that can be operated with the Platform or with another program
          (Permitted Objective), and provided that the information obtained by
          you during such activities:
        </li>
        <ul>
          <li>
            is not disclosed or communicated without the Licensor's prior
            written consent to any third party to whom it is not necessary to
            disclose or communicate it in order to achieve the Permitted
            Objective; and
          </li>
          <li>
            is not used to create any software that is substantially similar in
            its expression to the Platform;
          </li>
          <li>is kept secure; and</li>
          <li>is used only for the Permitted Objective;</li>
        </ul>

        <li>
          comply with all applicable technology control or export laws and
          regulations that apply to the technology used or supported by the
          Platform.
        </li>
      </ul>

      <h1>15. ACCEPTABLE USE RESTRICTIONS</h1>
      <p>You must:</p>
      <ul>
        <li>
          not use the Platform in any unlawful manner, for any unlawful purpose,
          or in any manner inconsistent with these terms, or act fraudulently or
          maliciously, for example, by hacking into or inserting malicious code,
          such as viruses, or harmful data, into the Platform, or any operating
          system;
        </li>

        <li>
          not infringe our intellectual property rights or those of any third
          party in relation to your use of the Platform (to the extent that such
          use is not licensed by these terms);
        </li>

        <li>
          not transmit any material that is defamatory, offensive or otherwise
          objectionable in relation to your use of the Platform;
        </li>

        <li>
          not use the Platform in a way that could damage, disable, overburden,
          impair or compromise our systems or security or interfere with other
          users; and
        </li>
        <li>
          not collect or harvest any information or data from our systems or
          attempt to decipher any transmissions to or from the servers.
        </li>

        <li>
          be responsible for configuring your information technology, computer
          programmes and platform to access our site. You should use your own
          virus protection software.
        </li>

        <li>
          not misuse our site by knowingly introducing viruses, trojans, worms,
          logic bombs or other material that is malicious or technologically
          harmful. You must not attempt to gain unauthorised access to our site,
          the server on which our site is stored, or any server, computer or
          database connected to our site. You must not attack our site via a
          denial-of-service attack or a distributed denial-of service attack. By
          breaching this provision, you would commit a criminal offence under
          the Computer Misuse Act 1990. We will report any such breach to the
          relevant law enforcement authorities, and we will co-operate with
          those authorities by disclosing your identity to them. In the event of
          such a breach, your right to use our site will cease immediately.
        </li>
      </ul>

      <h1>16. INTELLECTUAL PROPERTY RIGHTS</h1>
      <p>
        All intellectual property rights in the Platform throughout the world
        belong to us (or our licensors) and the rights in the Platform are
        licensed (not sold) to you. You have no intellectual property rights in,
        or to, the Platform, other than the right to use them in accordance with
        these terms.
      </p>

      <h1>17. OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</h1>
      <ul>
        <li>
          We are responsible to you for foreseeable loss and damage caused by
          us. If we fail to comply with these terms, we are responsible for loss
          or damage you suffer that is a foreseeable result of our breaking
          these terms or our failing to use reasonable care and skill, but we
          are not responsible for any loss or damage that is not foreseeable.
          Loss or damage is foreseeable if either it is obvious that it will
          happen or if, at the time you accepted these terms, both we and you
          knew it might happen.
        </li>

        <li>
          We do not exclude or limit in any way our liability to you where it
          would be unlawful to do so. This includes liability for death or
          personal injury caused by our negligence or the negligence of our
          employees, agents or subcontractors or for fraud or fraudulent
          misrepresentation.
        </li>

        <li>
          We are not liable for business losses. The Platform is for domestic
          and private use. If you use the Platform for any commercial, business
          or resale purpose we will have no liability to you for any loss of
          profit, loss of business, business interruption, or loss of business
          opportunity.
        </li>

        <li>
          Limitations to the Platform. The Platform is provided for general
          information and entertainment purposes only. It, and we, do not offer
          advice on which you should rely. You must obtain professional or
          specialist advice before taking, or refraining from, any action on the
          basis of information obtained from the Platform. Although we make
          reasonable efforts to update the information provided by the Platform,
          we make no representations, warranties or guarantees, whether express
          or implied, that such information is accurate, complete or up to date.
        </li>

        <li>
          Please back-up content and data used with the Platform. We recommend
          that you back up any content and data used in connection with the
          Platform, to protect yourself in case of problems with the Platform.
        </li>

        <li>
          We are not responsible for events outside our control. If our
          provision of the Platform is delayed by an event outside our control,
          we will contact you as soon as possible to let you know and we will
          take steps to minimise the effect of the delay.
        </li>
      </ul>

      <h1>
        18. WE MAY END YOUR RIGHTS TO USE THE PLATFORM IF YOU BREAK THESE TERMS
      </h1>
      <p>
        We may end your rights to use the Platform at any time by contacting you
        if you have broken these terms in a serious way. If what you have done
        can be put right, we will give you a reasonable opportunity to do so.
      </p>

      <p>
        If we end your rights to use the Platform you must stop all activities
        authorised by these terms, including your use of the Platform.
      </p>

      <h1>19. WE MAY TRANSFER THIS AGREEMENT TO SOMEONE ELSE</h1>
      <p>
        We may transfer our rights and obligations under these terms to another
        organisation. We will always tell you in writing if this happens and we
        will ensure that the transfer will not affect your rights under the
        contract.
      </p>

      <h1>20. NO RIGHTS FOR THIRD PARTIES</h1>
      <p>
        This agreement does not give rise to any rights under the Contracts
        (Rights of Third Parties) Act 1999 to enforce any term of this
        agreement.
      </p>

      <h1>
        21. IF A COURT FINDS PART OF THIS CONTRACT ILLEGAL, THE REST WILL
        CONTINUE IN FORCE
      </h1>
      <p>
        Each of the paragraphs of these terms operates separately. If any court
        or relevant authority decides that any of them are unlawful, the
        remaining paragraphs will remain in full force and effect.
      </p>

      <h1>
        22. EVEN IF WE DELAY IN ENFORCING THIS CONTRACT, WE CAN STILL ENFORCE IT
        LATER
      </h1>
      <p>
        Even if we delay in enforcing this contract, we can still enforce it
        later. If we do not insist immediately that you do anything you are
        required to do under these terms, or if we delay in taking steps against
        you in respect of your breaking this contract, that will not mean that
        you do not have to do those things and it will not prevent us taking
        steps against you at a later date.
      </p>

      <h1>
        23. WHICH LAWS APPLY TO THIS CONTRACT AND WHERE YOU MAY BRING LEGAL
        PROCEEDINGS
      </h1>
      <p>
        The laws of England and Wales apply to these terms and you can bring
        legal proceedings in respect of the products in the English courts. If
        you live in Scotland, you can bring legal proceedings in respect of the
        products in either the Scottish or the English courts. If you live in
        Northern Ireland, you can bring legal proceedings in respect of the
        products in either the Northern Irish or the English courts.
      </p>
    </div>
  );
}

/* eslint-enable */
