import React from 'react';
import './ParticipantInformationSheet.scss';

import './DiscoverMeVideo.scss';

function DiscoverMeVideo() {
  return (
    <video
      src="https://assets.omnigenbiodata.co.uk/DiscoverMe_Enrol.mp4"
      type="video/mp4"
      id="player"
      controls
      className="plyr_video"
    >
      <track
        kind="captions"
        srcLang="en"
        src="https://assets.omnigenbiodata.co.uk/vtt/1_Enrolment_Video.vtt"
        label="English captions"
      />
    </video>
  );
}

export default DiscoverMeVideo;
